/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { SwaggerApiPageUiListComponent } from 'src/app/generated/dataPages/list/SwaggerApi/SwaggerApi.component';
import { SwaggerApiPageFormComponent } from 'src/app/generated/dataPages/form/SwaggerApi/SwaggerApi.component';
import { SwaggerApiPageUiListModule } from 'src/app/generated/dataPages/list/SwaggerApi/SwaggerApi.module';
import { SwaggerApiPageFormComponentModule } from 'src/app/generated/dataPages/form/SwaggerApi/SwaggerApi.module';

export class SwaggerApiRoutes {
  public static routes = [
    {
      path: 'SwaggerApi/list',
      component: SwaggerApiPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'SwaggerApi/edit/:id',
      component: SwaggerApiPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'SwaggerApi/new/:id',
      component: SwaggerApiPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [SwaggerApiPageUiListModule, SwaggerApiPageFormComponentModule];
}

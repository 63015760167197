/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { _viewPageUiListComponent } from 'src/app/generated/dataPages/list/_view/_view.component';
import { _viewPageFormComponent } from 'src/app/generated/dataPages/form/_view/_view.component';
import { _viewPageUiListModule } from 'src/app/generated/dataPages/list/_view/_view.module';
import { _viewPageFormComponentModule } from 'src/app/generated/dataPages/form/_view/_view.module';

export class _viewRoutes {
  public static routes = [
    {
      path: '_view/list',
      component: _viewPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: '_view/edit/:id',
      component: _viewPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: '_view/new/:id',
      component: _viewPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [_viewPageUiListModule, _viewPageFormComponentModule];
}

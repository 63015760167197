<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs [crumbs]="[]" [current]="currentEditName"></fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content [fxLayoutGap]="gap" fxLayout="column">
    <fury-card *ngIf="formGroup" [formGroup]="formGroup" c>
      <fury-card-header>
        <fury-card-header-heading>Fiche UserClient</fury-card-header-heading>
        <fury-card-header-subheading> Utilisateurs du site no admin </fury-card-header-subheading>

        <fury-card-header-actions *ngIf="!unVisibleComp?.saveBtn || !unVisibleComp?.deleteBtn || !unVisibleComp?.duplicateBtn">
          <button *ngIf="!unVisibleComp?.saveBtn" mat-fab color="primary" (click)="save()">
            <mat-icon aria-label="add icon">save</mat-icon>
          </button>
          <button class="copy" *ngIf="!unVisibleComp?.duplicateBtn" mat-fab (click)="duplicate()">
            <mat-icon aria-label="add icon">content_copy</mat-icon>
          </button>
          <button *ngIf="!unVisibleComp?.deleteBtn" mat-fab color="warn" (click)="delete()">
            <mat-icon aria-label="add icon">delete</mat-icon>
          </button>
        </fury-card-header-actions>
      </fury-card-header>
      <fury-card-content>
        <app-ui-form-userclient id="formUi" [formGroup]="formGroup"></app-ui-form-userclient>
      </fury-card-content>
    </fury-card>
  </fury-page-layout-content>
</fury-page-layout>

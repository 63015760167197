import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatExpansionModule } from '@angular/material/expansion';

import { SheetDescriptionListSubDocModule } from '../../listSubdoc/SheetDescription/SheetDescription.module';

import { UserAdminFormAutoCompleteModule } from '../../chipList/UserAdmin/UserAdmin.module';

import { TagFormAutoCompleteModule } from '../../chipList/Tag/Tag.module';

import { SharedModule } from '@leandredev/common-ng16/components';

import { ImportingFileFormComponent } from './ImportingFile.component';
@NgModule({
  declarations: [ImportingFileFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatCheckboxModule,

    MatExpansionModule,

    SheetDescriptionListSubDocModule,

    UserAdminFormAutoCompleteModule,

    TagFormAutoCompleteModule,

    SharedModule
  ],
  exports: [
    ImportingFileFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatCheckboxModule,

    MatExpansionModule,

    SheetDescriptionListSubDocModule,

    UserAdminFormAutoCompleteModule,

    TagFormAutoCompleteModule,

    SharedModule
  ]
})
export class ImportingFileFormModule {}

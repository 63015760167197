/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageTextStreamPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageTextStream/UserMessageTextStream.component';
import { UserMessageTextStreamPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageTextStream/UserMessageTextStream.component';
import { UserMessageTextStreamPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageTextStream/UserMessageTextStream.module';
import { UserMessageTextStreamPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageTextStream/UserMessageTextStream.module';

export class UserMessageTextStreamRoutes {
  public static routes = [
    {
      path: 'UserMessageTextStream/list',
      component: UserMessageTextStreamPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageTextStream/edit/:id',
      component: UserMessageTextStreamPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageTextStream/new/:id',
      component: UserMessageTextStreamPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageTextStreamPageUiListModule, UserMessageTextStreamPageFormComponentModule];
}

import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { TagPageFormComponent } from 'src/app/generated/dataPages/form/Tag/Tag.component';
import { TagTypePageFormComponent } from 'src/app/generated/dataPages/form/TagType/TagType.component';
import { TagPageUiListComponent } from 'src/app/generated/dataPages/list/Tag/Tag.component';
import { TagTypePageUiListComponent } from 'src/app/generated/dataPages/list/TagType/TagType.component';
import { UploadComponent } from 'src/app/modules/upload/upload/upload.component';

export class DocumentRoutes {
  public static routes = [
    {
      path: 'LightDocumentVector/list',
      component: UploadComponent,
      resolve: {
        state: RouteComponentState
      }
    },

    {
      path: 'Tag/list',
      component: TagPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Tag/edit/:id',
      component: TagPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Tag/new/:id',
      component: TagPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },

    {
      path: 'TagType/list',
      component: TagTypePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'TagType/edit/:id',
      component: TagTypePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'TagType/new/:id',
      component: TagTypePageFormComponent,
      resolve: {
        state: RouteComponentState
      },
      data: {
        state: {
          child: {
            formUi: {
              state: {
                unVisibleComp: {
                  resetPasswordKey: true,
                  name: true
                }
              }
            }
          }
        }
      }
    }
  ];
}

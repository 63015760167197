/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-documentdataanalyst',
  templateUrl: './DocumentDataAnalyst.component.html',
  styleUrls: ['./DocumentDataAnalyst.component.scss'],
  providers: [UIComponentState]
})
export class DocumentDataAnalystUiListComponent extends UiList<interfaces.IDocumentDataAnalyst> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      creationDate: 'date',
      startDate: 'date',
      state: 'object',
      serviceId: 'string',
      endDate: 'date',
      deleted: 'boolean',
      hasFailed: 'boolean',
      errorsList: 'subdoc',
      userId: 'objectid',
      serviceCreator: 'string',
      assistantSessionId: 'objectid',
      lang: 'string',
      llm: 'string',
      llmModel: 'string',
      endInteractionServiceId: 'string',
      url: 'string',
      blob: 'object',
      mimeType: 'string',
      prompt: 'string',
      output: 'subdoc',
      answerFormat: 'string',
      datasets: 'string'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['creationDate', 'startDate', 'state', 'serviceId'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserAdminRoutes } from '../generated/routes/UserAdmin.routes';
import { DocumentRoutes } from './routes/DocumentRoutes';
import { AvailableAiModelsRoutes, ImportingFileRoutes, UserClientRoutes } from '../generated/routes';
import { UserClientGroupRoutes } from '../generated/routes/UserClientGroup.routes';
import { ApplicationDataRoutes } from './routes/ApplicationDataRoutes';
import { LlmActionRoutes } from './routes/LlmActionsRoutes';

let routes: Routes = [];

routes = routes
  .concat(UserAdminRoutes.routes)
  .concat(DocumentRoutes.routes)
  .concat(UserClientRoutes.routes)
  .concat(UserClientGroupRoutes.routes)
  .concat(ApplicationDataRoutes.routes)
  .concat(LlmActionRoutes.routes)
  .concat(ImportingFileRoutes.routes)
  .concat(AvailableAiModelsRoutes.routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataRoutingModule {}

<mat-card class="selector-card">
  <mat-card-title>Veuillez sélectioner type d'import</mat-card-title>

  <mat-card-content [formGroup]="form">
    <mat-radio-group aria-label="Select an option" class="uploadSelector" (change)="setUploadSelection($event)">
      <mat-radio-button class="selector-radio-button" value="url">Je connais l'url du fichier a téléverser</mat-radio-button>
      <mat-radio-button class="selector-radio-button" value="fileSelector">Je choisis un fichier depuis mon ordinateur</mat-radio-button>
    </mat-radio-group>
    <ng-container [ngSwitch]="uploadSelection">
      <div *ngSwitchCase="'url'">
        <mat-form-field class="selector-form-field">
          <mat-label>Url du fichier a téléverser</mat-label>
          <input matInput type="text" formControlName="url" />
          <mat-error *ngIf="form.controls.url.invalid"> L'url est <strong>obligatoire</strong> </mat-error>
          <mat-error *ngIf="form.controls.url.errors?.pattern"> L'url <strong> n'est pas valide</strong> </mat-error>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="'fileSelector'">
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload accept=".pdf,.doc,.docx,.pptx,.xls,.xslx,.*" />

        <div class="file-upload">
          <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <app-tag-selector (tagsChange)="updateTagForRequest($event)" [tags]="allTags" [selectedTags]="[]"></app-tag-selector>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button (click)="cancel()">ANNULER</button>
    <button mat-button (click)="validate()">VALIDER</button>
  </mat-card-actions>
</mat-card>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { LlmActionFormAutoCompleteModule } from '../../chipList/LlmAction/LlmAction.module';

import { UserAdminFormAutoCompleteModule } from '../../chipList/UserAdmin/UserAdmin.module';

import { AssistantSessionFormAutoCompleteModule } from '../../chipList/AssistantSession/AssistantSession.module';

import { SharedModule } from '@leandredev/common-ng16/components';

import { UserMessageAssistantDeletionFormComponent } from './UserMessageAssistantDeletion.component';
@NgModule({
  declarations: [UserMessageAssistantDeletionFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    LlmActionFormAutoCompleteModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule,

    SharedModule
  ],
  exports: [
    UserMessageAssistantDeletionFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    LlmActionFormAutoCompleteModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule,

    SharedModule
  ]
})
export class UserMessageAssistantDeletionFormModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { AdobeePdfToChuncksPageUiListComponent } from 'src/app/generated/dataPages/list/AdobeePdfToChuncks/AdobeePdfToChuncks.component';
import { AdobeePdfToChuncksPageFormComponent } from 'src/app/generated/dataPages/form/AdobeePdfToChuncks/AdobeePdfToChuncks.component';
import { AdobeePdfToChuncksPageUiListModule } from 'src/app/generated/dataPages/list/AdobeePdfToChuncks/AdobeePdfToChuncks.module';
import { AdobeePdfToChuncksPageFormComponentModule } from 'src/app/generated/dataPages/form/AdobeePdfToChuncks/AdobeePdfToChuncks.module';

export class AdobeePdfToChuncksRoutes {
  public static routes = [
    {
      path: 'AdobeePdfToChuncks/list',
      component: AdobeePdfToChuncksPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AdobeePdfToChuncks/edit/:id',
      component: AdobeePdfToChuncksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AdobeePdfToChuncks/new/:id',
      component: AdobeePdfToChuncksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [AdobeePdfToChuncksPageUiListModule, AdobeePdfToChuncksPageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['require']"><mat-checkbox formControlName="require">require</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['type']">
    <app-protoschema-form-auto-complete [formCtrl]="formGroup['_type']" placeholder="type"> </app-protoschema-form-auto-complete>
  </div>
</div>

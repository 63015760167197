/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { ExcelToChunksPageUiListComponent } from 'src/app/generated/dataPages/list/ExcelToChunks/ExcelToChunks.component';
import { ExcelToChunksPageFormComponent } from 'src/app/generated/dataPages/form/ExcelToChunks/ExcelToChunks.component';
import { ExcelToChunksPageUiListModule } from 'src/app/generated/dataPages/list/ExcelToChunks/ExcelToChunks.module';
import { ExcelToChunksPageFormComponentModule } from 'src/app/generated/dataPages/form/ExcelToChunks/ExcelToChunks.module';

export class ExcelToChunksRoutes {
  public static routes = [
    {
      path: 'ExcelToChunks/list',
      component: ExcelToChunksPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'ExcelToChunks/edit/:id',
      component: ExcelToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'ExcelToChunks/new/:id',
      component: ExcelToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [ExcelToChunksPageUiListModule, ExcelToChunksPageFormComponentModule];
}

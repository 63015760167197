/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnChanges, ViewChild, OnInit, Input, Self, SimpleChanges } from '@angular/core';
import { interfaces, ObjectId } from '@leandredev/adaptivai-common-lib';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { DataService } from 'src/app/dataService/dataService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAdminUiListComponent } from '../../../ui/list/UserAdmin/UserAdmin.component';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { ListComponent, PageTitleService } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-useradmin-list',
  templateUrl: './UserAdmin.component.html',
  styleUrls: ['./UserAdmin.component.scss'],
  providers: [UIComponentState]
})
export class UserAdminPageUiListComponent extends ListComponent<interfaces.IUserAdmin> implements OnInit, OnChanges {
  @ViewChild(UserAdminUiListComponent, { static: true }) table!: UserAdminUiListComponent;

  @Input()
  public filterQuery!: string;
  public columns: ListColumn[] = [
    { colId: 'firstName', visible: false, humanName: 'firstName' },
    { colId: 'lastName', visible: false, humanName: 'lastName' },
    { colId: 'email', visible: false, humanName: 'email' },
    { colId: 'password', visible: false, humanName: 'password' },
    { colId: 'name', visible: false, humanName: 'name' },
    { colId: 'resetPasswordKey', visible: false, humanName: 'resetPasswordKey' },
    { colId: 'resetPasswordEndDate', visible: false, humanName: 'resetPasswordEndDate' },
    { colId: 'userConf', visible: false, humanName: 'userConf' },
    { colId: 'superAdmin', visible: false, humanName: 'superAdmin' },
    { colId: 'apiKey', visible: false, humanName: 'apiKey' }
  ];

  constructor(
    protected dataService: DataService,
    route: Router,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    protected titleService: PageTitleService
  ) {
    super(route, activeRoute, uIComponentState);

    this.titleService.setPageTitle(`UserAdmin`);

    this.httpServiceBase = dataService.api.collections.UserAdmin;
  }

  setDefaultStatePopValues(): void {
    // super.setDefaultStatePopValues();
    this.pageSizeOptions = [20, 50, 100, 500];
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = this.columns.slice(0, 3).map((t) => t.colId);
    }
  }

  afterStateChanged(): void {
    // super.afterStateChanged();
    if (this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((col) => {
        const colToActivateIndex = this.columns.findIndex((c) => c.colId === col);

        if (colToActivateIndex > -1) {
          this.columns[colToActivateIndex].visible = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBase = this.filterQuery;
  }

  colsFilterChangeListener($event) {
    this.displayedColumns = $event;
  }

  public add(className = ''): void {
    if (className === '') {
      void this.router.navigate([`UserAdmin/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    } else {
      void this.router.navigate([`${className}/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    }
  }

  onFilterChange($event): void {
    this.applyFilter($event);
  }

  override rowClicked(row: interfaces.IUserAdmin): void {
    if (!row._class) {
      row._class = 'UserAdmin';
    }
    super.rowClicked(row);
  }
}

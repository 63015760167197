<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['styleType']">
    <mat-form-field appearance="fill">
      <mat-label>styleType</mat-label>

      <mat-select placeholder="Select" formControlName="styleType">
        <mat-option value="style">style</mat-option>

        <mat-option value="brand_tone_of_voice">brand_tone_of_voice</mat-option>

        <mat-option value="corporate_positioning">corporate_positioning</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['styleEmitter']">
    <mat-form-field appearance="fill">
      <mat-label>styleEmitter</mat-label>
      <input matInput placeholder="" formControlName="styleEmitter" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['styleDescription']">
    <mat-form-field appearance="fill">
      <mat-label>styleDescription</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="styleDescription"></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['copywriterStyleRecordId']">
    <mat-form-field appearance="fill">
      <mat-label>copywriterStyleRecordId</mat-label>
      <input matInput placeholder="" formControlName="copywriterStyleRecordId" />
    </mat-form-field>
  </div>
</div>

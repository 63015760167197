import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '@leandredev/common-ng16/components';
import { DayjsModule } from '@leandredev/common-ng16';
import { FileImageDataToChunksUiListComponent } from './FileImageDataToChunks.component';

@NgModule({
  declarations: [FileImageDataToChunksUiListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    SharedModule,
    DayjsModule
  ],
  exports: [
    FileImageDataToChunksUiListComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    SharedModule,
    DayjsModule
  ]
})
export class FileImageDataToChunksListModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { DocumentChunkListSubDocModule } from '../../listSubdoc/DocumentChunk/DocumentChunk.module';

import { CopywriterOutputFormComponent } from './CopywriterOutput.component';
@NgModule({
  declarations: [CopywriterOutputFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    DocumentChunkListSubDocModule
  ],
  exports: [
    CopywriterOutputFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    DocumentChunkListSubDocModule
  ]
})
export class CopywriterOutputFormModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <input matInput placeholder="" formControlName="message" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmActionId']">
    <app-llmaction-form-auto-complete [formCtrl]="formGroup['_llmActionId']" placeholder="llmActionId"> </app-llmaction-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceIds']">
    <app-string-list label="serviceIds" [formArr]="formGroup['_serviceIds']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantSessionId']">
    <app-assistantsession-form-auto-complete [formCtrl]="formGroup['_assistantSessionId']" placeholder="assistantSessionId">
    </app-assistantsession-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['errorsList']">
    <mat-expansion-panel
      [expanded]="state['errorsList']?._expanded"
      (closed)="propState('errorsList_expanded', null)"
      (opened)="propState('errorsList_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> errorsList {{ fieldClassHumanName[formGroup._errorsList?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_errorsList']?.__class?.value">
          <app-ui-form-errorobj id="errorsList" *ngSwitchCase="'ErrorObj'" [formGroup]="formGroup['_errorsList']"></app-ui-form-errorobj>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>
</div>

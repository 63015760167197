import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { UIDocumentalistWelcomeFormComponent } from './UIDocumentalistWelcome.component';
@NgModule({
  declarations: [UIDocumentalistWelcomeFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, DirectiveModule],
  exports: [UIDocumentalistWelcomeFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule]
})
export class UIDocumentalistWelcomeFormModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isAbstract']"><mat-checkbox formControlName="isAbstract">isAbstract</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['isSchema']"><mat-checkbox formControlName="isSchema">isSchema</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['parentModel']">
    <app-protoschema-form-auto-complete [formCtrl]="formGroup['_parentModel']" placeholder="parentModel"> </app-protoschema-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['fields']">
    <app-ui-list-sub-doc-field id="fields" label="fields" [inputData]="formGroup['_fields']"> </app-ui-list-sub-doc-field>
  </div>
</div>

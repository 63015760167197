/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-userclientinvitation',
  templateUrl: './UserClientInvitation.component.html',
  styleUrls: ['./UserClientInvitation.component.scss'],
  providers: [UIComponentState]
})
export class UserClientInvitationUiListComponent extends UiList<interfaces.IUserClientInvitation> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      firstName: 'string',
      lastName: 'string',
      email: 'string',
      createdAt: 'date',
      userClientGroup: 'objectid',
      copywriterSetting: 'objectid',
      categoryEditor: 'boolean',
      tagEditor: 'boolean',
      documentUpload: 'boolean'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['firstName', 'lastName', 'email', 'createdAt'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

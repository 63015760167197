/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { TagTypePageUiListComponent } from 'src/app/generated/dataPages/list/TagType/TagType.component';
import { TagTypePageFormComponent } from 'src/app/generated/dataPages/form/TagType/TagType.component';
import { TagTypePageUiListModule } from 'src/app/generated/dataPages/list/TagType/TagType.module';
import { TagTypePageFormComponentModule } from 'src/app/generated/dataPages/form/TagType/TagType.module';

export class TagTypeRoutes {
  public static routes = [
    {
      path: 'TagType/list',
      component: TagTypePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'TagType/edit/:id',
      component: TagTypePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'TagType/new/:id',
      component: TagTypePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [TagTypePageUiListModule, TagTypePageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { PdfToImagesPageUiListComponent } from 'src/app/generated/dataPages/list/PdfToImages/PdfToImages.component';
import { PdfToImagesPageFormComponent } from 'src/app/generated/dataPages/form/PdfToImages/PdfToImages.component';
import { PdfToImagesPageUiListModule } from 'src/app/generated/dataPages/list/PdfToImages/PdfToImages.module';
import { PdfToImagesPageFormComponentModule } from 'src/app/generated/dataPages/form/PdfToImages/PdfToImages.module';

export class PdfToImagesRoutes {
  public static routes = [
    {
      path: 'PdfToImages/list',
      component: PdfToImagesPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'PdfToImages/edit/:id',
      component: PdfToImagesPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'PdfToImages/new/:id',
      component: PdfToImagesPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [PdfToImagesPageUiListModule, PdfToImagesPageFormComponentModule];
}

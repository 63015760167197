/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnChanges, ViewChild, OnInit, Input, Self, SimpleChanges } from '@angular/core';
import { interfaces, ObjectId } from '@leandredev/adaptivai-common-lib';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { DataService } from 'src/app/dataService/dataService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AssistantSessionUiListComponent } from '../../../ui/list/AssistantSession/AssistantSession.component';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { ListComponent, PageTitleService } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-assistantsession-list',
  templateUrl: './AssistantSession.component.html',
  styleUrls: ['./AssistantSession.component.scss'],
  providers: [UIComponentState]
})
export class AssistantSessionPageUiListComponent extends ListComponent<interfaces.IAssistantSession> implements OnInit, OnChanges {
  @ViewChild(AssistantSessionUiListComponent, { static: true }) table!: AssistantSessionUiListComponent;

  @Input()
  public filterQuery!: string;
  public columns: ListColumn[] = [
    { colId: 'name', visible: false, humanName: 'name' },
    { colId: 'assistantName', visible: false, humanName: 'assistantName' },
    { colId: 'creationDate', visible: false, humanName: 'creationDate' },
    { colId: 'flowView', visible: false, humanName: 'flowView' },
    { colId: 'state', visible: false, humanName: 'state' },
    { colId: 'lmActionHistory', visible: false, humanName: 'lmActionHistory' },
    { colId: 'userId', visible: false, humanName: 'userId' },
    { colId: 'updatedDate', visible: false, humanName: 'updatedDate' },
    { colId: 'userInput', visible: false, humanName: 'userInput' },
    { colId: 'drawerComponent', visible: false, humanName: 'drawerComponent' },
    { colId: 'userLang', visible: false, humanName: 'userLang' },
    { colId: 'extraDrawerComponent', visible: false, humanName: 'extraDrawerComponent' }
  ];

  constructor(
    protected dataService: DataService,
    route: Router,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    protected titleService: PageTitleService
  ) {
    super(route, activeRoute, uIComponentState);

    this.titleService.setPageTitle(`AssistantSession`);

    this.httpServiceBase = dataService.api.collections.AssistantSession;
  }

  setDefaultStatePopValues(): void {
    // super.setDefaultStatePopValues();
    this.pageSizeOptions = [20, 50, 100, 500];
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = this.columns.slice(0, 3).map((t) => t.colId);
    }
  }

  afterStateChanged(): void {
    // super.afterStateChanged();
    if (this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((col) => {
        const colToActivateIndex = this.columns.findIndex((c) => c.colId === col);

        if (colToActivateIndex > -1) {
          this.columns[colToActivateIndex].visible = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBase = this.filterQuery;
  }

  colsFilterChangeListener($event) {
    this.displayedColumns = $event;
  }

  public add(className = ''): void {
    if (className === '') {
      void this.router.navigate([`AssistantSession/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    } else {
      void this.router.navigate([`${className}/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    }
  }

  onFilterChange($event): void {
    this.applyFilter($event);
  }

  override rowClicked(row: interfaces.IAssistantSession): void {
    if (!row._class) {
      row._class = 'AssistantSession';
    }
    super.rowClicked(row);
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-flow',
  templateUrl: './Flow.component.html',
  styleUrls: ['./Flow.component.scss'],
  providers: [UIComponentState]
})
export class FlowUiListComponent extends UiList<interfaces.IFlow> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      description: 'string',
      author: 'objectid',
      creationDate: 'date',
      updated: 'date',
      steps: 'subdoc',
      params: 'subdoc',
      docspace: 'objectid'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'description', 'author', 'creationDate'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

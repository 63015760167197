<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="urlBase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>urlBase</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.urlBase }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="port">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>port</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.port }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="debug">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>debug</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.debug }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="licence_well-known">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>licence_well-known</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.licence_well - known }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="secretKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>secretKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.secretKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="publicAccess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>publicAccess</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.publicAccess | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="bindIp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>bindIp</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.bindIp }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="headers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>headers</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.headers | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="accessControlAllowOrigin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>accessControlAllowOrigin</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.accessControlAllowOrigin }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="confServiceUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>confServiceUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.confServiceUrl }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="bddUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>bddUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.bddUrl }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tockenDuration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Durée de validité du token de connexion en secondes</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.tockenDuration }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="adminSiteUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>url de admin pour les retours de connexion admin</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.adminSiteUrl }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="mongoDbUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>mongoDbUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.mongoDbUrl }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="openAiApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>openAiApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.openAiApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="filesPath">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>filesPath</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.filesPath }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deeplApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>deeplApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.deeplApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="adobePdfServiceClientId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>adobePdfServiceClientId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.adobePdfServiceClientId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="adobePdfServiceClientSecret">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>adobePdfServiceClientSecret</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.adobePdfServiceClientSecret }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="sendgridConf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>sendgridConf</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.sendgridConf | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="sendgridApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>sendgridApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.sendgridApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="azureOpenApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>azureOpenApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.azureOpenApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="azureOpenAiUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>azureOpenAiUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.azureOpenAiUrl }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="mistralApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>mistralApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.mistralApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="geminiApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>geminiApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.geminiApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="anthropicApiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>anthropicApiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.anthropicApiKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="vectorStoreDriver">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>vectorStoreDriver</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.vectorStoreDriver }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="embeddingDriver">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>embeddingDriver</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.embeddingDriver }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="embeddingModel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>embeddingModel</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.embeddingModel }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="embeddingVectorLength">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>embeddingVectorLength</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.embeddingVectorLength }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="imgLlm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>imgLlm</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.imgLlm }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="imgLlmModel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>imgLlmModel</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.imgLlmModel }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { _view_paramsPageUiListComponent } from 'src/app/generated/dataPages/list/_view_params/_view_params.component';
import { _view_paramsPageFormComponent } from 'src/app/generated/dataPages/form/_view_params/_view_params.component';
import { _view_paramsPageUiListModule } from 'src/app/generated/dataPages/list/_view_params/_view_params.module';
import { _view_paramsPageFormComponentModule } from 'src/app/generated/dataPages/form/_view_params/_view_params.module';

export class _view_paramsRoutes {
  public static routes = [
    {
      path: '_view_params/list',
      component: _view_paramsPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: '_view_params/edit/:id',
      component: _view_paramsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: '_view_params/new/:id',
      component: _view_paramsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [_view_paramsPageUiListModule, _view_paramsPageFormComponentModule];
}

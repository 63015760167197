/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageProgressPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageProgress/UserMessageProgress.component';
import { UserMessageProgressPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageProgress/UserMessageProgress.component';
import { UserMessageProgressPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageProgress/UserMessageProgress.module';
import { UserMessageProgressPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageProgress/UserMessageProgress.module';

export class UserMessageProgressRoutes {
  public static routes = [
    {
      path: 'UserMessageProgress/list',
      component: UserMessageProgressPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageProgress/edit/:id',
      component: UserMessageProgressPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageProgress/new/:id',
      component: UserMessageProgressPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageProgressPageUiListModule, UserMessageProgressPageFormComponentModule];
}

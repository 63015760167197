import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpServiceBase } from '@leandredev/common-ng16/httpClient';
import { RunTimeDataService } from '@leandredev/common-ng16/infraService';
import { Api_plateforme, api_collection_plateform } from '@leandredev/adaptivai-ng16-lib/httpClient/services/api';
import { HttpClient } from '@angular/common/http';
import { IUserAdmin } from '@leandredev/adaptivai-common-lib/dist/interfaces';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Authentication } from '@leandredev/adaptivai-ng16-lib/httpClient/services/authentication';
import { AssistantSession } from '@leandredev/adaptivai-ng16-lib/httpClient/services/AssistantSession';
import { UserMessages } from '@leandredev/adaptivai-ng16-lib/httpClient/services/UserMessages';
import { Document } from '@leandredev/adaptivai-ng16-lib/httpClient/services/Document';
import { Admin } from '@leandredev/adaptivai-ng16-lib/httpClient/services/Admin';
import { UserClient } from '@leandredev/adaptivai-ng16-lib/httpClient/services/UserClient';
import { FileService } from '@leandredev/adaptivai-ng16-lib/httpClient/services/FileService';
import { TagService } from '@leandredev/adaptivai-ng16-lib/httpClient/services/TagService';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public api: Api_plateforme;
  public authService: Authentication;
  public assistantSession: AssistantSession;
  public userMessage: UserMessages;
  public documents: Document;
  public fileService: FileService;
  public admin: Admin;
  public userClient: UserClient;

  public tagService: TagService;

  public uploadUrl = '';
  public avatarUrl = '';

  public fileUploadServiceUrl = '';

  public proxyObj: { [key: string]: { [key: string]: any } } = {};

  public proxyObservable: {
    [key: string]: { [key: string]: Observable<any> };
  } = {};

  private adminProfile: BehaviorSubject<IUserAdmin> = new BehaviorSubject<IUserAdmin>(null);
  public profile: Observable<IUserAdmin>;

  constructor(
    private http: HttpClient,
    private runTimeDataService: RunTimeDataService
  ) {
    this.profile = this.adminProfile.asObservable();

    this.api = new Api_plateforme(this.http);

    this.authService = new Authentication(this.http, false);
    this.documents = new Document(this.http, true);
    this.fileService = new FileService(this.http, true);
    this.admin = new Admin(this.http, true);
    this.userClient = new UserClient(this.http, true);
    this.tagService = new TagService(this.http, true);

    this.userMessage = new UserMessages(this.http, true);
    this.assistantSession = new AssistantSession(this.http, true);
    this.runTimeDataService.getObservableStore('publicConf').subscribe((data: any) => {
      if (data) {
        this.authService.urlCollection = `${data.api}authentication/`;
        this.userMessage.urlCollection = `${data.api}userMessages/`;
        this.assistantSession.urlCollection = `${data.api}assistantSession/`;
        this.documents.urlCollection = `${data.api}documentloader`;
        this.fileService.urlCollection = `${data.api}fileservice`;
        this.admin.urlCollection = `${data.api}admin/`;
        this.userClient.urlCollection = `${data.api}userclient/`;
        this.tagService.urlCollection = `${data.api}tagService/`;
        this.api.urlApi = `${data.api}bdd/`;
      }
    });

    this.runTimeDataService.getObservableStore('ConfService').subscribe((profile: IUserAdmin) => {
      if (profile) this.adminProfile.next(profile);
    });
  }

  public getProxyData<T>(columnName: keyof api_collection_plateform, idObj: string): Observable<T> {
    const colName: string = columnName as string;

    if (this.proxyObj[colName] && this.proxyObj[colName][idObj]) {
      return of(this.proxyObj[colName][idObj]);
    } else if (this.proxyObservable[colName] && this.proxyObservable[colName][idObj]) {
      return this.proxyObservable[colName][idObj];
    } else {
      if (!this.proxyObj[colName]) {
        this.proxyObj[colName] = {};
      }
      if (!this.proxyObservable[colName]) {
        this.proxyObservable[colName] = {};
      }

      const caller: HttpServiceBase<T> = this.api.collections[columnName] as HttpServiceBase<T>;
      this.proxyObservable[colName][idObj] = caller.get(idObj).pipe(
        map((dataRes) => {
          delete this.proxyObservable[colName][idObj];
          if (dataRes.code === 200) {
            if (dataRes.response.length > 0) {
              this.proxyObj[colName][idObj] = dataRes.response[0];
              return this.proxyObj[colName][idObj];
            } else {
              return null;
            }
          } else {
            return null;
          }
        })
      );

      return this.proxyObservable[colName][idObj];
    }
  }
}

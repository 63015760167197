/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnChanges, ViewChild, OnInit, Input, Self, SimpleChanges } from '@angular/core';
import { interfaces, ObjectId } from '@leandredev/adaptivai-common-lib';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { DataService } from 'src/app/dataService/dataService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserMessageAssistantDeletionUiListComponent } from '../../../ui/list/UserMessageAssistantDeletion/UserMessageAssistantDeletion.component';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { ListComponent, PageTitleService } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-usermessageassistantdeletion-list',
  templateUrl: './UserMessageAssistantDeletion.component.html',
  styleUrls: ['./UserMessageAssistantDeletion.component.scss'],
  providers: [UIComponentState]
})
export class UserMessageAssistantDeletionPageUiListComponent
  extends ListComponent<interfaces.IUserMessageAssistantDeletion>
  implements OnInit, OnChanges
{
  @ViewChild(UserMessageAssistantDeletionUiListComponent, { static: true }) table!: UserMessageAssistantDeletionUiListComponent;

  @Input()
  public filterQuery!: string;
  public columns: ListColumn[] = [
    { colId: 'message', visible: false, humanName: 'message' },
    { colId: 'llmActionId', visible: false, humanName: 'llmActionId' },
    { colId: 'creationDate', visible: false, humanName: 'creationDate' },
    { colId: 'serviceIds', visible: false, humanName: 'serviceIds' },
    { colId: 'userId', visible: false, humanName: 'userId' },
    { colId: 'assistantSessionId', visible: false, humanName: 'assistantSessionId' },
    { colId: 'flowViews', visible: false, humanName: 'flowViews' }
  ];

  constructor(
    protected dataService: DataService,
    route: Router,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    protected titleService: PageTitleService
  ) {
    super(route, activeRoute, uIComponentState);

    this.titleService.setPageTitle(`UserMessageAssistantDeletion`);

    this.httpServiceBase = dataService.api.collections.UserMessageAssistantDeletion;
  }

  setDefaultStatePopValues(): void {
    // super.setDefaultStatePopValues();
    this.pageSizeOptions = [20, 50, 100, 500];
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = this.columns.slice(0, 3).map((t) => t.colId);
    }
  }

  afterStateChanged(): void {
    // super.afterStateChanged();
    if (this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((col) => {
        const colToActivateIndex = this.columns.findIndex((c) => c.colId === col);

        if (colToActivateIndex > -1) {
          this.columns[colToActivateIndex].visible = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBase = this.filterQuery;
  }

  colsFilterChangeListener($event) {
    this.displayedColumns = $event;
  }

  public add(className = ''): void {
    if (className === '') {
      void this.router.navigate([`UserMessageAssistantDeletion/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    } else {
      void this.router.navigate([`${className}/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    }
  }

  onFilterChange($event): void {
    this.applyFilter($event);
  }

  override rowClicked(row: interfaces.IUserMessageAssistantDeletion): void {
    if (!row._class) {
      row._class = 'UserMessageAssistantDeletion';
    }
    super.rowClicked(row);
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnChanges, ViewChild, OnInit, Input, Self, SimpleChanges } from '@angular/core';
import { interfaces, ObjectId } from '@leandredev/adaptivai-common-lib';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { DataService } from 'src/app/dataService/dataService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { _view_paramsUiListComponent } from '../../../ui/list/_view_params/_view_params.component';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { ListComponent, PageTitleService } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-_view_params-list',
  templateUrl: './_view_params.component.html',
  styleUrls: ['./_view_params.component.scss'],
  providers: [UIComponentState]
})
export class _view_paramsPageUiListComponent extends ListComponent<interfaces.I_view_params> implements OnInit, OnChanges {
  @ViewChild(_view_paramsUiListComponent, { static: true }) table!: _view_paramsUiListComponent;

  @Input()
  public filterQuery!: string;
  public columns: ListColumn[] = [
    { colId: 'name', visible: false, humanName: 'name' },
    { colId: 'description', visible: false, humanName: 'description' },
    { colId: 'output', visible: false, humanName: 'output' },
    { colId: 'model', visible: false, humanName: 'model' },
    { colId: 'pipeline', visible: false, humanName: 'pipeline' },
    { colId: 'params', visible: false, humanName: 'params' },
    { colId: 'castParams', visible: false, humanName: 'castParams' }
  ];

  constructor(
    protected dataService: DataService,
    route: Router,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    protected titleService: PageTitleService
  ) {
    super(route, activeRoute, uIComponentState);

    this.titleService.setPageTitle(`_view_params`);

    this.httpServiceBase = dataService.api.collections._view_params;
  }

  setDefaultStatePopValues(): void {
    // super.setDefaultStatePopValues();
    this.pageSizeOptions = [20, 50, 100, 500];
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = this.columns.slice(0, 3).map((t) => t.colId);
    }
  }

  afterStateChanged(): void {
    // super.afterStateChanged();
    if (this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((col) => {
        const colToActivateIndex = this.columns.findIndex((c) => c.colId === col);

        if (colToActivateIndex > -1) {
          this.columns[colToActivateIndex].visible = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBase = this.filterQuery;
  }

  colsFilterChangeListener($event) {
    this.displayedColumns = $event;
  }

  public add(className = ''): void {
    if (className === '') {
      void this.router.navigate([`_view_params/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    } else {
      void this.router.navigate([`${className}/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    }
  }

  onFilterChange($event): void {
    this.applyFilter($event);
  }

  override rowClicked(row: interfaces.I_view_params): void {
    if (!row._class) {
      row._class = '_view_params';
    }
    super.rowClicked(row);
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['analystOutput']">
    <mat-expansion-panel
      [expanded]="state['analystOutput']?._expanded"
      (closed)="propState('analystOutput_expanded', null)"
      (opened)="propState('analystOutput_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> analystOutput {{ fieldClassHumanName[formGroup._analystOutput?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_analystOutput']?.__class?.value">
          <app-ui-form-documentdataanalystoutput
            id="analystOutput"
            *ngSwitchCase="'DocumentDataAnalystOutput'"
            [formGroup]="formGroup['_analystOutput']"
          ></app-ui-form-documentdataanalystoutput>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['content']">
    <mat-form-field appearance="fill">
      <mat-label>content</mat-label>
      <input matInput placeholder="" formControlName="content" />
    </mat-form-field>
  </div>
</div>

import { Injectable } from '@angular/core';
import { DataService } from '../dataService/dataService.service';
import { Observable, Subject } from 'rxjs';

import { interfaces } from '@leandredev/adaptivai-common-lib';
import { RunTimeDataService } from '@leandredev/common-ng16/infraService';
import { ApiHelper } from '@leandredev/common-ng16/httpClient';

@Injectable({
  providedIn: 'root'
})
export class UserMessagesService {
  constructor(
    protected dataService: DataService,
    protected runTimeDataService: RunTimeDataService
  ) {
    this.runTimeDataService.getObservableStore('token').subscribe({
      next: (token) => {
        if (this.eventSource) {
          this.eventSource.close();
          this.inConnexion = false;
        }
        if (token) {
          this.internalConnexion();
        }
      },
      error: (err) => {
        console.warn(err);
      }
    });
  }

  checkConnexion(): void {
    this.internalConnexion();
  }

  protected timeOut;
  protected reConnect(): void {
    clearTimeout(this.timeOut);
    this.timeOut = setInterval(() => {
      this.internalConnexion();
    }, 30000);
  }

  protected messagesSubject: Subject<interfaces.IUserMessage> = new Subject<interfaces.IUserMessage>();

  public internalMessagesObs: Observable<interfaces.IUserMessage> = this.messagesSubject.asObservable();
  public get messagesObs(): Observable<interfaces.IUserMessage> {
    this.internalConnexion();
    return this.internalMessagesObs;
  }

  protected inConnexion: boolean;

  protected internalConnexion(): void {
    if (this.inConnexion) {
      return;
    }
    this.reConnect();
    this.inConnexion = true;
    this.dataService.userMessage
      .connectUser({})
      .pipe(ApiHelper.resultToObjWEmptyError('usermessage connexion error'))
      .subscribe({
        next: (val) => {
          this.createEventSource(val);
        },
        error: (error) => {
          this.hasError(error);
        }
      });
  }
  protected eventSource: EventSource;
  protected createEventSource(sourceId: string): void {
    this.eventSource = new EventSource(`${this.dataService.userMessage.urlCollection}getMessage/${sourceId}/`);
    this.eventSource.addEventListener('UserMessage', (event) => {
      this.reConnect();
      try {
        const messageData: interfaces.IUserMessage = JSON.parse(event.data);
        this.messagesSubject.next(messageData);
      } catch (error) {
        console.warn(error);
      }
    });

    this.eventSource.onerror = (err) => {
      if (this.eventSource.readyState === EventSource.CLOSED || this.eventSource.readyState === EventSource.CONNECTING) {
        this.eventSource.removeAllListeners();
        this.eventSource.close();
        this.inConnexion = false;
        this.reConnect();
        return;
      } else {
        this.hasError(err);
      }
    };
  }

  protected hasError(err): void {
    this.inConnexion = false;
    console.warn(err);
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['content']">
    <mat-form-field appearance="fill">
      <mat-label>content</mat-label>
      <input matInput placeholder="" formControlName="content" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['images']">
    <app-string-list label="images" [formArr]="formGroup['_images']"></app-string-list>
  </div>
</div>

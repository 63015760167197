import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { PageLayoutModule } from 'src/@fury/shared/page-layout/page-layout.module';
import { ConfirmationDialogModule } from 'src/app/app-specific/confirmation/confirmation.module';
import { AdobeWordPdfToChunksPageFormComponent } from './AdobeWordPdfToChunks.component';
import { AdobeWordPdfToChunksFormModule } from '../../../ui/form/AdobeWordPdfToChunks/AdobeWordPdfToChunks.module';

@NgModule({
  declarations: [AdobeWordPdfToChunksPageFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FuryCardModule,
    FurySharedModule,
    PageLayoutModule,
    ConfirmationDialogModule,
    AdobeWordPdfToChunksFormModule
  ],

  exports: [
    AdobeWordPdfToChunksPageFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AdobeWordPdfToChunksFormModule,
    FurySharedModule,
    FuryCardModule
  ]
})
export class AdobeWordPdfToChunksPageFormComponentModule {}

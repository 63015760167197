<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['usePrevious']">
    <app-string-list label="usePrevious" [formArr]="formGroup['_usePrevious']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['outputTest']">
    <mat-form-field appearance="fill">
      <mat-label>outputTest</mat-label>
      <input matInput placeholder="" formControlName="outputTest" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['flowParams']">
    <app-ui-list-sub-doc-flowparams id="flowParams" label="flowParams" [inputData]="formGroup['_flowParams']"> </app-ui-list-sub-doc-flowparams>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['analystRequest']">
    <mat-form-field appearance="fill">
      <mat-label>analystRequest</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['analystSettings']">
    <mat-form-field appearance="fill">
      <mat-label>analystSettings</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { FileImageDataToChunksPageUiListComponent } from 'src/app/generated/dataPages/list/FileImageDataToChunks/FileImageDataToChunks.component';
import { FileImageDataToChunksPageFormComponent } from 'src/app/generated/dataPages/form/FileImageDataToChunks/FileImageDataToChunks.component';
import { FileImageDataToChunksPageUiListModule } from 'src/app/generated/dataPages/list/FileImageDataToChunks/FileImageDataToChunks.module';
import { FileImageDataToChunksPageFormComponentModule } from 'src/app/generated/dataPages/form/FileImageDataToChunks/FileImageDataToChunks.module';

export class FileImageDataToChunksRoutes {
  public static routes = [
    {
      path: 'FileImageDataToChunks/list',
      component: FileImageDataToChunksPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'FileImageDataToChunks/edit/:id',
      component: FileImageDataToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'FileImageDataToChunks/new/:id',
      component: FileImageDataToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [FileImageDataToChunksPageUiListModule, FileImageDataToChunksPageFormComponentModule];
}

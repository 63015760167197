<div class="selector-container">
  <span class="table-desc">{{ label }}</span>

  <table
    cdkDropList
    #selectorTable
    mat-table
    [dataSource]="inputData.controls"
    multiTemplateDataRows
    class="mat-elevation-z8"
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container matColumnDef="drag">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="drag" mat-cell *matCellDef="let row">
        <mat-icon cdkDragHandle>drag_handle</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="_class">
      <th mat-header-cell *matHeaderCellDef>type</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">{{ classToHumanName[row?.value?._class] }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>name</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._name?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>description</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._description?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="path">
      <th mat-header-cell *matHeaderCellDef>path</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._path?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="verbe">
      <th mat-header-cell *matHeaderCellDef>verbe</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._verbe?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="bodyFormat">
      <th mat-header-cell *matHeaderCellDef>bodyFormat</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._bodyFormat?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="returnFormat">
      <th mat-header-cell *matHeaderCellDef>returnFormat</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._returnFormat?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pathParams">
      <th mat-header-cell *matHeaderCellDef>pathParams</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row.pathParams | json }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="queryParams">
      <th mat-header-cell *matHeaderCellDef>queryParams</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row.queryParams | json }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="bodyParam">
      <th mat-header-cell *matHeaderCellDef>bodyParam</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          <app-ui-ref #obj colName="protoschema" [idRef]="row._bodyParam?.value">
            {{ obj.data?.name || obj.data?.title || row._bodyParam?.value }}
          </app-ui-ref>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="returnType">
      <th mat-header-cell *matHeaderCellDef>returnType</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          <app-ui-ref #obj colName="protoschema" [idRef]="row._returnType?.value">
            {{ obj.data?.name || obj.data?.title || row._returnType?.value }}
          </app-ui-ref>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions">Actions</th>
      <td mat-cell *matCellDef="let element" class="actions">
        <button class="table-button" mat-button color="accent" (click)="deleteTemplate($event, element)">
          <mat-icon aria-label="icon button trash">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="content-form" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
          <mat-card>
            <mat-card-content>
              <!--ajout d'un id-->

              <app-ui-form-swaggerpath id="swaggerpath_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'SwaggerPath'">
              </app-ui-form-swaggerpath>
            </mat-card-content>
          </mat-card>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="addObject">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="columnsToDisplay.length">
        <button mat-button [matMenuTriggerFor]="addObj">Selectionner le type d'élément à ajouter</button>
        <mat-menu #addObj="matMenu">
          <button mat-menu-item (click)="addItemByMenu('SwaggerPath')">SwaggerPath</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      cdkDrag
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr mat-footer-row *matFooterRowDef="['addObject']"></tr>
  </table>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { TagFormAutoCompleteModule } from '../../chipList/Tag/Tag.module';

import { DocSpaceRightFormComponent } from './DocSpaceRight.component';
@NgModule({
  declarations: [DocSpaceRightFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, TagFormAutoCompleteModule],
  exports: [
    DocSpaceRightFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatCheckboxModule,

    TagFormAutoCompleteModule
  ]
})
export class DocSpaceRightFormModule {}

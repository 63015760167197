/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { interfaces } from '@leandredev/adaptivai-common-lib';
import { DataService } from 'src/app/dataService/dataService.service';
import { mergeMap, map, startWith, switchMap, debounceTime } from 'rxjs/operators';
import { AutoCompleteComponent } from '@leandredev/common-ng16/components';
import { Observable, Subscription, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FormArrayOf } from '@leandredev/common-ng16/form';

@Component({
  selector: 'app-tag-form-auto-complete',
  templateUrl: './Tag.component.html',
  styleUrls: ['./Tag.component.scss']
})
export class TagFormAutoCompleteComponent extends AutoCompleteComponent<interfaces.ITag> implements OnInit, OnDestroy {
  @Input()
  public get formArr(): FormArrayOf<FormControl> {
    return this.pFormAr;
  }
  public set formArr(value: FormArrayOf<FormControl>) {
    if (value !== this.pFormAr) {
      if (this.pFormArSub) {
        this.pFormArSub.unsubscribe();
        this.pFormArSub = null;
      }
      this.pFormAr = value;
      if (this.pFormAr) {
        this.pFormArSub = this.pFormAr.valueChanges
          .pipe(
            startWith(this.pFormAr.value),
            debounceTime(200),
            switchMap((ids) => {
              if (ids && ids.length > 0) {
                let newIds: string[];
                newIds = ids.filter((id) => {
                  return this.populatedValue.find((elt) => elt._id === id) == null;
                });
                const currentPopulatedValue: interfaces.ITag[] = this.populatedValue.slice();

                if (newIds && newIds.length > 0) {
                  return this.httpServiceBase.get('_id/' + newIds.join('/') + '/' + newIds.length + '/[/type/$pop/type_pop.docspaces/$pop/').pipe(
                    map((dataRes) => {
                      if (dataRes.code === 200) {
                        const all: interfaces.ITag[] = currentPopulatedValue.concat(dataRes.response);
                        this.populatedValue = ids.map((id) => {
                          let res: interfaces.ITag = all.find((elt) => {
                            return elt._id === id;
                          });
                          if (res) {
                            return res;
                          } else {
                            res = <any>{ _id: id, name: 'error' };
                            return res;
                          }
                        });
                      } else {
                        // console.log("err: " + dataRes.message);
                        this.populatedValue = ids.map((id) => {
                          let res: interfaces.ITag = this.populatedValue.find((elt) => {
                            return elt._id === id;
                          });
                          if (res) {
                            return res;
                          } else {
                            res = <any>{ _id: id, name: 'error' };
                            return res;
                          }
                        });
                      }
                    })
                  );
                } else {
                  return of(null);
                }
              } else {
                this.populatedValue = [];
                return of(null);
              }
            })
          )
          .subscribe();
      }
    }
  }
  protected pFormControl: FormControl;
  protected pFormControlSub: Subscription;
  @Input()
  public get formCtrl(): FormControl {
    return this.pFormControl;
  }
  public set formCtrl(value: FormControl) {
    if (value !== this.pFormControl) {
      if (this.pFormControlSub) {
        this.pFormControlSub.unsubscribe();
        this.pFormControlSub = null;
      }
      this.pFormControl = value;
      if (this.pFormControl) {
        this.pFormControlSub = this.pFormControl.valueChanges
          .pipe(
            startWith(this.pFormControl.value),
            debounceTime(200),
            switchMap((id) => {
              if (id) {
                if (this.populatedValue.find((elt) => elt._id === id)) {
                  return of(null);
                } else {
                  return this.httpServiceBase.get(`_id/${id}/=/type/$pop/type_pop.docspaces/$pop/`).pipe(
                    map((dataRes) => {
                      if (dataRes.code === 200) {
                        if (dataRes.response.length === 0) {
                          this.populatedValue = [<any>{ _id: id, name: 'error' }];
                        } else {
                          this.populatedValue = [dataRes.response[0]];
                        }
                      } else {
                        this.populatedValue = [<any>{ _id: id, name: 'error' + dataRes.message }];
                        // console.log("err " + dataRes.message);
                      }
                    })
                  );
                }
              } else {
                this.populatedValue = [];
                return of(null);
              }
            })
          )
          .subscribe();
      }
    }
  }
  private _filterQuery = '';
  @Input()
  public set filterQuery(value: string) {
    this._filterQuery = value;
  }
  public get filterQuery(): string {
    return this._filterQuery;
  }

  @Input()
  public get hasOverridedData(): boolean {
    return this._hasOverridedData;
  }
  public set hasOverridedData(value: boolean) {
    this._hasOverridedData = value;
    if (!value) {
      return;
    }
    this.unsubscribePformCtrl();
    if (this.overridedData) {
      this.filteredData = this.overridedData;
    }
  }
  private _hasOverridedData = false;

  @Input()
  public get overridedData(): interfaces.ITag[] {
    return this._overridedData;
  }
  public set overridedData(value: interfaces.ITag[]) {
    this._overridedData = value;
    if (!value || !this.hasOverridedData) {
      return;
    }
    this.filteredData = value;
  }
  private _overridedData: interfaces.ITag[] = [];

  constructor(protected dataService: DataService) {
    super();
    this.httpServiceBase = dataService.api.collections.Tag;

    if (this.hasOverridedData) {
      return;
    }
    this.pformCtrlSub = this._formCtrl.valueChanges
      .pipe(
        startWith(null),
        debounceTime(200),
        mergeMap((value) => {
          let query = '';
          const limit = `10/$limit/type/$pop/type_pop.docspaces/$pop/`;
          if (typeof value === 'string') {
            if (value) {
              value = value.trim();
              query = `name/${value}/i/$regex/type/$pop/type_pop.docspaces/$pop/`;
            } else {
              query = '*';
            }
          } else {
            query = '*';
          }
          if (this.filterQuery) {
            query = `${query}/${this.filterQuery}`;
          }

          return this.httpServiceBase.get(`${query}/${limit}`);
        }),

        map((result) => {
          if (!this.pformCtrlSub) {
            return;
          }
          if (this.hasOverridedData) {
            this.unsubscribePformCtrl();
            this.filteredData = this.overridedData;
            return;
          }

          this.filteredData = result.response;
        })
      )
      .subscribe();
  }

  private unsubscribePformCtrl(): void {
    if (!this.pformCtrlSub) {
      return;
    }
    this.pformCtrlSub.unsubscribe();
    // delete this.pformCtrlSub;
  }
}

<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>firstName</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.firstName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>lastName</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.lastName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.email }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>createdAt</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.createdAt | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userClientGroup">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userClientGroup</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <ng-container *ngFor="let val of row.userClientGroup">
              <app-ui-ref #obj colName="UserClientGroup" [idRef]="val">
                {{ obj.data?.name || obj.data?.title || val }}
              </app-ui-ref>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="copywriterSetting">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>copywriterSetting</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="CopywriterStyleRecord" [idRef]="row.copywriterSetting">
              {{ obj.data?.name || obj.data?.title || row.copywriterSetting }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="categoryEditor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>categoryEditor</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.categoryEditor }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tagEditor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>tagEditor</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.tagEditor }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="documentUpload">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>documentUpload</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.documentUpload }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

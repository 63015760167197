<mat-card class="document-card">
  <mat-card-header>
    <mat-card-title>{{ documentUpdate.item.name }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      {{ documentUpdate.item.text }}
    </p>
    <app-tag-selector
      (tagsChange)="updateTagForRequest($event)"
      [tags]="documentUpdate.tags || []"
      [selectedTags]="documentUpdate.item.tags_pop || []"
    ></app-tag-selector>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button (click)="cancel()">ANNULER</button>
    <button mat-button (click)="validate()">VALIDER</button>
  </mat-card-actions>
</mat-card>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { Field_objectFormComponent } from './Field_object.component';
@NgModule({
  declarations: [Field_objectFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, DirectiveModule, MatCheckboxModule],
  exports: [
    Field_objectFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatCheckboxModule
  ]
})
export class Field_objectFormModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { PageLayoutModule } from 'src/@fury/shared/page-layout/page-layout.module';
import { ConfirmationDialogModule } from 'src/app/app-specific/confirmation/confirmation.module';
import { OpenAiPredictPageFormComponent } from './OpenAiPredict.component';
import { OpenAiPredictFormModule } from '../../../ui/form/OpenAiPredict/OpenAiPredict.module';

@NgModule({
  declarations: [OpenAiPredictPageFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FuryCardModule,
    FurySharedModule,
    PageLayoutModule,
    ConfirmationDialogModule,
    OpenAiPredictFormModule
  ],

  exports: [
    OpenAiPredictPageFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    OpenAiPredictFormModule,
    FurySharedModule,
    FuryCardModule
  ]
})
export class OpenAiPredictPageFormComponentModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['answerFormat']">
    <mat-form-field appearance="fill">
      <mat-label>answerFormat</mat-label>

      <mat-select placeholder="Select" formControlName="answerFormat">
        <mat-option value="text">text</mat-option>

        <mat-option value="dataset">dataset</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tags']">
    <app-string-list label="tags" [formArr]="formGroup['_tags']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['datasets']">
    <app-string-list label="datasets" [formArr]="formGroup['_datasets']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['searchType']">
    <mat-form-field appearance="fill">
      <mat-label>searchType</mat-label>

      <mat-select placeholder="Select" formControlName="searchType">
        <mat-option value="query_only">query_only</mat-option>

        <mat-option value="conversation">conversation</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['keyContext']">
    <mat-form-field appearance="fill">
      <mat-label>keyContext</mat-label>
      <input matInput placeholder="" formControlName="keyContext" />
    </mat-form-field>
  </div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-tagtype',
  templateUrl: './TagType.component.html',
  styleUrls: ['./TagType.component.scss'],
  providers: [UIComponentState]
})
export class TagTypeUiListComponent extends UiList<interfaces.ITagType> implements AfterContentInit, OnInit {
  @Output() editRow = new EventEmitter<interfaces.ITagType>();

  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      order: 'number',
      color: 'string',
      isMandatory: 'boolean',
      isDataQualifier: 'boolean',
      isReadOnly: 'boolean',
      import: 'boolean'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'order', 'color', 'isMandatory'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }

  public startEdit($event: PointerEvent, row: any): void {
    $event.stopImmediatePropagation();
  }

  public sendit(row: interfaces.ITagType, event): void {
    row.order = event;
    this.editRow.emit(row);
  }
}

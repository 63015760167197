<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="urlBase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>urlBase</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.urlBase }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="port">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>port</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.port }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="debug">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>debug</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.debug }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="licence_well-known">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>licence_well-known</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.licence_well - known }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="secretKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>secretKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.secretKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="publicAccess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>publicAccess</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.publicAccess | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="bindIp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>bindIp</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.bindIp }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="headers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>headers</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.headers | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="accessControlAllowOrigin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>accessControlAllowOrigin</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.accessControlAllowOrigin }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="confServiceUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>confServiceUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.confServiceUrl }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { CreativeSettingsRequestFormModule } from '../CreativeSettingsRequest/CreativeSettingsRequest.module';

import { UICreativeSettingsDrawerFormComponent } from './UICreativeSettingsDrawer.component';
@NgModule({
  declarations: [UICreativeSettingsDrawerFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    CreativeSettingsRequestFormModule
  ],
  exports: [
    UICreativeSettingsDrawerFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    CreativeSettingsRequestFormModule
  ]
})
export class UICreativeSettingsDrawerFormModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentDataAnalystPageUiListComponent } from 'src/app/generated/dataPages/list/DocumentDataAnalyst/DocumentDataAnalyst.component';
import { DocumentDataAnalystPageFormComponent } from 'src/app/generated/dataPages/form/DocumentDataAnalyst/DocumentDataAnalyst.component';
import { DocumentDataAnalystPageUiListModule } from 'src/app/generated/dataPages/list/DocumentDataAnalyst/DocumentDataAnalyst.module';
import { DocumentDataAnalystPageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentDataAnalyst/DocumentDataAnalyst.module';

export class DocumentDataAnalystRoutes {
  public static routes = [
    {
      path: 'DocumentDataAnalyst/list',
      component: DocumentDataAnalystPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentDataAnalyst/edit/:id',
      component: DocumentDataAnalystPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentDataAnalyst/new/:id',
      component: DocumentDataAnalystPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentDataAnalystPageUiListModule, DocumentDataAnalystPageFormComponentModule];
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { interfaces } from '@leandredev/adaptivai-common-lib';

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss']
})
export class TagSelectorComponent {
  constructor() {
    this.tags = [];
  }

  @Input() selectedTags: interfaces.ITag[];
  @Input() tags: interfaces.ITag[];
  @Output() tagsChange: EventEmitter<interfaces.ITag[]> = new EventEmitter<interfaces.ITag[]>();

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public changeLog = [];

  selected(event: MatAutocompleteSelectedEvent): void {
    this.initTags();
    const tagToAdd = event.option.value as interfaces.ITag;
    if (this.selectedTags.find((current) => current._id === tagToAdd._id) == null) {
      this.selectedTags.push(tagToAdd);
    }
  }
  add(): void {
    this.tagsChange.emit(this.selectedTags);
  }
  remove(tag: interfaces.ITag): void {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  private initTags(): void {
    if (this.selectedTags === null) {
      this.selectedTags = [];
    }
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-_view_params',
  templateUrl: './_view_params.component.html',
  styleUrls: ['./_view_params.component.scss'],
  providers: [UIComponentState]
})
export class _view_paramsUiListComponent extends UiList<interfaces.I_view_params> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      description: 'string',
      output: 'modelid',
      model: 'modelid',
      pipeline: 'object',
      params: 'subdoc',
      castParams: 'subdoc'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'description', 'output', 'model'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentChunkSearchPageUiListComponent } from 'src/app/generated/dataPages/list/DocumentChunkSearch/DocumentChunkSearch.component';
import { DocumentChunkSearchPageFormComponent } from 'src/app/generated/dataPages/form/DocumentChunkSearch/DocumentChunkSearch.component';
import { DocumentChunkSearchPageUiListModule } from 'src/app/generated/dataPages/list/DocumentChunkSearch/DocumentChunkSearch.module';
import { DocumentChunkSearchPageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentChunkSearch/DocumentChunkSearch.module';

export class DocumentChunkSearchRoutes {
  public static routes = [
    {
      path: 'DocumentChunkSearch/list',
      component: DocumentChunkSearchPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentChunkSearch/edit/:id',
      component: DocumentChunkSearchPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentChunkSearch/new/:id',
      component: DocumentChunkSearchPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentChunkSearchPageUiListModule, DocumentChunkSearchPageFormComponentModule];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserClientNewComponent } from './user-client-new/user-client-new.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { PageLayoutModule } from 'src/@fury/shared/page-layout/page-layout.module';
import { MatChipsModule } from '@angular/material/chips';
import { UserClientGroupFormAutoCompleteModule } from 'src/app/generated/ui/chipList/UserClientGroup/UserClientGroup.module';
import { UserClientInvitationFormModule } from 'src/app/generated/ui/form/UserClientInvitation/UserClientInvitation.module';

@NgModule({
  declarations: [UserClientNewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    FuryCardModule,
    FurySharedModule,
    PageLayoutModule,
    UserClientGroupFormAutoCompleteModule,
    UserClientInvitationFormModule
  ],
  exports: [
    UserClientNewComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    FurySharedModule,
    FuryCardModule,
    UserClientGroupFormAutoCompleteModule,
    UserClientInvitationFormModule
  ]
})
export class UserClientModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { AssistantPageUiListComponent } from 'src/app/generated/dataPages/list/Assistant/Assistant.component';
import { AssistantPageFormComponent } from 'src/app/generated/dataPages/form/Assistant/Assistant.component';
import { AssistantPageUiListModule } from 'src/app/generated/dataPages/list/Assistant/Assistant.module';
import { AssistantPageFormComponentModule } from 'src/app/generated/dataPages/form/Assistant/Assistant.module';

export class AssistantRoutes {
  public static routes = [
    {
      path: 'Assistant/list',
      component: AssistantPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Assistant/edit/:id',
      component: AssistantPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Assistant/new/:id',
      component: AssistantPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [AssistantPageUiListModule, AssistantPageFormComponentModule];
}

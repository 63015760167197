/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';

import { UserClientPageUiListComponent } from 'src/app/generated/dataPages/list/UserClient/UserClient.component';
import { UserClientPageFormComponent } from 'src/app/generated/dataPages/form/UserClient/UserClient.component';
import { UserClientPageUiListModule } from 'src/app/generated/dataPages/list/UserClient/UserClient.module';
import { UserClientPageFormComponentModule } from 'src/app/generated/dataPages/form/UserClient/UserClient.module';
import { UserClientNewComponent } from 'src/app/modules/user-client/user-client-new/user-client-new.component';

export class UserClientRoutes {
  public static routes = [
    {
      path: 'UserClient/list',
      component: UserClientPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClient/edit/:id',
      component: UserClientPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClient/new/:id',
      component: UserClientPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClient/newIvitation/:id',
      component: UserClientNewComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserClientPageUiListModule, UserClientPageFormComponentModule];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatExpansionModule } from '@angular/material/expansion';

import { UserConfFormModule } from '../UserConf/UserConf.module';

import { UserAdminFormComponent } from './UserAdmin.component';
@NgModule({
  declarations: [UserAdminFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatCheckboxModule,

    MatExpansionModule,

    UserConfFormModule
  ],
  exports: [
    UserAdminFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatCheckboxModule,

    MatExpansionModule,

    UserConfFormModule
  ]
})
export class UserAdminFormModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { protoschemaPageUiListComponent } from 'src/app/generated/dataPages/list/protoschema/protoschema.component';
import { protoschemaPageFormComponent } from 'src/app/generated/dataPages/form/protoschema/protoschema.component';
import { protoschemaPageUiListModule } from 'src/app/generated/dataPages/list/protoschema/protoschema.module';
import { protoschemaPageFormComponentModule } from 'src/app/generated/dataPages/form/protoschema/protoschema.module';

export class protoschemaRoutes {
  public static routes = [
    {
      path: 'protoschema/list',
      component: protoschemaPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'protoschema/edit/:id',
      component: protoschemaPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'protoschema/new/:id',
      component: protoschemaPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [protoschemaPageUiListModule, protoschemaPageFormComponentModule];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@leandredev/common-ng16/components';

import { DayjsModule } from '@leandredev/common-ng16';

import { fieldUIListSubDocComponent } from './field.component';

import { Field_modelidFormModule } from '../../form/Field_modelid/Field_modelid.module';

import { Field_booleanFormModule } from '../../form/Field_boolean/Field_boolean.module';

import { Field_dateFormModule } from '../../form/Field_date/Field_date.module';

import { Field_numberFormModule } from '../../form/Field_number/Field_number.module';

import { Field_htmltexteFormModule } from '../../form/Field_htmltexte/Field_htmltexte.module';

import { Field_objectidFormModule } from '../../form/Field_objectid/Field_objectid.module';

import { Field_objectFormModule } from '../../form/Field_object/Field_object.module';

import { Field_texteFormModule } from '../../form/Field_texte/Field_texte.module';

import { Field_subdocFormModule } from '../../form/Field_subdoc/Field_subdoc.module';

import { Field_stringFormModule } from '../../form/Field_string/Field_string.module';

import { Field_passwordFormModule } from '../../form/Field_password/Field_password.module';

@NgModule({
  declarations: [fieldUIListSubDocComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    DayjsModule,
    SharedModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,

    Field_modelidFormModule,

    Field_booleanFormModule,

    Field_dateFormModule,

    Field_numberFormModule,

    Field_htmltexteFormModule,

    Field_objectidFormModule,

    Field_objectFormModule,

    Field_texteFormModule,

    Field_subdocFormModule,

    Field_stringFormModule,

    Field_passwordFormModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,
    DayjsModule,
    SharedModule,

    Field_modelidFormModule,

    Field_booleanFormModule,

    Field_dateFormModule,

    Field_numberFormModule,

    Field_htmltexteFormModule,

    Field_objectidFormModule,

    Field_objectFormModule,

    Field_texteFormModule,

    Field_subdocFormModule,

    Field_stringFormModule,

    Field_passwordFormModule,

    fieldUIListSubDocComponent
  ]
})
export class fieldListSubDocModule {}

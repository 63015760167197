<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['xField']">
    <mat-form-field appearance="fill">
      <mat-label>xField</mat-label>
      <input matInput placeholder="" formControlName="xField" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['yField']">
    <mat-form-field appearance="fill">
      <mat-label>yField</mat-label>
      <input matInput placeholder="" formControlName="yField" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['legendField']">
    <mat-form-field appearance="fill">
      <mat-label>legendField</mat-label>
      <input matInput placeholder="" formControlName="legendField" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['legend']">
    <app-string-list label="legend" [formArr]="formGroup['_legend']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['chartType']">
    <mat-form-field appearance="fill">
      <mat-label>chartType</mat-label>

      <mat-select placeholder="Select" formControlName="chartType">
        <mat-option value="bar">bar</mat-option>

        <mat-option value="line">line</mat-option>

        <mat-option value="pie">pie</mat-option>

        <mat-option value="line-stack">line-stack</mat-option>

        <mat-option value="bar-stack">bar-stack</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

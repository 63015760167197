<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="assistantName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>assistantName</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.assistantName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>creationDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.creationDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="flowView">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>flowView</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.flowView | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>state</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.state }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="lmActionHistory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>lmActionHistory</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <ng-container *ngFor="let val of row.lmActionHistory">
              <app-ui-ref #obj colName="LlmAction" [idRef]="val">
                {{ obj.data?.name || obj.data?.title || val }}
              </app-ui-ref>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="UserAdmin" [idRef]="row.userId">
              {{ obj.data?.name || obj.data?.title || row.userId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>updatedDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.updatedDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userInput">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userInput</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.userInput | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="drawerComponent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>drawerComponent</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.drawerComponent | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userLang">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userLang</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.userLang }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="extraDrawerComponent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>extraDrawerComponent</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.extraDrawerComponent | json }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['key']">
    <mat-form-field appearance="fill">
      <mat-label>key</mat-label>
      <input matInput placeholder="" formControlName="key" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['value']">
    <mat-form-field appearance="fill">
      <mat-label>value</mat-label>
      <input matInput placeholder="" formControlName="value" />
    </mat-form-field>
  </div>
</div>

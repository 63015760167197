<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userType']">
    <mat-form-field appearance="fill">
      <mat-label>userType</mat-label>
      <input matInput placeholder="" formControlName="userType" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>
</div>

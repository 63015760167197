import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { FlowParamsListSubDocModule } from '../../listSubdoc/FlowParams/FlowParams.module';

import { SharedModule } from '@leandredev/common-ng16/components';

import { FlowStepAnalystFormComponent } from './FlowStepAnalyst.component';
@NgModule({
  declarations: [FlowStepAnalystFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    FlowParamsListSubDocModule,

    SharedModule
  ],
  exports: [
    FlowStepAnalystFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    FlowParamsListSubDocModule,

    SharedModule
  ]
})
export class FlowStepAnalystFormModule {}

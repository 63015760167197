<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="originalname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>originalname</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.originalname }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="encoding">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>encoding</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.encoding }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="mimetype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>mimetype</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.mimetype }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>size</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.size }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>path</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.path }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>createdAt</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.createdAt | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>updatedAt</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.updatedAt | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="downloadUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>downloadUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.downloadUrl }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="accessKeys">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>accessKeys</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.accessKeys }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

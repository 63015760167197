<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['services']">
    <app-service_access-form-auto-complete [formArr]="formGroup['_services']" placeholder="services"> </app-service_access-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['appName']">
    <mat-form-field appearance="fill">
      <mat-label>Titre</mat-label>
      <input matInput placeholder="" formControlName="appName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['apiUrl']">
    <mat-form-field appearance="fill">
      <mat-label>apiUrl</mat-label>
      <input matInput placeholder="" formControlName="apiUrl" />
    </mat-form-field>
  </div>
</div>

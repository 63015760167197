import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { UserClientFormAutoCompleteModule } from '../../chipList/UserClient/UserClient.module';

import { UserActionStatsFormComponent } from './UserActionStats.component';
@NgModule({
  declarations: [UserActionStatsFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    UserClientFormAutoCompleteModule
  ],
  exports: [
    UserActionStatsFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    UserClientFormAutoCompleteModule
  ]
})
export class UserActionStatsFormModule {}

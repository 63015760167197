import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatExpansionModule } from '@angular/material/expansion';

import { name_valueListSubDocModule } from '../../listSubdoc/name_value/name_value.module';

import { url_roleFormModule } from '../url_role/url_role.module';

import { SharedModule } from '@leandredev/common-ng16/components';

import { serviceFormComponent } from './service.component';
@NgModule({
  declarations: [serviceFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatCheckboxModule,

    MatExpansionModule,

    name_valueListSubDocModule,

    url_roleFormModule,

    SharedModule
  ],
  exports: [
    serviceFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatCheckboxModule,

    MatExpansionModule,

    name_valueListSubDocModule,

    url_roleFormModule,

    SharedModule
  ]
})
export class serviceFormModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { SharedModule } from '@leandredev/common-ng16/components';

import { Field_stringFormComponent } from './Field_string.component';
@NgModule({
  declarations: [Field_stringFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatCheckboxModule,

    SharedModule
  ],
  exports: [
    Field_stringFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatCheckboxModule,

    SharedModule
  ]
})
export class Field_stringFormModule {}

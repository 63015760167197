<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <input matInput placeholder="" formControlName="message" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmActionId']">
    <app-llmaction-form-auto-complete [formCtrl]="formGroup['_llmActionId']" placeholder="llmActionId"> </app-llmaction-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceIds']">
    <app-string-list label="serviceIds" [formArr]="formGroup['_serviceIds']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantSessionId']">
    <app-assistantsession-form-auto-complete [formCtrl]="formGroup['_assistantSessionId']" placeholder="assistantSessionId">
    </app-assistantsession-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['flowViews']">
    <app-string-list label="flowViews" [formArr]="formGroup['_flowViews']"></app-string-list>
  </div>
</div>

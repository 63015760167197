import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { AnalystSettingsRequestFormModule } from '../AnalystSettingsRequest/AnalystSettingsRequest.module';

import { UIAnalystSettingsDrawerFormComponent } from './UIAnalystSettingsDrawer.component';
@NgModule({
  declarations: [UIAnalystSettingsDrawerFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    AnalystSettingsRequestFormModule
  ],
  exports: [
    UIAnalystSettingsDrawerFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    AnalystSettingsRequestFormModule
  ]
})
export class UIAnalystSettingsDrawerFormModule {}

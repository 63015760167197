import { Component, Inject } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';

import { interfaces } from '@leandredev/adaptivai-common-lib';
import { DataService } from 'src/app/dataService/dataService.service';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, filter, Observable } from 'rxjs';
@Component({
  selector: 'app-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss']
})
export class DocumentSelectorComponent {
  public form: FormGroup;
  public allTags: interfaces.ITag[];
  get tags(): FormArray {
    return this.form.controls['tags'] as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public documentRequest: { allTags: interfaces.ITag[] },
    private dialogRef: MatDialogRef<DocumentSelectorComponent>,
    private formBuild: FormBuilder,
    private dataService: DataService,
    private http: HttpClient
  ) {
    this.allTags = documentRequest.allTags;
    this.form = new FormGroup({
      url: new FormControl('', [Validators.required, Validators.nullValidator, Validators.pattern(this.reg)]),
      file: new FormControl(null),
      tags: this.formBuild.array([])
    });
  }
  progress: { value: number; mode: string };
  private uploadRequest: interfaces.IUploadDocumentRequest = { file: null, url: '' };
  public uploadSelection = '';
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  public updateTagForRequest(data: interfaces.ITag[]): void {
    this.uploadRequest.tags = data.map((t) => t._id.toString());
  }

  public validate(): void {
    switch (this.uploadSelection) {
      case 'url':
        this.uploadRequest.url = this.form.get('url').value as string;
        this.dialogRef.close(this.uploadRequest);
        break;
      case 'fileSelector':
        void this.sendFileToServarAndretournRequestWihtFileUrl().subscribe((result) => {
          this.uploadRequest.file = result;
          this.dialogRef.close(this.uploadRequest);
        });
        break;
    }
  }

  private sendFileToServarAndretournRequestWihtFileUrl(): Observable<interfaces.IFile> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const url = `${this.dataService.fileService.urlCollection as string}`.concat('/upload');

    const req = new HttpRequest('POST', url, this.formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req).pipe(
      filter((event: HttpResponse<interfaces.IFile[]>) => event.type === HttpEventType.Response),
      map((event) => {
        return event.body as interfaces.IFile[];
      }),
      map((files) => files['response'][0]),
      map((data: interfaces.IFile) => {
        return data;
      })
    );
  }

  private formData: FormData;
  public onFileSelected(event: Event): void {
    if (!(event.target instanceof HTMLInputElement)) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const file: File = event.target.files[0];

    this.formData = new FormData();
    this.formData.append('file', file, file.name);
  }
  public cancel(): void {
    this.dialogRef.close(null);
  }
  public setUploadSelection(event: MatRadioChange): void {
    this.uploadSelection = event.value as string;
  }
}

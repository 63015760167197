/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, Self, Inject } from '@angular/core';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { FormControlBase } from '@leandredev/common-ng16/form';

import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiForm } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-form-txttochunks',
  templateUrl: './TxtToChunks.component.html',
  styleUrls: ['./TxtToChunks.component.scss'],
  providers: [UIComponentState]
})
export class TxtToChunksFormComponent extends UiForm<form.TxtToChunks> implements OnInit {
  @Input() override id = '';
  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['TxtToChunksOutput'] = 'TxtToChunksOutput';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
    Object.assign(this.state, this.uIComponentState.state);
    Object.assign(this.uIComponentState.state, this.state);
    this.state = this.uIComponentState.state;
  }

  public fieldClassHumanName: { [key: string]: string } = {};

  public classChange(value: string, control: FormControlBase): void {
    let oldVal: any;
    oldVal = control.value;
    if (!oldVal) {
      oldVal = {};
    }
    oldVal._class = value;
    control.patchValue(oldVal);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { CopywriterStyleRecordFormAutoCompleteModule } from '../../chipList/CopywriterStyleRecord/CopywriterStyleRecord.module';

import { TagFormAutoCompleteModule } from '../../chipList/Tag/Tag.module';

import { AvailableAiModelsFormAutoCompleteModule } from '../../chipList/AvailableAiModels/AvailableAiModels.module';

import { UserConfFormComponent } from './UserConf.component';
@NgModule({
  declarations: [UserConfFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    CopywriterStyleRecordFormAutoCompleteModule,

    TagFormAutoCompleteModule,

    AvailableAiModelsFormAutoCompleteModule
  ],
  exports: [
    UserConfFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    CopywriterStyleRecordFormAutoCompleteModule,

    TagFormAutoCompleteModule,

    AvailableAiModelsFormAutoCompleteModule
  ]
})
export class UserConfFormModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['blobs']">
    <mat-form-field appearance="fill">
      <mat-label>blobs</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['paths']">
    <app-string-list label="paths" [formArr]="formGroup['_paths']"></app-string-list>
  </div>
</div>

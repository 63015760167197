/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageErrorPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageError/UserMessageError.component';
import { UserMessageErrorPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageError/UserMessageError.component';
import { UserMessageErrorPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageError/UserMessageError.module';
import { UserMessageErrorPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageError/UserMessageError.module';

export class UserMessageErrorRoutes {
  public static routes = [
    {
      path: 'UserMessageError/list',
      component: UserMessageErrorPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageError/edit/:id',
      component: UserMessageErrorPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageError/new/:id',
      component: UserMessageErrorPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageErrorPageUiListModule, UserMessageErrorPageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['type']">
    <app-tagtype-form-auto-complete [formCtrl]="formGroup['_type']" placeholder="type"> </app-tagtype-form-auto-complete>
  </div>
</div>

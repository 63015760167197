<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['documentalistSettingsId']">
    <mat-form-field appearance="fill">
      <mat-label>documentalistSettingsId</mat-label>
      <input matInput placeholder="" formControlName="documentalistSettingsId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tags']">
    <app-string-list label="tags" [formArr]="formGroup['_tags']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['answerFormat']">
    <mat-form-field appearance="fill">
      <mat-label>answerFormat</mat-label>
      <input matInput placeholder="" formControlName="answerFormat" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['docQuery']">
    <mat-form-field appearance="fill">
      <mat-label>docQuery</mat-label>
      <input matInput placeholder="" formControlName="docQuery" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['documents']">
    <app-string-list label="documents" [formArr]="formGroup['_documents']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['searchType']">
    <mat-form-field appearance="fill">
      <mat-label>searchType</mat-label>

      <mat-select placeholder="Select" formControlName="searchType">
        <mat-option value="query_only">query_only</mat-option>

        <mat-option value="query_specific">query_specific</mat-option>

        <mat-option value="query_and_question">query_and_question</mat-option>

        <mat-option value="full_document">full_document</mat-option>

        <mat-option value="chat_only">chat_only</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['framework']">
    <mat-form-field appearance="fill">
      <mat-label>framework</mat-label>
      <input matInput placeholder="" formControlName="framework" />
    </mat-form-field>
  </div>
</div>

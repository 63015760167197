<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['firstName']">
    <mat-form-field appearance="fill">
      <mat-label>firstName</mat-label>
      <input matInput placeholder="" formControlName="firstName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lastName']">
    <mat-form-field appearance="fill">
      <mat-label>lastName</mat-label>
      <input matInput placeholder="" formControlName="lastName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['email']">
    <mat-form-field appearance="fill">
      <mat-label>email</mat-label>
      <input matInput placeholder="" formControlName="email" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['password']">
    <mat-form-field appearance="fill">
      <mat-label>password</mat-label>
      <input matInput type="password" placeholder="" [appFormPassword] [passwordFormControl]="formGroup._password" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPasswordKey']">
    <mat-form-field appearance="fill">
      <mat-label>resetPasswordKey</mat-label>
      <input matInput placeholder="" formControlName="resetPasswordKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPasswordEndDate']">
    <mat-form-field appearance="fill">
      <mat-label>resetPasswordEndDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myresetPasswordEndDate" formControlName="resetPasswordEndDate" />
      <mat-datepicker-toggle matSuffix [for]="myresetPasswordEndDate"></mat-datepicker-toggle>
      <mat-datepicker #myresetPasswordEndDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userConf']">
    <mat-expansion-panel
      [expanded]="state['userConf']?._expanded"
      (closed)="propState('userConf_expanded', null)"
      (opened)="propState('userConf_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> userConf {{ fieldClassHumanName[formGroup._userConf?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_userConf']?.__class?.value">
          <app-ui-form-userconf id="userConf" *ngSwitchCase="'UserConf'" [formGroup]="formGroup['_userConf']"></app-ui-form-userconf>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['superAdmin']"><mat-checkbox formControlName="superAdmin">superAdmin</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['apiKey']">
    <mat-form-field appearance="fill">
      <mat-label>apiKey</mat-label>
      <input matInput placeholder="" formControlName="apiKey" />
    </mat-form-field>
  </div>
</div>

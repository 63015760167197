import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatExpansionModule } from '@angular/material/expansion';

import { ErrorObjListSubDocModule } from '../../listSubdoc/ErrorObj/ErrorObj.module';

import { ExcelToChunksOutputFormModule } from '../ExcelToChunksOutput/ExcelToChunksOutput.module';

import { UserAdminFormAutoCompleteModule } from '../../chipList/UserAdmin/UserAdmin.module';

import { AssistantSessionFormAutoCompleteModule } from '../../chipList/AssistantSession/AssistantSession.module';

import { ExcelToChunksFormComponent } from './ExcelToChunks.component';
@NgModule({
  declarations: [ExcelToChunksFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatCheckboxModule,

    MatExpansionModule,

    ErrorObjListSubDocModule,

    ExcelToChunksOutputFormModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule
  ],
  exports: [
    ExcelToChunksFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatCheckboxModule,

    MatExpansionModule,

    ErrorObjListSubDocModule,

    ExcelToChunksOutputFormModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule
  ]
})
export class ExcelToChunksFormModule {}

<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs current="UserClient"></fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content>
    <fury-list name="UserClient" [columns]="columns" (filterChange)="onFilterChange($event)" (colsFilterChange)="colsFilterChangeListener($event)">
      <div class="actions">
        <button class="create" type="button" mat-mini-fab color="primary" (click)="addWithMail()">
          <mat-icon aria-label="add icon">group_add</mat-icon>
        </button>
        <span> </span>
        <button class="create" (click)="add()" type="button" mat-mini-fab color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <app-ui-list-userclient
        class="table"
        id="uiList"
        [sortDirection]="sortDirection"
        [sortColumn]="sortColumn"
        [displayedColumns]="displayedColumns"
        [data]="data"
        (rowClicked)="rowClicked($event)"
        [isLoadingResults]="isLoadingResults"
        (sortChange)="sortChange($event)"
      ></app-ui-list-userclient>
      <mat-paginator
        (page)="paginatorChange($event)"
        [pageSizeOptions]="pageSizeOptions"
        [length]="dataLength"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        showFirstLastButtons
      ></mat-paginator>
    </fury-list>
  </fury-page-layout-content>
</fury-page-layout>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { PageLayoutModule } from 'src/@fury/shared/page-layout/page-layout.module';
import { ConfirmationDialogModule } from 'src/app/app-specific/confirmation/confirmation.module';
import { application_configurationPageFormComponent } from './application_configuration.component';
import { application_configurationFormModule } from '../../../ui/form/application_configuration/application_configuration.module';

@NgModule({
  declarations: [application_configurationPageFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FuryCardModule,
    FurySharedModule,
    PageLayoutModule,
    ConfirmationDialogModule,
    application_configurationFormModule
  ],

  exports: [
    application_configurationPageFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    application_configurationFormModule,
    FurySharedModule,
    FuryCardModule
  ]
})
export class application_configurationPageFormComponentModule {}

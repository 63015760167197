<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['startDate']">
    <mat-form-field appearance="fill">
      <mat-label>startDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mystartDate" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="mystartDate"></mat-datepicker-toggle>
      <mat-datepicker #mystartDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceId']">
    <app-string-list label="serviceId" [formArr]="formGroup['_serviceId']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endDate']">
    <mat-form-field appearance="fill">
      <mat-label>endDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myendDate" formControlName="endDate" />
      <mat-datepicker-toggle matSuffix [for]="myendDate"></mat-datepicker-toggle>
      <mat-datepicker #myendDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['hasFailed']"><mat-checkbox formControlName="hasFailed">hasFailed</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['errorsList']">
    <app-ui-list-sub-doc-errorobj id="errorsList" label="errorsList" [inputData]="formGroup['_errorsList']"> </app-ui-list-sub-doc-errorobj>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceCreator']">
    <mat-form-field appearance="fill">
      <mat-label>serviceCreator</mat-label>
      <input matInput placeholder="" formControlName="serviceCreator" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-userclientgroup-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-userclientgroup-form-auto-complete>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentDataDiscoveryPageUiListComponent } from 'src/app/generated/dataPages/list/DocumentDataDiscovery/DocumentDataDiscovery.component';
import { DocumentDataDiscoveryPageFormComponent } from 'src/app/generated/dataPages/form/DocumentDataDiscovery/DocumentDataDiscovery.component';
import { DocumentDataDiscoveryPageUiListModule } from 'src/app/generated/dataPages/list/DocumentDataDiscovery/DocumentDataDiscovery.module';
import { DocumentDataDiscoveryPageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentDataDiscovery/DocumentDataDiscovery.module';

export class DocumentDataDiscoveryRoutes {
  public static routes = [
    {
      path: 'DocumentDataDiscovery/list',
      component: DocumentDataDiscoveryPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentDataDiscovery/edit/:id',
      component: DocumentDataDiscoveryPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentDataDiscovery/new/:id',
      component: DocumentDataDiscoveryPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentDataDiscoveryPageUiListModule, DocumentDataDiscoveryPageFormComponentModule];
}

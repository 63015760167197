<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs current="UserMessage"></fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content>
    <fury-list name="UserMessage" [columns]="columns" (filterChange)="onFilterChange($event)" (colsFilterChange)="colsFilterChangeListener($event)">
      <div class="actions">
        <button mat-fab color="primary" [matMenuTriggerFor]="addObj">
          <mat-icon aria-label="add icon">add</mat-icon>
        </button>
        <mat-menu #addObj="matMenu">
          <button mat-menu-item (click)="add('UserMessageError')">UserMessageError</button>

          <button mat-menu-item (click)="add('UserMessageProgress')">UserMessageProgress</button>

          <button mat-menu-item (click)="add('UserMessageEnd')">UserMessageEnd</button>

          <button mat-menu-item (click)="add('UserMessageInfo')">UserMessageInfo</button>

          <button mat-menu-item (click)="add('DocumentStatusMessage')">DocumentStatusMessage</button>

          <button mat-menu-item (click)="add('UserMessageTextStream')">UserMessageTextStream</button>

          <button mat-menu-item (click)="add('UserMessageAssistant')">UserMessageAssistant</button>

          <button mat-menu-item (click)="add('UserMessageAssistantDeletion')">UserMessageAssistantDeletion</button>

          <button mat-menu-item (click)="add('UserMessageRefreshToken')">UserMessageRefreshToken</button>
        </mat-menu>
      </div>
      <app-ui-list-usermessage
        class="table"
        id="uiList"
        [sortDirection]="sortDirection"
        [sortColumn]="sortColumn"
        [displayedColumns]="displayedColumns"
        [data]="data"
        (rowClicked)="rowClicked($event)"
        [isLoadingResults]="isLoadingResults"
        (sortChange)="sortChange($event)"
      ></app-ui-list-usermessage>
      <mat-paginator
        (page)="paginatorChange($event)"
        [pageSizeOptions]="pageSizeOptions"
        [length]="dataLength"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        showFirstLastButtons
      ></mat-paginator>
    </fury-list>
  </fury-page-layout-content>
</fury-page-layout>

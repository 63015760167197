import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { DocumentDataAnalystOutputFormModule } from '../DocumentDataAnalystOutput/DocumentDataAnalystOutput.module';

import { UIAnalystDataMessageFormComponent } from './UIAnalystDataMessage.component';
@NgModule({
  declarations: [UIAnalystDataMessageFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    DocumentDataAnalystOutputFormModule
  ],
  exports: [
    UIAnalystDataMessageFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    DocumentDataAnalystOutputFormModule
  ]
})
export class UIAnalystDataMessageFormModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['route']">
    <mat-form-field appearance="fill">
      <mat-label>route</mat-label>
      <input matInput placeholder="" formControlName="route" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['role']">
    <app-string-list label="role" [formArr]="formGroup['_role']"></app-string-list>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserActionStatsPageUiListComponent } from 'src/app/generated/dataPages/list/UserActionStats/UserActionStats.component';
import { UserActionStatsPageFormComponent } from 'src/app/generated/dataPages/form/UserActionStats/UserActionStats.component';
import { UserActionStatsPageUiListModule } from 'src/app/generated/dataPages/list/UserActionStats/UserActionStats.module';
import { UserActionStatsPageFormComponentModule } from 'src/app/generated/dataPages/form/UserActionStats/UserActionStats.module';

export class UserActionStatsRoutes {
  public static routes = [
    {
      path: 'UserActionStats/list',
      component: UserActionStatsPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserActionStats/edit/:id',
      component: UserActionStatsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserActionStats/new/:id',
      component: UserActionStatsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserActionStatsPageUiListModule, UserActionStatsPageFormComponentModule];
}

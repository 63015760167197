import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ListColumn } from './list-column.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'fury-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent implements AfterViewInit {
  @Input() name: string;
  @Input() columns: ListColumn[];

  @ViewChild('filterUi') filter: ElementRef;
  @Output() filterChange = new EventEmitter<string>();
  @Output() colsFilterChange = new EventEmitter<string[]>();

  @Input() hideHeader: boolean;

  @Input() showBottomBorder: boolean = false;
  displayedColumns: string[];

  constructor() {}

  ngAfterViewInit() {
    if (!this.hideHeader) {
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(distinctUntilChanged(), debounceTime(150))
        .subscribe(() => {
          this.filterChange.emit(this.filter.nativeElement.value);
        });
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.displayedColumns = this.columns.filter((c) => c.visible === true).map((c) => c.colId);
    // never hide all cols
    if (this.displayedColumns.length > 0) {
      this.colsFilterChange.emit(this.displayedColumns);
    }
  }
}

<h1 mat-dialog-title>Demande de confirmation</h1>
<div mat-dialog-content>
  <p>You have not saved your file changes.<br />If you quit now your changes will be lost.</p>
  <p>Are you sure you want to discard changes ?</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="ignore()">Confirm</button>
  <button mat-raised-button (click)="resume()">Cancel</button>
</div>

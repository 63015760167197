<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['output']">
    <mat-expansion-panel
      [expanded]="state['output']?._expanded"
      (closed)="propState('output_expanded', null)"
      (opened)="propState('output_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> output {{ fieldClassHumanName[formGroup._output?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-form-field>
          <mat-label>Selectionner le type</mat-label>
          <mat-select (selectionChange)="classChange($event.value, formGroup['_output'])" [value]="formGroup['_output']?.__class?.value">
            <mat-option value="OpenAiPredictOutput">OpenAiPredictOutput</mat-option>

            <mat-option value="OpenAiChatOutput">OpenAiChatOutput</mat-option>

            <mat-option value="DocumentalisteSearchOutput">DocumentalisteSearchOutput</mat-option>

            <mat-option value="DocumentChunkSearchOutput">DocumentChunkSearchOutput</mat-option>

            <mat-option value="CopywriterOutput">CopywriterOutput</mat-option>

            <mat-option value="DeeplChatOutput">DeeplChatOutput</mat-option>

            <mat-option value="AdobeePdfToChuncksOutput">AdobeePdfToChuncksOutput</mat-option>

            <mat-option value="ExcelToChunksOutput">ExcelToChunksOutput</mat-option>

            <mat-option value="VisionToChuncksOutput">VisionToChuncksOutput</mat-option>

            <mat-option value="VisionImageDescriptionOutput">VisionImageDescriptionOutput</mat-option>

            <mat-option value="TxtToChunksOutput">TxtToChunksOutput</mat-option>

            <mat-option value="AdobeCreatePdfOutput">AdobeCreatePdfOutput</mat-option>

            <mat-option value="PdfToImagesOutput">PdfToImagesOutput</mat-option>

            <mat-option value="FileDataToChunksOutput">FileDataToChunksOutput</mat-option>

            <mat-option value="FileImageDataToChunksOutput">FileImageDataToChunksOutput</mat-option>

            <mat-option value="CreatePresentationOutput">CreatePresentationOutput</mat-option>

            <mat-option value="LangChainFileToChunckOutput">LangChainFileToChunckOutput</mat-option>

            <mat-option value="CreativeOutput">CreativeOutput</mat-option>

            <mat-option value="DocumentDataDiscoveryOutput">DocumentDataDiscoveryOutput</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container [ngSwitch]="formGroup['_output']?.__class?.value">
          <app-ui-form-openaipredictoutput
            id="output"
            *ngSwitchCase="'OpenAiPredictOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-openaipredictoutput>

          <app-ui-form-openaichatoutput
            id="output"
            *ngSwitchCase="'OpenAiChatOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-openaichatoutput>

          <app-ui-form-documentalistesearchoutput
            id="output"
            *ngSwitchCase="'DocumentalisteSearchOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-documentalistesearchoutput>

          <app-ui-form-documentchunksearchoutput
            id="output"
            *ngSwitchCase="'DocumentChunkSearchOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-documentchunksearchoutput>

          <app-ui-form-copywriteroutput
            id="output"
            *ngSwitchCase="'CopywriterOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-copywriteroutput>

          <app-ui-form-deeplchatoutput
            id="output"
            *ngSwitchCase="'DeeplChatOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-deeplchatoutput>

          <app-ui-form-adobeepdftochuncksoutput
            id="output"
            *ngSwitchCase="'AdobeePdfToChuncksOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-adobeepdftochuncksoutput>

          <app-ui-form-exceltochunksoutput
            id="output"
            *ngSwitchCase="'ExcelToChunksOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-exceltochunksoutput>

          <app-ui-form-visiontochuncksoutput
            id="output"
            *ngSwitchCase="'VisionToChuncksOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-visiontochuncksoutput>

          <app-ui-form-visionimagedescriptionoutput
            id="output"
            *ngSwitchCase="'VisionImageDescriptionOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-visionimagedescriptionoutput>

          <app-ui-form-txttochunksoutput
            id="output"
            *ngSwitchCase="'TxtToChunksOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-txttochunksoutput>

          <app-ui-form-adobecreatepdfoutput
            id="output"
            *ngSwitchCase="'AdobeCreatePdfOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-adobecreatepdfoutput>

          <app-ui-form-pdftoimagesoutput
            id="output"
            *ngSwitchCase="'PdfToImagesOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-pdftoimagesoutput>

          <app-ui-form-filedatatochunksoutput
            id="output"
            *ngSwitchCase="'FileDataToChunksOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-filedatatochunksoutput>

          <app-ui-form-fileimagedatatochunksoutput
            id="output"
            *ngSwitchCase="'FileImageDataToChunksOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-fileimagedatatochunksoutput>

          <app-ui-form-createpresentationoutput
            id="output"
            *ngSwitchCase="'CreatePresentationOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-createpresentationoutput>

          <app-ui-form-langchainfiletochunckoutput
            id="output"
            *ngSwitchCase="'LangChainFileToChunckOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-langchainfiletochunckoutput>

          <app-ui-form-creativeoutput
            id="output"
            *ngSwitchCase="'CreativeOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-creativeoutput>

          <app-ui-form-documentdatadiscoveryoutput
            id="output"
            *ngSwitchCase="'DocumentDataDiscoveryOutput'"
            [formGroup]="$any(formGroup['_output'])"
          ></app-ui-form-documentdatadiscoveryoutput>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['startDate']">
    <mat-form-field appearance="fill">
      <mat-label>startDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mystartDate" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="mystartDate"></mat-datepicker-toggle>
      <mat-datepicker #mystartDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['state']">
    <mat-form-field appearance="fill">
      <mat-label>state</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceId']">
    <mat-form-field appearance="fill">
      <mat-label>serviceId</mat-label>
      <input matInput placeholder="" formControlName="serviceId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endDate']">
    <mat-form-field appearance="fill">
      <mat-label>endDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myendDate" formControlName="endDate" />
      <mat-datepicker-toggle matSuffix [for]="myendDate"></mat-datepicker-toggle>
      <mat-datepicker #myendDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['deleted']"><mat-checkbox formControlName="deleted">deleted</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['hasFailed']"><mat-checkbox formControlName="hasFailed">hasFailed</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['errorsList']">
    <app-ui-list-sub-doc-errorobj id="errorsList" label="errorsList" [inputData]="formGroup['_errorsList']"> </app-ui-list-sub-doc-errorobj>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceCreator']">
    <mat-form-field appearance="fill">
      <mat-label>serviceCreator</mat-label>
      <input matInput placeholder="" formControlName="serviceCreator" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantSessionId']">
    <app-assistantsession-form-auto-complete [formCtrl]="formGroup['_assistantSessionId']" placeholder="assistantSessionId">
    </app-assistantsession-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lang']">
    <mat-form-field appearance="fill">
      <mat-label>lang</mat-label>
      <input matInput placeholder="" formControlName="lang" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llm']">
    <mat-form-field appearance="fill">
      <mat-label>llm</mat-label>
      <input matInput placeholder="" formControlName="llm" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmModel']">
    <mat-form-field appearance="fill">
      <mat-label>llmModel</mat-label>
      <input matInput placeholder="" formControlName="llmModel" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endInteractionServiceId']">
    <mat-form-field appearance="fill">
      <mat-label>endInteractionServiceId</mat-label>
      <input matInput placeholder="" formControlName="endInteractionServiceId" />
    </mat-form-field>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['_$get']">
    <app-ui-list-sub-doc-route_role id="_$get" label="_$get" [inputData]="formGroup['__$get']"> </app-ui-list-sub-doc-route_role>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['_$post']">
    <app-ui-list-sub-doc-route_role id="_$post" label="_$post" [inputData]="formGroup['__$post']"> </app-ui-list-sub-doc-route_role>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['_$delete']">
    <app-ui-list-sub-doc-route_role id="_$delete" label="_$delete" [inputData]="formGroup['__$delete']"> </app-ui-list-sub-doc-route_role>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['_$put']">
    <app-ui-list-sub-doc-route_role id="_$put" label="_$put" [inputData]="formGroup['__$put']"> </app-ui-list-sub-doc-route_role>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['_$patch']">
    <app-ui-list-sub-doc-route_role id="_$patch" label="_$patch" [inputData]="formGroup['__$patch']"> </app-ui-list-sub-doc-route_role>
  </div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-_view',
  templateUrl: './_view.component.html',
  styleUrls: ['./_view.component.scss'],
  providers: [UIComponentState]
})
export class _viewUiListComponent extends UiList<interfaces.I_view> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = { _id: 'string', name: 'string', description: 'string', output: 'modelid', model: 'modelid', pipeline: 'object' };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'description', 'output', 'model'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

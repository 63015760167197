import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { DocumentalistSettingsRequestFormModule } from '../DocumentalistSettingsRequest/DocumentalistSettingsRequest.module';

import { UIDocumentalistSettingsFormComponent } from './UIDocumentalistSettings.component';
@NgModule({
  declarations: [UIDocumentalistSettingsFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    DocumentalistSettingsRequestFormModule
  ],
  exports: [
    UIDocumentalistSettingsFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    DocumentalistSettingsRequestFormModule
  ]
})
export class UIDocumentalistSettingsFormModule {}

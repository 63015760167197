/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { VisionToChuncksPageUiListComponent } from 'src/app/generated/dataPages/list/VisionToChuncks/VisionToChuncks.component';
import { VisionToChuncksPageFormComponent } from 'src/app/generated/dataPages/form/VisionToChuncks/VisionToChuncks.component';
import { VisionToChuncksPageUiListModule } from 'src/app/generated/dataPages/list/VisionToChuncks/VisionToChuncks.module';
import { VisionToChuncksPageFormComponentModule } from 'src/app/generated/dataPages/form/VisionToChuncks/VisionToChuncks.module';

export class VisionToChuncksRoutes {
  public static routes = [
    {
      path: 'VisionToChuncks/list',
      component: VisionToChuncksPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'VisionToChuncks/edit/:id',
      component: VisionToChuncksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'VisionToChuncks/new/:id',
      component: VisionToChuncksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [VisionToChuncksPageUiListModule, VisionToChuncksPageFormComponentModule];
}

import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { LlmActionPageFormComponent } from 'src/app/generated/dataPages/form/LlmAction/LlmAction.component';
import { LlmActionPageUiListComponent } from 'src/app/generated/dataPages/list/LlmAction/LlmAction.component';

export class LlmActionRoutes {
  public static routes = [
    {
      path: 'LlmAction/list',
      component: LlmActionPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'LlmAction/edit/:id',
      component: LlmActionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'LlmAction/new/:id',
      component: LlmActionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
}

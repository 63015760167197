/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageRefreshTokenPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageRefreshToken/UserMessageRefreshToken.component';
import { UserMessageRefreshTokenPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageRefreshToken/UserMessageRefreshToken.component';
import { UserMessageRefreshTokenPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageRefreshToken/UserMessageRefreshToken.module';
import { UserMessageRefreshTokenPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageRefreshToken/UserMessageRefreshToken.module';

export class UserMessageRefreshTokenRoutes {
  public static routes = [
    {
      path: 'UserMessageRefreshToken/list',
      component: UserMessageRefreshTokenPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageRefreshToken/edit/:id',
      component: UserMessageRefreshTokenPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageRefreshToken/new/:id',
      component: UserMessageRefreshTokenPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageRefreshTokenPageUiListModule, UserMessageRefreshTokenPageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['settings']">
    <mat-expansion-panel
      [expanded]="state['settings']?._expanded"
      (closed)="propState('settings_expanded', null)"
      (opened)="propState('settings_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> settings {{ fieldClassHumanName[formGroup._settings?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_settings']?.__class?.value">
          <app-ui-form-copywriterstylesettingsrequest
            id="settings"
            *ngSwitchCase="'CopywriterStyleSettingsRequest'"
            [formGroup]="formGroup['_settings']"
          ></app-ui-form-copywriterstylesettingsrequest>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['hidden']"><mat-checkbox formControlName="hidden">hidden</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['isDefault']"><mat-checkbox formControlName="isDefault">isDefault</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['groups']">
    <app-userclientgroup-form-auto-complete [formArr]="formGroup['_groups']" placeholder="groups"> </app-userclientgroup-form-auto-complete>
  </div>
</div>

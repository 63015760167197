import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { protoschemaFormAutoCompleteModule } from '../../chipList/protoschema/protoschema.module';

import { SwaggerParamsFormComponent } from './SwaggerParams.component';
@NgModule({
  declarations: [SwaggerParamsFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatCheckboxModule,

    protoschemaFormAutoCompleteModule
  ],
  exports: [
    SwaggerParamsFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatCheckboxModule,

    protoschemaFormAutoCompleteModule
  ]
})
export class SwaggerParamsFormModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <<<<<<< HEAD =======
  <div class="form-item" *ngIf="!unVisibleComp['externalId']">
    <mat-form-field appearance="fill">
      <mat-label>externalId</mat-label>
      <input matInput placeholder="" formControlName="externalId" />
    </mat-form-field>
  </div>

  >>>>>>> develop
  <div class="form-item" *ngIf="!unVisibleComp['enabledTags']">
    <app-tag-form-auto-complete [formArr]="formGroup['_enabledTags']" [filterQuery]="selectedDisabledTags" placeholder="enabledTags">
    </app-tag-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['disabledTags']">
    <app-tag-form-auto-complete [formArr]="formGroup['_disabledTags']" [filterQuery]="selectedEnabledTags" placeholder="disabledTags">
    </app-tag-form-auto-complete>
  </div>

  <<<<<<< HEAD =======
  <div class="form-item" *ngIf="!unVisibleComp['docSpaceRights']">
    <app-ui-list-sub-doc-docspaceright id="docSpaceRights" label="docSpaceRights" [inputData]="formGroup['_docSpaceRights']">
    </app-ui-list-sub-doc-docspaceright>
  </div>

  >>>>>>> develop
  <div class="form-item" *ngIf="!unVisibleComp['creator']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_creator']" placeholder="creator"> </app-useradmin-form-auto-complete>
  </div>
</div>

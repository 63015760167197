import { Component, Inject, OnInit, Self } from '@angular/core';

import { DataService } from 'src/app/dataService/dataService.service';
import { interfaces } from '@leandredev/adaptivai-common-lib';
import { ApiHelper } from '@leandredev/common-ng16/httpClient';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, map } from 'rxjs';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DocumentSelectorComponent } from '../document-selector/document-selector.component';

import { DocumentEditorComponent } from '../document-editor/document-editor.component';
import { UserMessagesService } from 'src/app/services/user-messages.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { UiList } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [UIComponentState],
  animations: [
    trigger('fade', [
      transition('void => *', [
        // using status here for transition
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('* => void', [animate(500, style({ opacity: 0 }))])
    ])
  ]
})
export class UploadComponent extends UiList<interfaces.ILightDocumentVector> implements OnInit {
  // progress bar
  progressDisplay = false;
  progressColor = 'primary';
  progressMessage = '';

  isLoadingResults;
  dataLength;
  pageIndex;
  pageSize;
  pageSizeOptions;
  private tags: interfaces.ITag[] = [];
  private uploadDocumentRequest: interfaces.IUploadDocumentRequest = null;
  private patchDocumentRequest: interfaces.ILightDocumentVectorRequest = null;

  columns: ListColumn[] = [
    { colId: 'name', visible: true, humanName: 'Nom' },
    { colId: 'tags', visible: true, humanName: 'Tags' },
    { colId: 'text', visible: true, humanName: 'Texte' }
  ];
  constructor(
    @Self() protected uIComponentState: UIComponentState,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public documentRequest: interfaces.IUploadDocumentRequest,
    @Inject(MAT_DIALOG_DATA) public documentUpdate: interfaces.ILightDocumentVectorRequest,
    protected userMessagesService: UserMessagesService,
    private dialog: MatDialog
  ) {
    super();
    this.displayedColumns = ['name', 'tags', 'text'];
    this.columnsType = { name: 'string', tags: 'string', tewt: 'string', id: 'string' };

    this.subscription.add(
      this.userMessagesService.messagesObs.subscribe({
        next: (message) => {
          const progressResult = message as interfaces.IDocumentStatusMessage;
          console.info(`STATUS ${progressResult.status}, message :${progressResult.message}`);
          if (progressResult._class === 'DocumentStatusMessage') {
            this.progressMessage = progressResult.message;
            switch (progressResult.status) {
              case 'START':
                this.progressDisplay = true;

                this.progressColor = 'primary';
                break;
              case 'PROGRESS':
                this.progressColor = 'primary';
                break;
              case 'END':
                setTimeout(() => {
                  this.progressDisplay = false;
                }, 3000);
                break;
              case 'ERROR':
                this.progressColor = 'warn';
                setTimeout(() => {
                  this.progressDisplay = false;
                }, 3000);
                break;
            }
            // eslint-disable-next-line no-restricted-syntax
           
          }
        },
        error: (err) => {
          console.warn('messagesObs err', err);
        }
      })
    );
  }

  ngOnInit(): void {
    this.isLoadingResults = true;
    const obs = combineLatest([
      this.dataService.api.collections.Tag.get('*').pipe(ApiHelper.resultToArr('errro')),
      this.dataService.documents.getDocuments({}).pipe(ApiHelper.resultToArr('errro'))
    ]);
    obs.subscribe((results) => {
      this.tags = results[0];

      const dataSource = new MatTableDataSource<interfaces.ILightDocumentVector>();
      const formatedData: interfaces.ILightDocumentVector[] = [];
      results[1].forEach((d) => {
        if (d.tags) {
          d.tags_pop = d.tags.map((t) => {
            return this.findTag(t);
          });
        } else {
          d.tags = [];
        }

        formatedData.push(d);
      });
      dataSource.data = formatedData;
      this.data = dataSource;
    });
  }
  public onFilterChange(event: string): void {
    this.data.filter = event.trim().toLowerCase();
  }
  public colsFilterChangeListener(event: string[]): void {
    /** empty **/
    this.displayedColumns = event;
  }

  public tagNames(ids: string[]): string {
    const result = [];
    if (ids && ids.length) {
      ids.forEach((id) => {
        try {
          const tagName = this.findTag(id).name;
          result.push(tagName);
        } catch (error) {
          result.push(id);
        }
      });
    }
    return result.join(',');
  }

  public paginatorChange(): void {
    /** empty **/
  }

  public add(): void {
    /** empty **/
    this.dialog
      .open(DocumentSelectorComponent, { disableClose: true, data: { allTags: this.tags } })
      .afterClosed()
      .pipe(
        map((result: interfaces.IUploadDocumentRequest) => {
          if (result != null) return result;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.uploadDocumentRequest = res;
          this.sendUploadDocumentToServer();
        }
      });
  }

  public editItem(data: any): void {
    this.dialog
      .open(DocumentEditorComponent, { disableClose: true, data: { item: data, tags: this.tags } })
      .afterClosed()
      .pipe(
        map((result: interfaces.ILightDocumentVectorRequest) => {
          if (result != null) return result;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.patchDocumentRequest = { document: res.document };
          this.sendPatchDocumentToServer();
        }
      });
  }

  private findTag(id: string): interfaces.ITag {
    const curTag = this.tags.find((t) => t._id.toString() === id);

    return curTag;
  }

  private sendUploadDocumentToServer() {
    this.userMessagesService.checkConnexion();
    return this.dataService.documents.addDocument(this.uploadDocumentRequest, {}).subscribe((res) => {
      console.info(res, { depth: null });
    });
  }

  private sendPatchDocumentToServer() {
    return this.dataService.documents.patchDocument(this.patchDocumentRequest, {}).subscribe((res) => {
      console.info(res, { depth: null });
    });
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, Self, Inject } from '@angular/core';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { FormControlBase } from '@leandredev/common-ng16/form';

import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiForm } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-form-documentdataanalystoutput',
  templateUrl: './DocumentDataAnalystOutput.component.html',
  styleUrls: ['./DocumentDataAnalystOutput.component.scss'],
  providers: [UIComponentState]
})
export class DocumentDataAnalystOutputFormComponent extends UiForm<form.DocumentDataAnalystOutput> implements OnInit {
  @Input() override id = '';
  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['LlmActionOutput'] = 'LlmActionOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['OpenAiPredictOutput'] = 'OpenAiPredictOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['OpenAiChatOutput'] = 'OpenAiChatOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['DocumentalisteSearchOutput'] = 'DocumentalisteSearchOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['DocumentChunkSearchOutput'] = 'DocumentChunkSearchOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['CopywriterOutput'] = 'CopywriterOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['DeeplChatOutput'] = 'DeeplChatOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['AdobeePdfToChuncksOutput'] = 'AdobeePdfToChuncksOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['ExcelToChunksOutput'] = 'ExcelToChunksOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['VisionToChuncksOutput'] = 'VisionToChuncksOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['VisionImageDescriptionOutput'] = 'VisionImageDescriptionOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['TxtToChunksOutput'] = 'TxtToChunksOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['AdobeCreatePdfOutput'] = 'AdobeCreatePdfOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['PdfToImagesOutput'] = 'PdfToImagesOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['FileDataToChunksOutput'] = 'FileDataToChunksOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['FileImageDataToChunksOutput'] = 'FileImageDataToChunksOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['CreatePresentationOutput'] = 'CreatePresentationOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['LangChainFileToChunckOutput'] = 'LangChainFileToChunckOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['CreativeOutput'] = 'CreativeOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['DocumentDataDiscoveryOutput'] = 'DocumentDataDiscoveryOutput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['AnalystChartSetting'] = 'AnalystChartSetting';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
    Object.assign(this.state, this.uIComponentState.state);
    Object.assign(this.uIComponentState.state, this.state);
    this.state = this.uIComponentState.state;
  }

  public fieldClassHumanName: { [key: string]: string } = {};

  public classChange(value: string, control: FormControlBase): void {
    let oldVal: any;
    oldVal = control.value;
    if (!oldVal) {
      oldVal = {};
    }
    oldVal._class = value;
    control.patchValue(oldVal);
  }
}

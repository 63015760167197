import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DayjsModule } from '@leandredev/common-ng16';
import { MatMenuModule } from '@angular/material/menu';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { ListModule } from 'src/@fury/shared/list/list.module';

import { FlowPageUiListComponent } from './Flow.component';
import { FlowListModule } from '../../../ui/list/Flow/Flow.module';
@NgModule({
  declarations: [FlowPageUiListComponent],
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    DayjsModule,
    MatMenuModule,
    FurySharedModule,
    ListModule,

    FlowListModule
  ],

  exports: [
    FlowPageUiListComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    DayjsModule,
    MatMenuModule,

    ListModule,

    FlowListModule
  ]
})
export class FlowPageUiListModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserClientGroupPageUiListComponent } from 'src/app/generated/dataPages/list/UserClientGroup/UserClientGroup.component';
import { UserClientGroupPageFormComponent } from 'src/app/generated/dataPages/form/UserClientGroup/UserClientGroup.component';
import { UserClientGroupPageUiListModule } from 'src/app/generated/dataPages/list/UserClientGroup/UserClientGroup.module';
import { UserClientGroupPageFormComponentModule } from 'src/app/generated/dataPages/form/UserClientGroup/UserClientGroup.module';

export class UserClientGroupRoutes {
  public static routes = [
    {
      path: 'UserClientGroup/list',
      component: UserClientGroupPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClientGroup/edit/:id',
      component: UserClientGroupPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClientGroup/new/:id',
      component: UserClientGroupPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserClientGroupPageUiListModule, UserClientGroupPageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { servicePageUiListComponent } from 'src/app/generated/dataPages/list/service/service.component';
import { servicePageFormComponent } from 'src/app/generated/dataPages/form/service/service.component';
import { servicePageUiListModule } from 'src/app/generated/dataPages/list/service/service.module';
import { servicePageFormComponentModule } from 'src/app/generated/dataPages/form/service/service.module';

export class serviceRoutes {
  public static routes = [
    {
      path: 'service/list',
      component: servicePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'service/edit/:id',
      component: servicePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'service/new/:id',
      component: servicePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [servicePageUiListModule, servicePageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['originalname']">
    <mat-form-field appearance="fill">
      <mat-label>originalname</mat-label>
      <input matInput placeholder="" formControlName="originalname" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['encoding']">
    <mat-form-field appearance="fill">
      <mat-label>encoding</mat-label>
      <input matInput placeholder="" formControlName="encoding" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['mimetype']">
    <mat-form-field appearance="fill">
      <mat-label>mimetype</mat-label>
      <input matInput placeholder="" formControlName="mimetype" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['size']">
    <mat-form-field appearance="fill">
      <mat-label>size</mat-label>
      <input matInput placeholder="" formControlName="size" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['path']">
    <mat-form-field appearance="fill">
      <mat-label>path</mat-label>
      <input matInput placeholder="" formControlName="path" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['createdAt']">
    <mat-form-field appearance="fill">
      <mat-label>createdAt</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreatedAt" formControlName="createdAt" />
      <mat-datepicker-toggle matSuffix [for]="mycreatedAt"></mat-datepicker-toggle>
      <mat-datepicker #mycreatedAt></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['updatedAt']">
    <mat-form-field appearance="fill">
      <mat-label>updatedAt</mat-label>
      <input matInput placeholder="" [matDatepicker]="myupdatedAt" formControlName="updatedAt" />
      <mat-datepicker-toggle matSuffix [for]="myupdatedAt"></mat-datepicker-toggle>
      <mat-datepicker #myupdatedAt></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['downloadUrl']">
    <mat-form-field appearance="fill">
      <mat-label>downloadUrl</mat-label>
      <input matInput placeholder="" formControlName="downloadUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['accessKeys']">
    <app-string-list label="accessKeys" [formArr]="formGroup['_accessKeys']"></app-string-list>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interfaces } from '@leandredev/adaptivai-common-lib';
import { DocumentSelectorComponent } from '../document-selector/document-selector.component';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss']
})
export class DocumentEditorComponent {
  private currentdocument: interfaces.ILightDocumentVector;
  constructor(
    @Inject(MAT_DIALOG_DATA) public documentUpdate: { item: interfaces.ILightDocumentVector; tags: interfaces.ITag[] },
    private dialogRef: MatDialogRef<DocumentSelectorComponent>
  ) {
    /** empty */
    this.currentdocument = documentUpdate.item;
  }

  public validate(): void {
    this.dialogRef.close(this.currentdocument);
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public updateTagForRequest(data: interfaces.ITag[]): void {
    this.currentdocument.tags = data.map((t) => t._id.toString());
  }
}

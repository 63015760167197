import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedModule } from '@leandredev/common-ng16/components';

import { TxtToChunksOutputFormComponent } from './TxtToChunksOutput.component';
@NgModule({
  declarations: [TxtToChunksOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule],
  exports: [TxtToChunksOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule]
})
export class TxtToChunksOutputFormModule {}

import { Component, OnChanges, ViewChild, OnInit, Input, Self, SimpleChanges } from '@angular/core';

import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { ListComponent, PageTitleService } from '@leandredev/common-ng16/components';
import { ObjectId } from '@leandredev/common-ng16/form';
import { interfaces } from '@leandredev/adaptivai-common-lib';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';

import { DataService } from 'src/app/dataService/dataService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserClientUiListComponent } from '../../../ui/list/UserClient/UserClient.component';

@Component({
  selector: 'app-userclient-list',
  templateUrl: './UserClient.component.html',
  styleUrls: ['./UserClient.component.scss'],
  providers: [UIComponentState]
})
export class UserClientPageUiListComponent extends ListComponent<interfaces.IUserClient> implements OnInit, OnChanges {
  @ViewChild(UserClientUiListComponent, { static: true }) table!: UserClientUiListComponent;

  @Input()
  public filterQuery!: string;
  public columns: ListColumn[] = [
    { colId: 'firstName', visible: false, humanName: 'firstName' },
    { colId: 'lastName', visible: false, humanName: 'lastName' },
    { colId: 'email', visible: false, humanName: 'email' },
    { colId: 'password', visible: false, humanName: 'password' },
    { colId: 'name', visible: false, humanName: 'name' },
    { colId: 'resetPasswordKey', visible: false, humanName: 'resetPasswordKey' },
    { colId: 'resetPasswordEndDate', visible: false, humanName: 'resetPasswordEndDate' },
    { colId: 'isActivated', visible: false, humanName: 'isActivated' }
  ];

  constructor(
    protected dataService: DataService,
    route: Router,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    protected titleService: PageTitleService
  ) {
    super(route, activeRoute, uIComponentState);

    this.titleService.setPageTitle(`UserClient`);

    this.httpServiceBase = dataService.api.collections.UserClient;
  }

  setDefaultStatePopValues(): void {
    //super.setDefaultStatePopValues();
    this.pageSizeOptions = [20, 50, 100, 500];
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = this.columns.slice(0, 3).map((t) => t.colId);
    }
  }

  afterStateChanged(): void {
    // super.afterStateChanged();
    if (this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((col) => {
        const colToActivateIndex = this.columns.findIndex((c) => c.colId === col);

        if (colToActivateIndex > -1) {
          this.columns[colToActivateIndex].visible = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBase = this.filterQuery;
  }

  public colsFilterChangeListener($event): void {
    this.displayedColumns = $event;
  }

  public add(className = ''): void {
    if (className === '') {
      void this.router.navigate([`UserClient/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    } else {
      void this.router.navigate([`${className}/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    }
  }

  public addWithMail(): void {
    void this.router.navigate([`UserClient/newIvitation/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
  }

  onFilterChange($event): void {
    this.applyFilter($event);
  }
  override rowClicked(row: interfaces.IUserClient): void {
    if (!row._class) {
      row._class = 'UserClient';
    }
    super.rowClicked(row);
  }
}

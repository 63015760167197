import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { LlmActionOutputFormComponent } from './LlmActionOutput.component';
@NgModule({
  declarations: [LlmActionOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
  exports: [LlmActionOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule]
})
export class LlmActionOutputFormModule {}

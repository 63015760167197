<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>order</th>
        <td mat-cell *matCellDef="let row" (click)="startEdit($event, row)">
          <div class="cell-content">
            <input type="number" [(value)]="row.order" (keyup.enter)="sendit(row, $event.target.value)" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>color</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content" *ngIf="row.color">
            <div *ngFor="let color of row.color.split(',')" style="display: inline-flex">
              <div class="circle" [style]="{ 'background-color': color }"></div>
              <span>{{ color }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isMandatory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>isMandatory</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.isMandatory }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isDataQualifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>isDataQualifier</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.isDataQualifier }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isReadOnly">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>isReadOnly</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.isReadOnly }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="import">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>import</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.import }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-availableaimodels',
  templateUrl: './AvailableAiModels.component.html',
  styleUrls: ['./AvailableAiModels.component.scss'],
  providers: [UIComponentState]
})
export class AvailableAiModelsUiListComponent extends UiList<interfaces.IAvailableAiModels> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      model: 'string',
      description: 'string',
      driver: 'string',
      isActive: 'boolean',
      category: 'string'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'model', 'description', 'driver'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

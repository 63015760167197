import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { PageLayoutModule } from 'src/@fury/shared/page-layout/page-layout.module';
import { ConfirmationDialogModule } from 'src/app/app-specific/confirmation/confirmation.module';
import { VisionToChuncksPageFormComponent } from './VisionToChuncks.component';
import { VisionToChuncksFormModule } from '../../../ui/form/VisionToChuncks/VisionToChuncks.module';

@NgModule({
  declarations: [VisionToChuncksPageFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FuryCardModule,
    FurySharedModule,
    PageLayoutModule,
    ConfirmationDialogModule,
    VisionToChuncksFormModule
  ],

  exports: [
    VisionToChuncksPageFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    VisionToChuncksFormModule,
    FurySharedModule,
    FuryCardModule
  ]
})
export class VisionToChuncksPageFormComponentModule {}

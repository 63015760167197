export { protoschemaRoutes } from './protoschema.routes';

export { _viewRoutes } from './_view.routes';

export { LlmActionRoutes } from './LlmAction.routes';

export { SwaggerApiRoutes } from './SwaggerApi.routes';

export { UserMessageRoutes } from './UserMessage.routes';

export { TagRoutes } from './Tag.routes';

export { TagTypeRoutes } from './TagType.routes';

export { FileRoutes } from './File.routes';

export { AssistantSessionRoutes } from './AssistantSession.routes';

export { InfraMessageRoutes } from './InfraMessage.routes';

export { UserAdminRoutes } from './UserAdmin.routes';

export { UserClientGroupRoutes } from './UserClientGroup.routes';

export { UserClientRoutes } from './UserClient.routes';

export { AssistantRoutes } from './Assistant.routes';

export { CopywriterStyleRecordRoutes } from './CopywriterStyleRecord.routes';

export { UserClientInvitationRoutes } from './UserClientInvitation.routes';

export { ImportingFileRoutes } from './ImportingFile.routes';

export { AvailableAiModelsRoutes } from './AvailableAiModels.routes';

export { UserActionStatsRoutes } from './UserActionStats.routes';

export { UserImportStatsRoutes } from './UserImportStats.routes';

export { FlowRoutes } from './Flow.routes';

export { application_configurationRoutes } from './application_configuration.routes';

export { serviceRoutes } from './service.routes';

export { service_accessRoutes } from './service_access.routes';

export { _view_paramsRoutes } from './_view_params.routes';

export { OpenAiPredictRoutes } from './OpenAiPredict.routes';

export { OpenAiChatRoutes } from './OpenAiChat.routes';

export { DocumentChunkSearchRoutes } from './DocumentChunkSearch.routes';

export { DeeplRoutes } from './Deepl.routes';

export { AdobeePdfToChuncksRoutes } from './AdobeePdfToChuncks.routes';

export { AdobeWordPdfToChunksRoutes } from './AdobeWordPdfToChunks.routes';

export { ExcelToChunksRoutes } from './ExcelToChunks.routes';

export { VisionToChuncksRoutes } from './VisionToChuncks.routes';

export { VisionImageDescriptionRoutes } from './VisionImageDescription.routes';

export { TxtToChunksRoutes } from './TxtToChunks.routes';

export { AdobeCreatePdfRoutes } from './AdobeCreatePdf.routes';

export { PdfToImagesRoutes } from './PdfToImages.routes';

export { FileDataToChunksRoutes } from './FileDataToChunks.routes';

export { FileImageDataToChunksRoutes } from './FileImageDataToChunks.routes';

export { CreatePresentationRoutes } from './CreatePresentation.routes';

export { LangChainFileToChunckRoutes } from './LangChainFileToChunck.routes';

export { CreativeRoutes } from './Creative.routes';

export { DocumentDataAnalystOutputRoutes } from './DocumentDataAnalystOutput.routes';

export { DocumentDataDiscoveryRoutes } from './DocumentDataDiscovery.routes';

export { DocumentDataAnalystRoutes } from './DocumentDataAnalyst.routes';

export { DocumentAnalystDiscoveryRoutes } from './DocumentAnalystDiscovery.routes';

export { VisionSlideTypeRoutes } from './VisionSlideType.routes';

export { CopywriterRoutes } from './Copywriter.routes';

export { DocumentalisteSearchRoutes } from './DocumentalisteSearch.routes';

export { UserMessageErrorRoutes } from './UserMessageError.routes';

export { UserMessageProgressRoutes } from './UserMessageProgress.routes';

export { UserMessageEndRoutes } from './UserMessageEnd.routes';

export { UserMessageInfoRoutes } from './UserMessageInfo.routes';

export { DocumentStatusMessageRoutes } from './DocumentStatusMessage.routes';

export { UserMessageTextStreamRoutes } from './UserMessageTextStream.routes';

export { UserMessageAssistantRoutes } from './UserMessageAssistant.routes';

export { UserMessageAssistantDeletionRoutes } from './UserMessageAssistantDeletion.routes';

export { UserMessageRefreshTokenRoutes } from './UserMessageRefreshToken.routes';

export { MsgActionToExecuteRoutes } from './MsgActionToExecute.routes';

export { application_configuration_adaptivai_adminRoutes } from './application_configuration_adaptivai_admin.routes';

export { application_configuration_adaptivai_clientRoutes } from './application_configuration_adaptivai_client.routes';

export { service_fsoRoutes } from './service_fso.routes';

export { adaptivai_admin_apiRoutes } from './adaptivai_admin_api.routes';

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['startDate']">
    <mat-form-field appearance="fill">
      <mat-label>startDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mystartDate" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="mystartDate"></mat-datepicker-toggle>
      <mat-datepicker #mystartDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['state']">
    <mat-form-field appearance="fill">
      <mat-label>state</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceId']">
    <mat-form-field appearance="fill">
      <mat-label>serviceId</mat-label>
      <input matInput placeholder="" formControlName="serviceId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endDate']">
    <mat-form-field appearance="fill">
      <mat-label>endDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myendDate" formControlName="endDate" />
      <mat-datepicker-toggle matSuffix [for]="myendDate"></mat-datepicker-toggle>
      <mat-datepicker #myendDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['deleted']"><mat-checkbox formControlName="deleted">deleted</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['hasFailed']"><mat-checkbox formControlName="hasFailed">hasFailed</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['errorsList']">
    <app-ui-list-sub-doc-errorobj id="errorsList" label="errorsList" [inputData]="formGroup['_errorsList']"> </app-ui-list-sub-doc-errorobj>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceCreator']">
    <mat-form-field appearance="fill">
      <mat-label>serviceCreator</mat-label>
      <input matInput placeholder="" formControlName="serviceCreator" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantSessionId']">
    <app-assistantsession-form-auto-complete [formCtrl]="formGroup['_assistantSessionId']" placeholder="assistantSessionId">
    </app-assistantsession-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lang']">
    <mat-form-field appearance="fill">
      <mat-label>lang</mat-label>
      <input matInput placeholder="" formControlName="lang" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llm']">
    <mat-form-field appearance="fill">
      <mat-label>llm</mat-label>
      <input matInput placeholder="" formControlName="llm" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmModel']">
    <mat-form-field appearance="fill">
      <mat-label>llmModel</mat-label>
      <input matInput placeholder="" formControlName="llmModel" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endInteractionServiceId']">
    <mat-form-field appearance="fill">
      <mat-label>endInteractionServiceId</mat-label>
      <input matInput placeholder="" formControlName="endInteractionServiceId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['url']">
    <mat-form-field appearance="fill">
      <mat-label>url</mat-label>
      <input matInput placeholder="" formControlName="url" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['blob']">
    <mat-form-field appearance="fill">
      <mat-label>blob</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['mimeType']">
    <mat-form-field appearance="fill">
      <mat-label>mimeType</mat-label>
      <input matInput placeholder="" formControlName="mimeType" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['output']">
    <mat-expansion-panel
      [expanded]="state['output']?._expanded"
      (closed)="propState('output_expanded', null)"
      (opened)="propState('output_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> output {{ fieldClassHumanName[formGroup._output?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_output']?.__class?.value">
          <app-ui-form-visiontochuncksoutput
            id="output"
            *ngSwitchCase="'VisionToChuncksOutput'"
            [formGroup]="formGroup['_output']"
          ></app-ui-form-visiontochuncksoutput>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['imageType']">
    <mat-form-field appearance="fill">
      <mat-label>imageType</mat-label>

      <mat-select placeholder="Select" formControlName="imageType">
        <mat-option value="visual">visual</mat-option>

        <mat-option value="infographic">infographic</mat-option>

        <mat-option value="data">data</mat-option>

        <mat-option value="video_storyboard">video_storyboard</mat-option>

        <mat-option value="Advertising">Advertising</mat-option>

        <mat-option value="text-component">text-component</mat-option>

        <mat-option value="chart-component">chart-component</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

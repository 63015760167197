import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@leandredev/common-ng16/components';

import { DayjsModule } from '@leandredev/common-ng16';

import { FlowStepFormModule } from '../../form/FlowStep/FlowStep.module';

import { FlowStepUIListSubDocComponent } from './FlowStep.component';

import { FlowStepDocumentalistFormModule } from '../../form/FlowStepDocumentalist/FlowStepDocumentalist.module';

import { FlowStepAnalystFormModule } from '../../form/FlowStepAnalyst/FlowStepAnalyst.module';

import { FlowStepCopywriterFormModule } from '../../form/FlowStepCopywriter/FlowStepCopywriter.module';

@NgModule({
  declarations: [FlowStepUIListSubDocComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    DayjsModule,
    SharedModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,

    FlowStepFormModule,

    FlowStepDocumentalistFormModule,

    FlowStepAnalystFormModule,

    FlowStepCopywriterFormModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,
    DayjsModule,
    SharedModule,

    FlowStepFormModule,

    FlowStepDocumentalistFormModule,

    FlowStepAnalystFormModule,

    FlowStepCopywriterFormModule,

    FlowStepUIListSubDocComponent
  ]
})
export class FlowStepListSubDocModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { TxtToChunksPageUiListComponent } from 'src/app/generated/dataPages/list/TxtToChunks/TxtToChunks.component';
import { TxtToChunksPageFormComponent } from 'src/app/generated/dataPages/form/TxtToChunks/TxtToChunks.component';
import { TxtToChunksPageUiListModule } from 'src/app/generated/dataPages/list/TxtToChunks/TxtToChunks.module';
import { TxtToChunksPageFormComponentModule } from 'src/app/generated/dataPages/form/TxtToChunks/TxtToChunks.module';

export class TxtToChunksRoutes {
  public static routes = [
    {
      path: 'TxtToChunks/list',
      component: TxtToChunksPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'TxtToChunks/edit/:id',
      component: TxtToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'TxtToChunks/new/:id',
      component: TxtToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [TxtToChunksPageUiListModule, TxtToChunksPageFormComponentModule];
}

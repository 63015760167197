<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['output']">
    <mat-form-field appearance="fill">
      <mat-label>output</mat-label>
      <input matInput placeholder="" formControlName="output" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['model']">
    <mat-form-field appearance="fill">
      <mat-label>model</mat-label>
      <input matInput placeholder="" formControlName="model" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['pipeline']">
    <mat-form-field appearance="fill">
      <mat-label>pipeline</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['params']">
    <app-ui-list-sub-doc-routeparam id="params" label="params" [inputData]="formGroup['_params']"> </app-ui-list-sub-doc-routeparam>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['castParams']">
    <app-ui-list-sub-doc-castparam id="castParams" label="castParams" [inputData]="formGroup['_castParams']"> </app-ui-list-sub-doc-castparam>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@leandredev/common-ng16/components';

import { DayjsModule } from '@leandredev/common-ng16';

import { HistoryMessageFormModule } from '../../form/HistoryMessage/HistoryMessage.module';

import { HistoryMessageUIListSubDocComponent } from './HistoryMessage.component';

@NgModule({
  declarations: [HistoryMessageUIListSubDocComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    DayjsModule,
    SharedModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,

    HistoryMessageFormModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,
    DayjsModule,
    SharedModule,

    HistoryMessageFormModule,

    HistoryMessageUIListSubDocComponent
  ]
})
export class HistoryMessageListSubDocModule {}

<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>message</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.message }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="llmActionId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>llmActionId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="LlmAction" [idRef]="row.llmActionId">
              {{ obj.data?.name || obj.data?.title || row.llmActionId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>creationDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.creationDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceIds">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>serviceIds</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.serviceIds }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="UserAdmin" [idRef]="row.userId">
              {{ obj.data?.name || obj.data?.title || row.userId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="assistantSessionId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>assistantSessionId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="AssistantSession" [idRef]="row.assistantSessionId">
              {{ obj.data?.name || obj.data?.title || row.assistantSessionId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>status</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.status }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="importingFile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>importingFile</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.importingFile | json }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

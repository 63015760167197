<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['lang']">
    <mat-form-field appearance="fill">
      <mat-label>lang</mat-label>
      <input matInput placeholder="" formControlName="lang" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tags']">
    <app-string-list label="tags" [formArr]="formGroup['_tags']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['query']">
    <mat-form-field appearance="fill">
      <mat-label>query</mat-label>
      <input matInput placeholder="" formControlName="query" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['documentsId']">
    <app-file-form-auto-complete [formArr]="formGroup['_documentsId']" placeholder="documentsId"> </app-file-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['chunksNumber']">
    <mat-form-field appearance="fill">
      <mat-label>chunksNumber</mat-label>
      <input matInput placeholder="" formControlName="chunksNumber" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['searchType']">
    <mat-form-field appearance="fill">
      <mat-label>searchType</mat-label>

      <mat-select placeholder="Select" formControlName="searchType">
        <mat-option value="best_document">best_document</mat-option>

        <mat-option value="max_marginal_relevance">max_marginal_relevance</mat-option>

        <mat-option value="similarity">similarity</mat-option>

        <mat-option value="multi_query">multi_query</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['suggestQuestions']">
    <mat-checkbox formControlName="suggestQuestions">suggestQuestions</mat-checkbox><br />
  </div>
</div>

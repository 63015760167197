<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>description</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.description }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>author</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="UserClient" [idRef]="row.author">
              {{ obj.data?.name || obj.data?.title || row.author }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>creationDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.creationDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>updated</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.updated | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="steps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>steps</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.steps | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="params">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>params</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.params | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="docspace">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>docspace</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="Tag" [idRef]="row.docspace">
              {{ obj.data?.name || obj.data?.title || row.docspace }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>creationDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.creationDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>startDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.startDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>state</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.state }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>serviceId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.serviceId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>endDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.endDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deleted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>deleted</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.deleted }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="hasFailed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>hasFailed</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.hasFailed }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorsList">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>errorsList</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.errorsList | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="UserAdmin" [idRef]="row.userId">
              {{ obj.data?.name || obj.data?.title || row.userId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceCreator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>serviceCreator</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.serviceCreator }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="assistantSessionId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>assistantSessionId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="AssistantSession" [idRef]="row.assistantSessionId">
              {{ obj.data?.name || obj.data?.title || row.assistantSessionId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="lang">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>lang</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.lang }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="llm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>llm</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.llm }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="llmModel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>llmModel</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.llmModel }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="endInteractionServiceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>endInteractionServiceId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.endInteractionServiceId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="prompt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>prompt</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.prompt }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="output">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>output</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.output | json }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

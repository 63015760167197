<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['author']">
    <app-userclient-form-auto-complete [formCtrl]="formGroup['_author']" placeholder="author"> </app-userclient-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['updated']">
    <mat-form-field appearance="fill">
      <mat-label>updated</mat-label>
      <input matInput placeholder="" [matDatepicker]="myupdated" formControlName="updated" />
      <mat-datepicker-toggle matSuffix [for]="myupdated"></mat-datepicker-toggle>
      <mat-datepicker #myupdated></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['steps']">
    <app-ui-list-sub-doc-flowstep id="steps" label="steps" [inputData]="formGroup['_steps']"> </app-ui-list-sub-doc-flowstep>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['params']">
    <app-ui-list-sub-doc-flowparams id="params" label="params" [inputData]="formGroup['_params']"> </app-ui-list-sub-doc-flowparams>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['docspace']">
    <app-tag-form-auto-complete [formCtrl]="formGroup['_docspace']" placeholder="docspace"> </app-tag-form-auto-complete>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { VisionSlideTypePageUiListComponent } from 'src/app/generated/dataPages/list/VisionSlideType/VisionSlideType.component';
import { VisionSlideTypePageFormComponent } from 'src/app/generated/dataPages/form/VisionSlideType/VisionSlideType.component';
import { VisionSlideTypePageUiListModule } from 'src/app/generated/dataPages/list/VisionSlideType/VisionSlideType.module';
import { VisionSlideTypePageFormComponentModule } from 'src/app/generated/dataPages/form/VisionSlideType/VisionSlideType.module';

export class VisionSlideTypeRoutes {
  public static routes = [
    {
      path: 'VisionSlideType/list',
      component: VisionSlideTypePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'VisionSlideType/edit/:id',
      component: VisionSlideTypePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'VisionSlideType/new/:id',
      component: VisionSlideTypePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [VisionSlideTypePageUiListModule, VisionSlideTypePageFormComponentModule];
}

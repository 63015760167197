<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['paths']">
    <app-ui-list-sub-doc-swaggerpath id="paths" label="paths" [inputData]="formGroup['_paths']"> </app-ui-list-sub-doc-swaggerpath>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <input matInput placeholder="" formControlName="message" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmActionId']">
    <app-llmaction-form-auto-complete [formCtrl]="formGroup['_llmActionId']" placeholder="llmActionId"> </app-llmaction-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceIds']">
    <app-string-list label="serviceIds" [formArr]="formGroup['_serviceIds']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantSessionId']">
    <app-assistantsession-form-auto-complete [formCtrl]="formGroup['_assistantSessionId']" placeholder="assistantSessionId">
    </app-assistantsession-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['status']">
    <mat-form-field appearance="fill">
      <mat-label>status</mat-label>

      <mat-select placeholder="Select" formControlName="status">
        <mat-option value="START">START</mat-option>

        <mat-option value="PROGRESS">PROGRESS</mat-option>

        <mat-option value="END">END</mat-option>

        <mat-option value="ERROR">ERROR</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['importingFile']">
    <mat-expansion-panel
      [expanded]="state['importingFile']?._expanded"
      (closed)="propState('importingFile_expanded', null)"
      (opened)="propState('importingFile_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> importingFile {{ fieldClassHumanName[formGroup._importingFile?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_importingFile']?.__class?.value">
          <app-ui-form-importingfile
            id="importingFile"
            *ngSwitchCase="'ImportingFile'"
            [formGroup]="formGroup['_importingFile']"
          ></app-ui-form-importingfile>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>
</div>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appFormPassword]'
})
export class FormPasswordDirective {
  @HostListener('input', ['$event.target.value'])
  onInput = (data: any): void => {

    const msgUint8 = new TextEncoder().encode(data);
    crypto.subtle
      .digest('SHA-256', msgUint8)
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const val = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        this.passwordFormControl.setValue(val);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  @Input() public passwordFormControl: FormControl;

  constructor(private el: ElementRef) {}
}

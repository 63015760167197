/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { InfraMessagePageUiListComponent } from 'src/app/generated/dataPages/list/InfraMessage/InfraMessage.component';
import { InfraMessagePageFormComponent } from 'src/app/generated/dataPages/form/InfraMessage/InfraMessage.component';
import { InfraMessagePageUiListModule } from 'src/app/generated/dataPages/list/InfraMessage/InfraMessage.module';
import { InfraMessagePageFormComponentModule } from 'src/app/generated/dataPages/form/InfraMessage/InfraMessage.module';

export class InfraMessageRoutes {
  public static routes = [
    {
      path: 'InfraMessage/list',
      component: InfraMessagePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'InfraMessage/edit/:id',
      component: InfraMessagePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'InfraMessage/new/:id',
      component: InfraMessagePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [InfraMessagePageUiListModule, InfraMessagePageFormComponentModule];
}

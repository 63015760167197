/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { ImportingFilePageUiListComponent } from 'src/app/generated/dataPages/list/ImportingFile/ImportingFile.component';
import { ImportingFilePageFormComponent } from 'src/app/generated/dataPages/form/ImportingFile/ImportingFile.component';
import { ImportingFilePageUiListModule } from 'src/app/generated/dataPages/list/ImportingFile/ImportingFile.module';
import { ImportingFilePageFormComponentModule } from 'src/app/generated/dataPages/form/ImportingFile/ImportingFile.module';

export class ImportingFileRoutes {
  public static routes = [
    {
      path: 'ImportingFile/list',
      component: ImportingFilePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'ImportingFile/edit/:id',
      component: ImportingFilePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'ImportingFile/new/:id',
      component: ImportingFilePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [ImportingFilePageUiListModule, ImportingFilePageFormComponentModule];
}

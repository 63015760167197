/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { FileDataToChunksPageUiListComponent } from 'src/app/generated/dataPages/list/FileDataToChunks/FileDataToChunks.component';
import { FileDataToChunksPageFormComponent } from 'src/app/generated/dataPages/form/FileDataToChunks/FileDataToChunks.component';
import { FileDataToChunksPageUiListModule } from 'src/app/generated/dataPages/list/FileDataToChunks/FileDataToChunks.module';
import { FileDataToChunksPageFormComponentModule } from 'src/app/generated/dataPages/form/FileDataToChunks/FileDataToChunks.module';

export class FileDataToChunksRoutes {
  public static routes = [
    {
      path: 'FileDataToChunks/list',
      component: FileDataToChunksPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'FileDataToChunks/edit/:id',
      component: FileDataToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'FileDataToChunks/new/:id',
      component: FileDataToChunksPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [FileDataToChunksPageUiListModule, FileDataToChunksPageFormComponentModule];
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-useradmin',
  templateUrl: './UserAdmin.component.html',
  styleUrls: ['./UserAdmin.component.scss'],
  providers: [UIComponentState]
})
export class UserAdminUiListComponent extends UiList<interfaces.IUserAdmin> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      firstName: 'string',
      lastName: 'string',
      email: 'string',
      password: 'password',
      name: 'string',
      resetPasswordKey: 'string',
      resetPasswordEndDate: 'date',
      userConf: 'subdoc',
      superAdmin: 'boolean',
      apiKey: 'string'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['firstName', 'lastName', 'email', 'password'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

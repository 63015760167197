import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedModule } from '@leandredev/common-ng16/components';

import { AdobeWordPdfToChunksOutputFormComponent } from './AdobeWordPdfToChunksOutput.component';
@NgModule({
  declarations: [AdobeWordPdfToChunksOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule],
  exports: [AdobeWordPdfToChunksOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule]
})
export class AdobeWordPdfToChunksOutputFormModule {}

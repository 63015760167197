<div class="selector-container">
  <span class="table-desc">{{ label }}</span>

  <table
    cdkDropList
    #selectorTable
    mat-table
    [dataSource]="inputData.controls"
    multiTemplateDataRows
    class="mat-elevation-z8"
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container matColumnDef="drag">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="drag" mat-cell *matCellDef="let row">
        <mat-icon cdkDragHandle>drag_handle</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="_class">
      <th mat-header-cell *matHeaderCellDef>type</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">{{ classToHumanName[row?.value?._class] }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef>userName</th>
      <td mat-cell *matCellDef="let row">
        <div class="cell-content">
          {{ row._userName?.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions">Actions</th>
      <td mat-cell *matCellDef="let element" class="actions">
        <button class="table-button" mat-button color="accent" (click)="deleteTemplate($event, element)">
          <mat-icon aria-label="icon button trash">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="content-form" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
          <mat-card>
            <mat-card-content>
              <!--ajout d'un id-->

              <app-ui-form-uichatmessage id="uichatmessage_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'UIChatMessage'">
              </app-ui-form-uichatmessage>

              <app-ui-form-uichatwelcome id="uichatwelcome_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'UIChatWelcome'">
              </app-ui-form-uichatwelcome>

              <app-ui-form-uidocumentalistwelcome
                id="uidocumentalistwelcome_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIDocumentalistWelcome'"
              >
              </app-ui-form-uidocumentalistwelcome>

              <app-ui-form-uidocumentalistsettings
                id="uidocumentalistsettings_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIDocumentalistSettings'"
              >
              </app-ui-form-uidocumentalistsettings>

              <app-ui-form-uicopywriterwelcome
                id="uicopywriterwelcome_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICopywriterWelcome'"
              >
              </app-ui-form-uicopywriterwelcome>

              <app-ui-form-uidocumentalistuserinput
                id="uidocumentalistuserinput_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIDocumentalistUserInput'"
              >
              </app-ui-form-uidocumentalistuserinput>

              <app-ui-form-uicopywritersettings
                id="uicopywritersettings_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICopywriterSettings'"
              >
              </app-ui-form-uicopywritersettings>

              <app-ui-form-uidocumentalistsettingsdrawer
                id="uidocumentalistsettingsdrawer_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIDocumentalistSettingsDrawer'"
              >
              </app-ui-form-uidocumentalistsettingsdrawer>

              <app-ui-form-uicopywriteruserinput
                id="uicopywriteruserinput_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICopywriterUserInput'"
              >
              </app-ui-form-uicopywriteruserinput>

              <app-ui-form-uicopywritersettingsdrawer
                id="uicopywritersettingsdrawer_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICopywriterSettingsDrawer'"
              >
              </app-ui-form-uicopywritersettingsdrawer>

              <app-ui-form-uierrormessage id="uierrormessage_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'UIErrorMessage'">
              </app-ui-form-uierrormessage>

              <app-ui-form-uicreativewelcome
                id="uicreativewelcome_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICreativeWelcome'"
              >
              </app-ui-form-uicreativewelcome>

              <app-ui-form-uicreativesettingsdrawer
                id="uicreativesettingsdrawer_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICreativeSettingsDrawer'"
              >
              </app-ui-form-uicreativesettingsdrawer>

              <app-ui-form-uicreativesettings
                id="uicreativesettings_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICreativeSettings'"
              >
              </app-ui-form-uicreativesettings>

              <app-ui-form-uicreativeuserinput
                id="uicreativeuserinput_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICreativeUserInput'"
              >
              </app-ui-form-uicreativeuserinput>

              <app-ui-form-uicopywriterstylesettings
                id="uicopywriterstylesettings_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICopywriterStyleSettings'"
              >
              </app-ui-form-uicopywriterstylesettings>

              <app-ui-form-uicopywriterstylesettingsdrawer
                id="uicopywriterstylesettingsdrawer_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UICopywriterStyleSettingsDrawer'"
              >
              </app-ui-form-uicopywriterstylesettingsdrawer>

              <app-ui-form-uianalystuserinput
                id="uianalystuserinput_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIAnalystUserInput'"
              >
              </app-ui-form-uianalystuserinput>

              <app-ui-form-uianalystsettingsdrawer
                id="uianalystsettingsdrawer_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIAnalystSettingsDrawer'"
              >
              </app-ui-form-uianalystsettingsdrawer>

              <app-ui-form-uianalystwelcome id="uianalystwelcome_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'UIAnalystWelcome'">
              </app-ui-form-uianalystwelcome>

              <app-ui-form-uianalystdatamessage
                id="uianalystdatamessage_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIAnalystDataMessage'"
              >
              </app-ui-form-uianalystdatamessage>

              <app-ui-form-uianalystsettings
                id="uianalystsettings_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'UIAnalystSettings'"
              >
              </app-ui-form-uianalystsettings>

              <app-ui-form-flowstep id="flowstep_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'FlowStep'"> </app-ui-form-flowstep>

              <app-ui-form-flowstepdocumentalist
                id="flowstepdocumentalist_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'FlowStepDocumentalist'"
              >
              </app-ui-form-flowstepdocumentalist>

              <app-ui-form-flowstepanalyst id="flowstepanalyst_id" [formGroup]="$any(element)" *ngIf="element.value._class === 'FlowStepAnalyst'">
              </app-ui-form-flowstepanalyst>

              <app-ui-form-flowstepcopywriter
                id="flowstepcopywriter_id"
                [formGroup]="$any(element)"
                *ngIf="element.value._class === 'FlowStepCopywriter'"
              >
              </app-ui-form-flowstepcopywriter>
            </mat-card-content>
          </mat-card>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="addObject">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="columnsToDisplay.length">
        <button mat-button [matMenuTriggerFor]="addObj">Selectionner le type d'élément à ajouter</button>
        <mat-menu #addObj="matMenu">
          <button mat-menu-item (click)="addItemByMenu('UIChatMessage')">UIChatMessage</button>

          <button mat-menu-item (click)="addItemByMenu('UIChatWelcome')">UIChatWelcome</button>

          <button mat-menu-item (click)="addItemByMenu('UIDocumentalistWelcome')">UIDocumentalistWelcome</button>

          <button mat-menu-item (click)="addItemByMenu('UIDocumentalistSettings')">UIDocumentalistSettings</button>

          <button mat-menu-item (click)="addItemByMenu('UICopywriterWelcome')">UICopywriterWelcome</button>

          <button mat-menu-item (click)="addItemByMenu('UIDocumentalistUserInput')">UIDocumentalistUserInput</button>

          <button mat-menu-item (click)="addItemByMenu('UICopywriterSettings')">UICopywriterSettings</button>

          <button mat-menu-item (click)="addItemByMenu('UIDocumentalistSettingsDrawer')">UIDocumentalistSettingsDrawer</button>

          <button mat-menu-item (click)="addItemByMenu('UICopywriterUserInput')">UICopywriterUserInput</button>

          <button mat-menu-item (click)="addItemByMenu('UICopywriterSettingsDrawer')">UICopywriterSettingsDrawer</button>

          <button mat-menu-item (click)="addItemByMenu('UIErrorMessage')">UIErrorMessage</button>

          <button mat-menu-item (click)="addItemByMenu('UICreativeWelcome')">UICreativeWelcome</button>

          <button mat-menu-item (click)="addItemByMenu('UICreativeSettingsDrawer')">UICreativeSettingsDrawer</button>

          <button mat-menu-item (click)="addItemByMenu('UICreativeSettings')">UICreativeSettings</button>

          <button mat-menu-item (click)="addItemByMenu('UICreativeUserInput')">UICreativeUserInput</button>

          <button mat-menu-item (click)="addItemByMenu('UICopywriterStyleSettings')">UICopywriterStyleSettings</button>

          <button mat-menu-item (click)="addItemByMenu('UICopywriterStyleSettingsDrawer')">UICopywriterStyleSettingsDrawer</button>

          <button mat-menu-item (click)="addItemByMenu('UIAnalystUserInput')">UIAnalystUserInput</button>

          <button mat-menu-item (click)="addItemByMenu('UIAnalystSettingsDrawer')">UIAnalystSettingsDrawer</button>

          <button mat-menu-item (click)="addItemByMenu('UIAnalystWelcome')">UIAnalystWelcome</button>

          <button mat-menu-item (click)="addItemByMenu('UIAnalystDataMessage')">UIAnalystDataMessage</button>

          <button mat-menu-item (click)="addItemByMenu('UIAnalystSettings')">UIAnalystSettings</button>

          <button mat-menu-item (click)="addItemByMenu('FlowStep')">FlowStep</button>

          <button mat-menu-item (click)="addItemByMenu('FlowStepDocumentalist')">FlowStepDocumentalist</button>

          <button mat-menu-item (click)="addItemByMenu('FlowStepAnalyst')">FlowStepAnalyst</button>

          <button mat-menu-item (click)="addItemByMenu('FlowStepCopywriter')">FlowStepCopywriter</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      cdkDrag
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr mat-footer-row *matFooterRowDef="['addObject']"></tr>
  </table>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserImportStatsPageUiListComponent } from 'src/app/generated/dataPages/list/UserImportStats/UserImportStats.component';
import { UserImportStatsPageFormComponent } from 'src/app/generated/dataPages/form/UserImportStats/UserImportStats.component';
import { UserImportStatsPageUiListModule } from 'src/app/generated/dataPages/list/UserImportStats/UserImportStats.module';
import { UserImportStatsPageFormComponentModule } from 'src/app/generated/dataPages/form/UserImportStats/UserImportStats.module';

export class UserImportStatsRoutes {
  public static routes = [
    {
      path: 'UserImportStats/list',
      component: UserImportStatsPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserImportStats/edit/:id',
      component: UserImportStatsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserImportStats/new/:id',
      component: UserImportStatsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserImportStatsPageUiListModule, UserImportStatsPageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['urlBase']">
    <mat-form-field appearance="fill">
      <mat-label>urlBase</mat-label>
      <input matInput placeholder="" formControlName="urlBase" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['port']">
    <mat-form-field appearance="fill">
      <mat-label>port</mat-label>
      <input matInput placeholder="" formControlName="port" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['debug']"><mat-checkbox formControlName="debug">debug</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['licence_well-known']">
    <mat-form-field appearance="fill">
      <mat-label>licence_well-known</mat-label>
      <input matInput placeholder="" formControlName="licence_well-known" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['secretKey']">
    <mat-form-field appearance="fill">
      <mat-label>secretKey</mat-label>
      <input matInput placeholder="" formControlName="secretKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['publicAccess']">
    <mat-expansion-panel
      [expanded]="state['publicAccess']?._expanded"
      (closed)="propState('publicAccess_expanded', null)"
      (opened)="propState('publicAccess_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> publicAccess {{ fieldClassHumanName[formGroup._publicAccess?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_publicAccess']?.__class?.value">
          <app-ui-form-url_role id="publicAccess" *ngSwitchCase="'url_role'" [formGroup]="formGroup['_publicAccess']"></app-ui-form-url_role>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['bindIp']">
    <mat-form-field appearance="fill">
      <mat-label>bindIp</mat-label>
      <input matInput placeholder="" formControlName="bindIp" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['headers']">
    <app-ui-list-sub-doc-name_value id="headers" label="headers" [inputData]="formGroup['_headers']"> </app-ui-list-sub-doc-name_value>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['accessControlAllowOrigin']">
    <app-string-list label="accessControlAllowOrigin" [formArr]="formGroup['_accessControlAllowOrigin']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['confServiceUrl']">
    <mat-form-field appearance="fill">
      <mat-label>confServiceUrl</mat-label>
      <input matInput placeholder="" formControlName="confServiceUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['filePath']">
    <mat-form-field appearance="fill">
      <mat-label>chemin du répertoire de stockage</mat-label>
      <input matInput placeholder="" formControlName="filePath" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['bddServiceUrl']">
    <mat-form-field appearance="fill">
      <mat-label>url de la bd</mat-label>
      <input matInput placeholder="" formControlName="bddServiceUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['proxy']">
    <app-ui-list-sub-doc-name_value id="proxy" label="proxy" [inputData]="formGroup['_proxy']"> </app-ui-list-sub-doc-name_value>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

//import { FormAutoCompleteModule } from '../../chipList//.module';

import { AdaptivaiMailerFormComponent } from './AdaptivaiMailer.component';

@NgModule({
  declarations: [AdaptivaiMailerFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule],
  exports: [AdaptivaiMailerFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule]
})
export class AdaptivaiMailerFormModule {}

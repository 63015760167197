import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@leandredev/common-ng16/components';

import { DayjsModule } from '@leandredev/common-ng16';

import { FlowParamsFormModule } from '../../form/FlowParams/FlowParams.module';

import { FlowParamsUIListSubDocComponent } from './FlowParams.component';

import { FlowParamStringFormModule } from '../../form/FlowParamString/FlowParamString.module';

import { FlowParamTagFormModule } from '../../form/FlowParamTag/FlowParamTag.module';

import { FlowParamDocumentFormModule } from '../../form/FlowParamDocument/FlowParamDocument.module';

import { FlowParamNumberFormModule } from '../../form/FlowParamNumber/FlowParamNumber.module';

import { FlowParamBoolFormModule } from '../../form/FlowParamBool/FlowParamBool.module';

@NgModule({
  declarations: [FlowParamsUIListSubDocComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    DayjsModule,
    SharedModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,

    FlowParamsFormModule,

    FlowParamStringFormModule,

    FlowParamTagFormModule,

    FlowParamDocumentFormModule,

    FlowParamNumberFormModule,

    FlowParamBoolFormModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,
    DayjsModule,
    SharedModule,

    FlowParamsFormModule,

    FlowParamStringFormModule,

    FlowParamTagFormModule,

    FlowParamDocumentFormModule,

    FlowParamNumberFormModule,

    FlowParamBoolFormModule,

    FlowParamsUIListSubDocComponent
  ]
})
export class FlowParamsListSubDocModule {}

<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs current="Documents"></fury-breadcrumbs>
    <div class="uploadProgress" *ngIf="progressDisplay" @fade>
      <span>{{ progressMessage }}</span>
      <progress mode="indeterminate" [color]="progressColor" #documentAnalysProgress></progress>
    </div>
  </fury-page-layout-header>
  <fury-page-layout-content>
    <fury-list name="Documents" [columns]="columns" (filterChange)="onFilterChange($event)" (colsFilterChange)="colsFilterChangeListener($event)">
      <div class="actions">
        <button class="create" (click)="add()" type="button" mat-mini-fab color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element._id }}</td>
      </ng-container>

      <table mat-table [dataSource]="data">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>tags</th>
          <td mat-cell *matCellDef="let element">{{ tagNames(element.tags) }}</td>
        </ng-container>

        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef>Text</th>
          <td mat-cell *matCellDef="let element">{{ element.text }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editItem(row)"></tr>
      </table>

      <mat-paginator
        (page)="paginatorChange()"
        [pageSizeOptions]="pageSizeOptions"
        [length]="dataLength"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        showFirstLastButtons
      ></mat-paginator>
    </fury-list>
  </fury-page-layout-content>
</fury-page-layout>

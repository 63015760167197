<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creativeSettingsId']">
    <mat-form-field appearance="fill">
      <mat-label>creativeSettingsId</mat-label>
      <input matInput placeholder="" formControlName="creativeSettingsId" />
    </mat-form-field>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['llm']">
    <mat-form-field appearance="fill">
      <mat-label>llm</mat-label>
      <input matInput placeholder="" formControlName="llm" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lang']">
    <mat-form-field appearance="fill">
      <mat-label>lang</mat-label>

      <mat-select placeholder="Select" formControlName="lang">
        <mat-option value="fr">fr</mat-option>

        <mat-option value="en">en</mat-option>

        <mat-option value="es">es</mat-option>

        <mat-option value="pt">pt</mat-option>

        <mat-option value="it">it</mat-option>

        <mat-option value="de">de</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['uiMode']">
    <mat-form-field appearance="fill">
      <mat-label>uiMode</mat-label>

      <mat-select placeholder="Select" formControlName="uiMode">
        <mat-option value="dark">dark</mat-option>

        <mat-option value="light">light</mat-option>

        <mat-option value="system">system</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['copywriterSetting']">
    <app-copywriterstylerecord-form-auto-complete [formCtrl]="formGroup['_copywriterSetting']" placeholder="copywriterSetting">
    </app-copywriterstylerecord-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lastActiveSession']">
    <mat-form-field appearance="fill">
      <mat-label>lastActiveSession</mat-label>
      <input matInput placeholder="" formControlName="lastActiveSession" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['defaultDocspace']">
    <app-tag-form-auto-complete [formCtrl]="formGroup['_defaultDocspace']" placeholder="defaultDocspace"> </app-tag-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['defaultChunks']">
    <mat-form-field appearance="fill">
      <mat-label>defaultChunks</mat-label>
      <input matInput placeholder="" formControlName="defaultChunks" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmId']">
    <app-availableaimodels-form-auto-complete [formCtrl]="formGroup['_llmId']" placeholder="llmId"> </app-availableaimodels-form-auto-complete>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HtmlScapePipe } from './html-scape.pipe';

@NgModule({
  declarations: [HtmlScapePipe],
  imports: [CommonModule],
  exports: [HtmlScapePipe]
})
export class PipesModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { service_accessPageUiListComponent } from 'src/app/generated/dataPages/list/service_access/service_access.component';
import { service_accessPageFormComponent } from 'src/app/generated/dataPages/form/service_access/service_access.component';
import { service_accessPageUiListModule } from 'src/app/generated/dataPages/list/service_access/service_access.module';
import { service_accessPageFormComponentModule } from 'src/app/generated/dataPages/form/service_access/service_access.module';

export class service_accessRoutes {
  public static routes = [
    {
      path: 'service_access/list',
      component: service_accessPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'service_access/edit/:id',
      component: service_accessPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'service_access/new/:id',
      component: service_accessPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [service_accessPageUiListModule, service_accessPageFormComponentModule];
}

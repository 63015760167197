/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { CreativePageUiListComponent } from 'src/app/generated/dataPages/list/Creative/Creative.component';
import { CreativePageFormComponent } from 'src/app/generated/dataPages/form/Creative/Creative.component';
import { CreativePageUiListModule } from 'src/app/generated/dataPages/list/Creative/Creative.module';
import { CreativePageFormComponentModule } from 'src/app/generated/dataPages/form/Creative/Creative.module';

export class CreativeRoutes {
  public static routes = [
    {
      path: 'Creative/list',
      component: CreativePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Creative/edit/:id',
      component: CreativePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Creative/new/:id',
      component: CreativePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [CreativePageUiListModule, CreativePageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['content']">
    <mat-form-field appearance="fill">
      <mat-label>content</mat-label>
      <input matInput placeholder="" formControlName="content" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['suggestQuestions']">
    <app-string-list label="suggestQuestions" [formArr]="formGroup['_suggestQuestions']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['chunks']">
    <app-ui-list-sub-doc-documentchunk id="chunks" label="chunks" [inputData]="formGroup['_chunks']"> </app-ui-list-sub-doc-documentchunk>
  </div>
</div>

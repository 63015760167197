/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageAssistantDeletionPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageAssistantDeletion/UserMessageAssistantDeletion.component';
import { UserMessageAssistantDeletionPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageAssistantDeletion/UserMessageAssistantDeletion.component';
import { UserMessageAssistantDeletionPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageAssistantDeletion/UserMessageAssistantDeletion.module';
import { UserMessageAssistantDeletionPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageAssistantDeletion/UserMessageAssistantDeletion.module';

export class UserMessageAssistantDeletionRoutes {
  public static routes = [
    {
      path: 'UserMessageAssistantDeletion/list',
      component: UserMessageAssistantDeletionPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageAssistantDeletion/edit/:id',
      component: UserMessageAssistantDeletionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageAssistantDeletion/new/:id',
      component: UserMessageAssistantDeletionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageAssistantDeletionPageUiListModule, UserMessageAssistantDeletionPageFormComponentModule];
}

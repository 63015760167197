import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatExpansionModule } from '@angular/material/expansion';

import { ErrorObjListSubDocModule } from '../../listSubdoc/ErrorObj/ErrorObj.module';

import { LlmActionOutputFormModule } from '../LlmActionOutput/LlmActionOutput.module';

import { OpenAiPredictOutputFormModule } from '../OpenAiPredictOutput/OpenAiPredictOutput.module';

import { OpenAiChatOutputFormModule } from '../OpenAiChatOutput/OpenAiChatOutput.module';

import { DocumentalisteSearchOutputFormModule } from '../DocumentalisteSearchOutput/DocumentalisteSearchOutput.module';

import { DocumentChunkSearchOutputFormModule } from '../DocumentChunkSearchOutput/DocumentChunkSearchOutput.module';

import { CopywriterOutputFormModule } from '../CopywriterOutput/CopywriterOutput.module';

import { DeeplChatOutputFormModule } from '../DeeplChatOutput/DeeplChatOutput.module';

import { AdobeePdfToChuncksOutputFormModule } from '../AdobeePdfToChuncksOutput/AdobeePdfToChuncksOutput.module';

import { ExcelToChunksOutputFormModule } from '../ExcelToChunksOutput/ExcelToChunksOutput.module';

import { VisionToChuncksOutputFormModule } from '../VisionToChuncksOutput/VisionToChuncksOutput.module';

import { VisionImageDescriptionOutputFormModule } from '../VisionImageDescriptionOutput/VisionImageDescriptionOutput.module';

import { TxtToChunksOutputFormModule } from '../TxtToChunksOutput/TxtToChunksOutput.module';

import { AdobeCreatePdfOutputFormModule } from '../AdobeCreatePdfOutput/AdobeCreatePdfOutput.module';

import { PdfToImagesOutputFormModule } from '../PdfToImagesOutput/PdfToImagesOutput.module';

import { FileDataToChunksOutputFormModule } from '../FileDataToChunksOutput/FileDataToChunksOutput.module';

import { FileImageDataToChunksOutputFormModule } from '../FileImageDataToChunksOutput/FileImageDataToChunksOutput.module';

import { CreatePresentationOutputFormModule } from '../CreatePresentationOutput/CreatePresentationOutput.module';

import { LangChainFileToChunckOutputFormModule } from '../LangChainFileToChunckOutput/LangChainFileToChunckOutput.module';

import { CreativeOutputFormModule } from '../CreativeOutput/CreativeOutput.module';

import { DocumentDataDiscoveryOutputFormModule } from '../DocumentDataDiscoveryOutput/DocumentDataDiscoveryOutput.module';

import { UserAdminFormAutoCompleteModule } from '../../chipList/UserAdmin/UserAdmin.module';

import { AssistantSessionFormAutoCompleteModule } from '../../chipList/AssistantSession/AssistantSession.module';

import { LlmActionFormComponent } from './LlmAction.component';
@NgModule({
  declarations: [LlmActionFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatCheckboxModule,

    MatExpansionModule,

    ErrorObjListSubDocModule,

    LlmActionOutputFormModule,

    OpenAiPredictOutputFormModule,

    OpenAiChatOutputFormModule,

    DocumentalisteSearchOutputFormModule,

    DocumentChunkSearchOutputFormModule,

    CopywriterOutputFormModule,

    DeeplChatOutputFormModule,

    AdobeePdfToChuncksOutputFormModule,

    ExcelToChunksOutputFormModule,

    VisionToChuncksOutputFormModule,

    VisionImageDescriptionOutputFormModule,

    TxtToChunksOutputFormModule,

    AdobeCreatePdfOutputFormModule,

    PdfToImagesOutputFormModule,

    FileDataToChunksOutputFormModule,

    FileImageDataToChunksOutputFormModule,

    CreatePresentationOutputFormModule,

    LangChainFileToChunckOutputFormModule,

    CreativeOutputFormModule,

    DocumentDataDiscoveryOutputFormModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule
  ],
  exports: [
    LlmActionFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatCheckboxModule,

    MatExpansionModule,

    ErrorObjListSubDocModule,

    LlmActionOutputFormModule,

    OpenAiPredictOutputFormModule,

    OpenAiChatOutputFormModule,

    DocumentalisteSearchOutputFormModule,

    DocumentChunkSearchOutputFormModule,

    CopywriterOutputFormModule,

    DeeplChatOutputFormModule,

    AdobeePdfToChuncksOutputFormModule,

    ExcelToChunksOutputFormModule,

    VisionToChuncksOutputFormModule,

    VisionImageDescriptionOutputFormModule,

    TxtToChunksOutputFormModule,

    AdobeCreatePdfOutputFormModule,

    PdfToImagesOutputFormModule,

    FileDataToChunksOutputFormModule,

    FileImageDataToChunksOutputFormModule,

    CreatePresentationOutputFormModule,

    LangChainFileToChunckOutputFormModule,

    CreativeOutputFormModule,

    DocumentDataDiscoveryOutputFormModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule
  ]
})
export class LlmActionFormModule {}

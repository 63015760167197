/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { AvailableAiModelsPageUiListComponent } from 'src/app/generated/dataPages/list/AvailableAiModels/AvailableAiModels.component';
import { AvailableAiModelsPageFormComponent } from 'src/app/generated/dataPages/form/AvailableAiModels/AvailableAiModels.component';
import { AvailableAiModelsPageUiListModule } from 'src/app/generated/dataPages/list/AvailableAiModels/AvailableAiModels.module';
import { AvailableAiModelsPageFormComponentModule } from 'src/app/generated/dataPages/form/AvailableAiModels/AvailableAiModels.module';

export class AvailableAiModelsRoutes {
  public static routes = [
    {
      path: 'AvailableAiModels/list',
      component: AvailableAiModelsPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AvailableAiModels/edit/:id',
      component: AvailableAiModelsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AvailableAiModels/new/:id',
      component: AvailableAiModelsPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [AvailableAiModelsPageUiListModule, AvailableAiModelsPageFormComponentModule];
}

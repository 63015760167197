<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['content']">
    <mat-form-field appearance="fill">
      <mat-label>content</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="content"></textarea>
    </mat-form-field>
  </div>
</div>

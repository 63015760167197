<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="services">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>services</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <ng-container *ngFor="let val of row.services">
              <app-ui-ref #obj colName="service_access" [idRef]="val">
                {{ obj.data?.name || obj.data?.title || val }}
              </app-ui-ref>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="appName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Titre</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.appName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="apiUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>apiUrl</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.apiUrl }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

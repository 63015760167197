/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { LangChainFileToChunckPageUiListComponent } from 'src/app/generated/dataPages/list/LangChainFileToChunck/LangChainFileToChunck.component';
import { LangChainFileToChunckPageFormComponent } from 'src/app/generated/dataPages/form/LangChainFileToChunck/LangChainFileToChunck.component';
import { LangChainFileToChunckPageUiListModule } from 'src/app/generated/dataPages/list/LangChainFileToChunck/LangChainFileToChunck.module';
import { LangChainFileToChunckPageFormComponentModule } from 'src/app/generated/dataPages/form/LangChainFileToChunck/LangChainFileToChunck.module';

export class LangChainFileToChunckRoutes {
  public static routes = [
    {
      path: 'LangChainFileToChunck/list',
      component: LangChainFileToChunckPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'LangChainFileToChunck/edit/:id',
      component: LangChainFileToChunckPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'LangChainFileToChunck/new/:id',
      component: LangChainFileToChunckPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [LangChainFileToChunckPageUiListModule, LangChainFileToChunckPageFormComponentModule];
}

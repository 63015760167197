/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-userclientgroup',
  templateUrl: './UserClientGroup.component.html',
  styleUrls: ['./UserClientGroup.component.scss'],
  providers: [UIComponentState]
})
export class UserClientGroupUiListComponent extends UiList<interfaces.IUserClientGroup> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      externalId: 'string',
      enabledTags: 'objectid',
      disabledTags: 'objectid',
      docSpaceRights: 'subdoc',
      creator: 'objectid'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'externalId', 'enabledTags', 'disabledTags'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

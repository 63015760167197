<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['required']"><mat-checkbox formControlName="required">required</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['isArrayOf']"><mat-checkbox formControlName="isArrayOf">isArrayOf</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['value']"><mat-checkbox formControlName="value">value</mat-checkbox><br /></div>
</div>

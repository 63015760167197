/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { AssistantSessionPageUiListComponent } from 'src/app/generated/dataPages/list/AssistantSession/AssistantSession.component';
import { AssistantSessionPageFormComponent } from 'src/app/generated/dataPages/form/AssistantSession/AssistantSession.component';
import { AssistantSessionPageUiListModule } from 'src/app/generated/dataPages/list/AssistantSession/AssistantSession.module';
import { AssistantSessionPageFormComponentModule } from 'src/app/generated/dataPages/form/AssistantSession/AssistantSession.module';

export class AssistantSessionRoutes {
  public static routes = [
    {
      path: 'AssistantSession/list',
      component: AssistantSessionPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AssistantSession/edit/:id',
      component: AssistantSessionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AssistantSession/new/:id',
      component: AssistantSessionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [AssistantSessionPageUiListModule, AssistantSessionPageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentAnalystDiscoveryPageUiListComponent } from 'src/app/generated/dataPages/list/DocumentAnalystDiscovery/DocumentAnalystDiscovery.component';
import { DocumentAnalystDiscoveryPageFormComponent } from 'src/app/generated/dataPages/form/DocumentAnalystDiscovery/DocumentAnalystDiscovery.component';
import { DocumentAnalystDiscoveryPageUiListModule } from 'src/app/generated/dataPages/list/DocumentAnalystDiscovery/DocumentAnalystDiscovery.module';
import { DocumentAnalystDiscoveryPageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentAnalystDiscovery/DocumentAnalystDiscovery.module';

export class DocumentAnalystDiscoveryRoutes {
  public static routes = [
    {
      path: 'DocumentAnalystDiscovery/list',
      component: DocumentAnalystDiscoveryPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentAnalystDiscovery/edit/:id',
      component: DocumentAnalystDiscoveryPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentAnalystDiscovery/new/:id',
      component: DocumentAnalystDiscoveryPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentAnalystDiscoveryPageUiListModule, DocumentAnalystDiscoveryPageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { OpenAiPredictPageUiListComponent } from 'src/app/generated/dataPages/list/OpenAiPredict/OpenAiPredict.component';
import { OpenAiPredictPageFormComponent } from 'src/app/generated/dataPages/form/OpenAiPredict/OpenAiPredict.component';
import { OpenAiPredictPageUiListModule } from 'src/app/generated/dataPages/list/OpenAiPredict/OpenAiPredict.module';
import { OpenAiPredictPageFormComponentModule } from 'src/app/generated/dataPages/form/OpenAiPredict/OpenAiPredict.module';

export class OpenAiPredictRoutes {
  public static routes = [
    {
      path: 'OpenAiPredict/list',
      component: OpenAiPredictPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'OpenAiPredict/edit/:id',
      component: OpenAiPredictPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'OpenAiPredict/new/:id',
      component: OpenAiPredictPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [OpenAiPredictPageUiListModule, OpenAiPredictPageFormComponentModule];
}

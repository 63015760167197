import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { ConfirmationDialogComponent } from '../app-specific/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class FormChangeService {
  hasChangesPenging = false;

  constructor(private dialog: MatDialog) {}

  confirm(event: Event): Observable<boolean> | boolean {
    if (this.hasChangesPenging) {
      if (event) return window.confirm();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {});

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return dialogRef.afterClosed().pipe(
        map((value: boolean) => {
          if (value) this.hasChangesPenging = false;
          return value;
        })
      ) as Observable<boolean>;
    }
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['firstName']">
    <mat-form-field appearance="fill">
      <mat-label>firstName</mat-label>
      <input matInput placeholder="" formControlName="firstName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lastName']">
    <mat-form-field appearance="fill">
      <mat-label>lastName</mat-label>
      <input matInput placeholder="" formControlName="lastName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['email']">
    <mat-form-field appearance="fill">
      <mat-label>email</mat-label>
      <input matInput placeholder="" formControlName="email" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['password']">
    <mat-form-field appearance="fill">
      <mat-label>password</mat-label>
      <input matInput placeholder="" formControlName="password" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" [readonly]="true" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPasswordKey']">
    <mat-form-field appearance="fill">
      <mat-label>resetPasswordKey</mat-label>
      <input matInput placeholder="" formControlName="resetPasswordKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPasswordEndDate']">
    <mat-form-field appearance="fill">
      <mat-label>resetPasswordEndDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myresetPasswordEndDate" formControlName="resetPasswordEndDate" />
      <mat-datepicker-toggle matSuffix [for]="myresetPasswordEndDate"></mat-datepicker-toggle>
      <mat-datepicker #myresetPasswordEndDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isActivated']"><mat-checkbox formControlName="isActivated">isActivated</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['userConf']">
    <mat-expansion-panel
      [expanded]="state['userConf']?._expanded"
      (closed)="propState('userConf_expanded', null)"
      (opened)="propState('userConf_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> userConf {{ fieldClassHumanName[formGroup._userConf?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_userConf']?.__class?.value">
          <app-ui-form-userconf id="userConf" *ngSwitchCase="'UserConf'" [formGroup]="formGroup['_userConf']"></app-ui-form-userconf>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['groups']">
    <app-userclientgroup-form-auto-complete [formArr]="formGroup['_groups']" placeholder="groups"> </app-userclientgroup-form-auto-complete>
  </div>
  <div class="form-item" *ngIf="!unVisibleComp['categoryEditor']">
    <mat-checkbox formControlName="categoryEditor">categoryEditor</mat-checkbox><br />
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tagEditor']"><mat-checkbox formControlName="tagEditor">tagEditor</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['documentUpload']">
    <mat-checkbox formControlName="documentUpload">documentUpload</mat-checkbox><br />
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['docSpaceRights']">
    <app-ui-list-sub-doc-docspaceright id="docSpaceRights" label="docSpaceRights" [inputData]="formGroup['_docSpaceRights']">
    </app-ui-list-sub-doc-docspaceright>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentalisteSearchPageUiListComponent } from 'src/app/generated/dataPages/list/DocumentalisteSearch/DocumentalisteSearch.component';
import { DocumentalisteSearchPageFormComponent } from 'src/app/generated/dataPages/form/DocumentalisteSearch/DocumentalisteSearch.component';
import { DocumentalisteSearchPageUiListModule } from 'src/app/generated/dataPages/list/DocumentalisteSearch/DocumentalisteSearch.module';
import { DocumentalisteSearchPageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentalisteSearch/DocumentalisteSearch.module';

export class DocumentalisteSearchRoutes {
  public static routes = [
    {
      path: 'DocumentalisteSearch/list',
      component: DocumentalisteSearchPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentalisteSearch/edit/:id',
      component: DocumentalisteSearchPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentalisteSearch/new/:id',
      component: DocumentalisteSearchPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentalisteSearchPageUiListModule, DocumentalisteSearchPageFormComponentModule];
}

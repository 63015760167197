/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { CopywriterStyleRecordPageUiListComponent } from 'src/app/generated/dataPages/list/CopywriterStyleRecord/CopywriterStyleRecord.component';
import { CopywriterStyleRecordPageFormComponent } from 'src/app/generated/dataPages/form/CopywriterStyleRecord/CopywriterStyleRecord.component';
import { CopywriterStyleRecordPageUiListModule } from 'src/app/generated/dataPages/list/CopywriterStyleRecord/CopywriterStyleRecord.module';
import { CopywriterStyleRecordPageFormComponentModule } from 'src/app/generated/dataPages/form/CopywriterStyleRecord/CopywriterStyleRecord.module';

export class CopywriterStyleRecordRoutes {
  public static routes = [
    {
      path: 'CopywriterStyleRecord/list',
      component: CopywriterStyleRecordPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'CopywriterStyleRecord/edit/:id',
      component: CopywriterStyleRecordPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'CopywriterStyleRecord/new/:id',
      component: CopywriterStyleRecordPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [CopywriterStyleRecordPageUiListModule, CopywriterStyleRecordPageFormComponentModule];
}

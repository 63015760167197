/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { application_configurationPageUiListComponent } from 'src/app/generated/dataPages/list/application_configuration/application_configuration.component';
import { application_configurationPageFormComponent } from 'src/app/generated/dataPages/form/application_configuration/application_configuration.component';
import { application_configurationPageUiListModule } from 'src/app/generated/dataPages/list/application_configuration/application_configuration.module';
import { application_configurationPageFormComponentModule } from 'src/app/generated/dataPages/form/application_configuration/application_configuration.module';

export class application_configurationRoutes {
  public static routes = [
    {
      path: 'application_configuration/list',
      component: application_configurationPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'application_configuration/edit/:id',
      component: application_configurationPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'application_configuration/new/:id',
      component: application_configurationPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [application_configurationPageUiListModule, application_configurationPageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserClientInvitationPageUiListComponent } from 'src/app/generated/dataPages/list/UserClientInvitation/UserClientInvitation.component';
import { UserClientInvitationPageFormComponent } from 'src/app/generated/dataPages/form/UserClientInvitation/UserClientInvitation.component';
import { UserClientInvitationPageUiListModule } from 'src/app/generated/dataPages/list/UserClientInvitation/UserClientInvitation.module';
import { UserClientInvitationPageFormComponentModule } from 'src/app/generated/dataPages/form/UserClientInvitation/UserClientInvitation.module';

export class UserClientInvitationRoutes {
  public static routes = [
    {
      path: 'UserClientInvitation/list',
      component: UserClientInvitationPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClientInvitation/edit/:id',
      component: UserClientInvitationPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserClientInvitation/new/:id',
      component: UserClientInvitationPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserClientInvitationPageUiListModule, UserClientInvitationPageFormComponentModule];
}

import { Injectable } from '@angular/core';

import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { RunTimeDataService } from '@leandredev/common-ng16/infraService';

@Injectable()
export class SidenavNavigationData {
  constructor(protected runtimeDataProvider: RunTimeDataService) {
    this.runtimeDataProvider.getObservableStore('ConfService').subscribe((data) => {
      if (data && data.superAdmin) {
        this.navigationAppItems = [
          {
            name: 'Application Admin',
            position: 10,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/stats/',
            name: 'Plateform usage',
            icon: 'bar_chart',
            position: 21,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/chat/',
            name: 'Chat',
            icon: 'dashboard',
            position: 20,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/Assistant/list/',
            name: 'Assistants',
            icon: 'apps',
            position: 21,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/AvailableAiModels/list/',
            name: 'Models',
            icon: 'psychology',
            position: 22,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/CopywriterStyleRecord/list/',
            name: 'Copywriter Styles',
            icon: 'history_edu',
            position: 23,
            pathMatchExact: true
          },
          {
            name: 'Documents Admin',
            position: 30,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/data/ImportingFile/list/',
            name: 'Import de documents',
            icon: 'dashboard',
            position: 40,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/Tag/list',
            name: 'Tags de documents',
            icon: 'feedback',
            position: 50,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/TagType/list',
            name: 'Type des Tags',
            icon: 'bookmark',
            position: 60,
            pathMatchExact: true
          },
          {
            name: 'Utilisateurs',
            position: 70,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/data/UserAdmin/list',
            name: 'Utilisateurs Admin',
            icon: 'people',
            position: 80,
            pathMatchExact: true
          },

          {
            routeOrFunction: '/data/UserClient/list',
            name: 'Clients app',
            icon: 'person',
            position: 90,
            pathMatchExact: true
          },

          {
            routeOrFunction: '/data/UserClientGroup/list',
            // eslint-disable-next-line @typescript-eslint/quotes
            name: "Group d'utilsateurs",
            icon: 'group_work',
            position: 100,
            pathMatchExact: true
          },
          {
            name: 'Super Admin Debug',
            position: 110,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/data/LlmAction/list',
            name: 'LLM Messages',
            position: 110,
            icon: 'all_inbox',
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/LightDocumentVector/list',
            name: 'Imports en cours',
            position: 120,
            icon: 'upload_file',
            pathMatchExact: true
          }
        ];
      } else {
        this.navigationAppItems = [
          {
            name: 'Application Admin',
            position: 10,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/stats/',
            name: 'Plateform usage',
            icon: 'bar_chart',
            position: 21,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/CopywriterStyleRecord/list/',
            name: 'Copywriter Styles',
            icon: 'history_edu',
            position: 22,
            pathMatchExact: true
          },
          {
            name: 'Documents Admin',
            position: 30,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/data/Tag/list',
            name: 'Tags de documents',
            icon: 'feedback',
            position: 50,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/TagType/list',
            name: 'Type des Tags',
            icon: 'bookmark',
            position: 60,
            pathMatchExact: true
          },
          {
            name: 'Utilisateurs',
            position: 70,
            type: 'subheading',
            customClass: 'first-subheading'
          },
          {
            routeOrFunction: '/data/UserClient/list',
            name: 'Clients app',
            icon: 'person',
            position: 90,
            pathMatchExact: true
          },
          {
            routeOrFunction: '/data/UserClientGroup/list',
            // eslint-disable-next-line @typescript-eslint/quotes
            name: "Group d'utilsateurs",
            icon: 'group_work',
            position: 100,
            pathMatchExact: true
          }
        ];
      }
    });
  }

  openQuickPanel(): void {
    return;
  }

  public navigationAppItems: SidenavItem[] = [];
}

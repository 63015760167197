/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { OpenAiChatPageUiListComponent } from 'src/app/generated/dataPages/list/OpenAiChat/OpenAiChat.component';
import { OpenAiChatPageFormComponent } from 'src/app/generated/dataPages/form/OpenAiChat/OpenAiChat.component';
import { OpenAiChatPageUiListModule } from 'src/app/generated/dataPages/list/OpenAiChat/OpenAiChat.module';
import { OpenAiChatPageFormComponentModule } from 'src/app/generated/dataPages/form/OpenAiChat/OpenAiChat.module';

export class OpenAiChatRoutes {
  public static routes = [
    {
      path: 'OpenAiChat/list',
      component: OpenAiChatPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'OpenAiChat/edit/:id',
      component: OpenAiChatPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'OpenAiChat/new/:id',
      component: OpenAiChatPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [OpenAiChatPageUiListModule, OpenAiChatPageFormComponentModule];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { service_accessFormAutoCompleteModule } from '../../chipList/service_access/service_access.module';

import { application_configuration_adaptivai_adminFormComponent } from './application_configuration_adaptivai_admin.component';
@NgModule({
  declarations: [application_configuration_adaptivai_adminFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    service_accessFormAutoCompleteModule
  ],
  exports: [
    application_configuration_adaptivai_adminFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    service_accessFormAutoCompleteModule
  ]
})
export class application_configuration_adaptivai_adminFormModule {}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageInfoPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageInfo/UserMessageInfo.component';
import { UserMessageInfoPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageInfo/UserMessageInfo.component';
import { UserMessageInfoPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageInfo/UserMessageInfo.module';
import { UserMessageInfoPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageInfo/UserMessageInfo.module';

export class UserMessageInfoRoutes {
  public static routes = [
    {
      path: 'UserMessageInfo/list',
      component: UserMessageInfoPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageInfo/edit/:id',
      component: UserMessageInfoPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageInfo/new/:id',
      component: UserMessageInfoPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageInfoPageUiListModule, UserMessageInfoPageFormComponentModule];
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, Self, Inject } from '@angular/core';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { FormControlBase } from '@leandredev/common-ng16/form';

import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiForm } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-form-usermessageassistant',
  templateUrl: './UserMessageAssistant.component.html',
  styleUrls: ['./UserMessageAssistant.component.scss'],
  providers: [UIComponentState]
})
export class UserMessageAssistantFormComponent extends UiForm<form.UserMessageAssistant> implements OnInit {
  @Input() override id = '';
  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIComponentChatUserInput'] = 'UIComponentChatUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIDocumentalistUserInput'] = 'UIDocumentalistUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterUserInput'] = 'UICopywriterUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICreativeUserInput'] = 'UICreativeUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIAnalystUserInput'] = 'UIAnalystUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIComponent'] = 'UIComponent';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIComponentChatUserInput'] = 'UIComponentChatUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIChatMessage'] = 'UIChatMessage';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIChatWelcome'] = 'UIChatWelcome';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIDocumentalistWelcome'] = 'UIDocumentalistWelcome';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIDocumentalistSettings'] = 'UIDocumentalistSettings';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterWelcome'] = 'UICopywriterWelcome';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIDocumentalistUserInput'] = 'UIDocumentalistUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterSettings'] = 'UICopywriterSettings';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIDocumentalistSettingsDrawer'] = 'UIDocumentalistSettingsDrawer';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterUserInput'] = 'UICopywriterUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterSettingsDrawer'] = 'UICopywriterSettingsDrawer';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIErrorMessage'] = 'UIErrorMessage';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICreativeWelcome'] = 'UICreativeWelcome';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICreativeSettingsDrawer'] = 'UICreativeSettingsDrawer';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICreativeSettings'] = 'UICreativeSettings';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICreativeUserInput'] = 'UICreativeUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterStyleSettings'] = 'UICopywriterStyleSettings';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UICopywriterStyleSettingsDrawer'] = 'UICopywriterStyleSettingsDrawer';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIAnalystUserInput'] = 'UIAnalystUserInput';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIAnalystSettingsDrawer'] = 'UIAnalystSettingsDrawer';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIAnalystWelcome'] = 'UIAnalystWelcome';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIAnalystDataMessage'] = 'UIAnalystDataMessage';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['UIAnalystSettings'] = 'UIAnalystSettings';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['FlowStep'] = 'FlowStep';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['FlowStepDocumentalist'] = 'FlowStepDocumentalist';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['FlowStepAnalyst'] = 'FlowStepAnalyst';

    // eslint-disable-next-line @typescript-eslint/quotes
    this.fieldClassHumanName['FlowStepCopywriter'] = 'FlowStepCopywriter';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
    Object.assign(this.state, this.uIComponentState.state);
    Object.assign(this.uIComponentState.state, this.state);
    this.state = this.uIComponentState.state;
  }

  public fieldClassHumanName: { [key: string]: string } = {};

  public classChange(value: string, control: FormControlBase): void {
    let oldVal: any;
    oldVal = control.value;
    if (!oldVal) {
      oldVal = {};
    }
    oldVal._class = value;
    control.patchValue(oldVal);
  }
}

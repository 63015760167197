/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Component, OnInit, Input, Self } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiListSubDoc } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-list-sub-doc-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [UIComponentState],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class fieldUIListSubDocComponent extends UiListSubDoc<form.field> implements OnInit {
  @Input() override id = '';

  public selectedClass = 'Field_modelid';

  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);
    this.columnsToDisplay = ['drag', '_class', 'name', 'description', 'isArrayOf', 'actions'];
    this.classChange(this.selectedClass);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.uIComponentState.id = this.id;
  }

  addItemByMenu(value: string): void {
    this.classChange(value);
    this.addItem();
  }
  override addItem(): void {
    this.inputData.push(this.newElement);
    this.slctTable.renderRows();
    this.expandedElement = this.newElement;
    this.newElement = new this.FormClass();
  }
  override drop(event: CdkDragDrop<any>): void {
    moveItemInArray(this.inputData.controls, event.previousIndex, event.currentIndex);
    this.slctTable.renderRows();
  }

  public classToHumanName: { [key: string]: string } = {
    // eslint-disable-next-line @typescript-eslint/quotes
    field: 'field',

    Field_modelid: 'modelid',

    Field_boolean: 'boolean',

    Field_date: 'date',

    Field_number: 'number',

    Field_htmltexte: 'htmltexte',

    Field_objectid: 'objectid',

    Field_object: 'object',

    Field_texte: 'texte',

    Field_subdoc: 'subdoc',

    Field_string: 'string',

    Field_password: 'password'
  };
  public classChange(value: string): void {
    let oldVal: any;
    if (this.newElement) {
      oldVal = this.newElement.value;
    }
    switch (value) {
      case 'field':
        this.FormClass = form.field;
        break;

      case 'Field_modelid':
        this.FormClass = form.Field_modelid;
        break;

      case 'Field_boolean':
        this.FormClass = form.Field_boolean;
        break;

      case 'Field_date':
        this.FormClass = form.Field_date;
        break;

      case 'Field_number':
        this.FormClass = form.Field_number;
        break;

      case 'Field_htmltexte':
        this.FormClass = form.Field_htmltexte;
        break;

      case 'Field_objectid':
        this.FormClass = form.Field_objectid;
        break;

      case 'Field_object':
        this.FormClass = form.Field_object;
        break;

      case 'Field_texte':
        this.FormClass = form.Field_texte;
        break;

      case 'Field_subdoc':
        this.FormClass = form.Field_subdoc;
        break;

      case 'Field_string':
        this.FormClass = form.Field_string;
        break;

      case 'Field_password':
        this.FormClass = form.Field_password;
        break;

      default:
        this.FormClass = form.field;
        break;
    }
    this.newElement = new this.FormClass();
    if (oldVal) {
      delete oldVal._class;
      this.newElement.patchValue(oldVal);
    }
    this.selectedClass = value;
  }
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageAssistantPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageAssistant/UserMessageAssistant.component';
import { UserMessageAssistantPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageAssistant/UserMessageAssistant.component';
import { UserMessageAssistantPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageAssistant/UserMessageAssistant.module';
import { UserMessageAssistantPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageAssistant/UserMessageAssistant.module';

export class UserMessageAssistantRoutes {
  public static routes = [
    {
      path: 'UserMessageAssistant/list',
      component: UserMessageAssistantPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageAssistant/edit/:id',
      component: UserMessageAssistantPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageAssistant/new/:id',
      component: UserMessageAssistantPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageAssistantPageUiListModule, UserMessageAssistantPageFormComponentModule];
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, HostListener, Inject, OnInit, Self } from '@angular/core';
import { IHttpResult, interfaces } from '@leandredev/adaptivai-common-lib';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/dataService/dataService.service';
import { ApiHelper } from '@leandredev/common-ng16/httpClient';
import { Observable, map, switchMap } from 'rxjs';
import { FormChangeService } from 'src/app/dataService/form-change.service';
import { ObjectId } from '@leandredev/common-ng16/form';
import { FormComponent, PageTitleService } from '@leandredev/common-ng16/components';
import { StatesService, UIComponentState } from '@leandredev/common-ng16/infraService';
@Component({
  selector: 'app-adobeepdftochuncks-form',
  templateUrl: './AdobeePdfToChuncks.component.html',
  styleUrls: ['./AdobeePdfToChuncks.component.scss'],
  providers: [UIComponentState]
})
export class AdobeePdfToChuncksPageFormComponent extends FormComponent<interfaces.IAdobeePdfToChuncks> implements OnInit {
  @HostListener('window:leave')
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate: (event: Event) => Observable<boolean> | boolean = (event) => this.formChangeService.confirm(event);

  private _gap = 16;
  public gap = `px`;

  public formGroup: form.AdobeePdfToChuncks = new form.AdobeePdfToChuncks();

  public currentEditName: string;

  constructor(
    public route: Router,
    protected formChangeService: FormChangeService,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    public statesService: StatesService,
    public dialog: MatDialog,
    protected titleService: PageTitleService,
    public snackBar: MatSnackBar,
    public _loc: Location,
    private dataService: DataService
  ) {
    super(
      route,
      // 'AdobeePdfToChuncks',
      activeRoute,
      uIComponentState,
      statesService,
      dialog,
      snackBar,
      _loc
    );

    this.titleService.setPageTitle(`AdobeePdfToChuncks`);

    this.httpServiceBase = dataService.api.collections.AdobeePdfToChuncks;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(this.formGroup.valueChanges.pipe(map(() => (this.formChangeService.hasChangesPenging = this.formGroup.dirty))).subscribe());
  }

  public isValid(): boolean {
    return !this.formGroup.invalid;
  }

  private _update(val: interfaces.IAdobeePdfToChuncks): Observable<interfaces.IAdobeePdfToChuncks> {
    return this.httpServiceBase.put(val).pipe(
      map((result) => {
        if (result.code === 200) {
          this.snackBar.open('Document enregistré !', null, {
            duration: 2000
          });

          this.formGroup.markAsPristine();
          this.initData();

          return result.response[0];
        } else {
          this.snackBar.open(result.message, null, {
            duration: 2000
          });
        }
      })
    );
  }

  override update(val: interfaces.IAdobeePdfToChuncks): void {
    if (this.isValid()) {
      const sub = this._update(val).subscribe({
        error: (error) => {
          console.error(error);
        }
      });

      this.subscription.add(sub);
    } else {
      this.snackBar.open('Document invalide !', null, {
        duration: 2000
      });
    }
  }

  private _duplicate(): Observable<void> {
    const rawValue = this.formGroup.getRawValue();

    rawValue._id = ObjectId.stringObjectId();
    if (rawValue?.name) {
      rawValue.name = rawValue.name + ' (Duplicate)';
    }

    if (rawValue.isSealed === undefined || rawValue.isSealed === null || rawValue.isSealed == true) rawValue.isSealed = false;
    if (rawValue.isFrozen == undefined || rawValue.isFrozen == null || rawValue.isFrozen == true) rawValue.isFrozen = false;

    return this.httpServiceBase
      .post(rawValue)
      .pipe(
        ApiHelper.resultToObj('Duplication Error'),
        switchMap((obj) => {
          if (obj) {
            return this.activeRoute.url;
          }
        })
      )
      .pipe(
        map((url) => {
          if (url.length > 0) {
            url[url.length - 1].path = rawValue._id;
          }

          const urlPath = url.map((u) => u.path);

          urlPath.unshift('data');
          urlPath.unshift('/');

          this.snackBar.open('Produit dupliqué avec succès', '×', {
            panelClass: 'success',
            verticalPosition: 'top',
            duration: 3000
          });

          void this.router.navigate(['/']).then(() => {
            return this.router.navigate(urlPath);
          });
        })
      );
  }

  public duplicate(): void {
    console.warn('@WARN: New duplicate method');
    this._duplicate().subscribe();
  }

  protected processDataResponse(id: string, dataRes: IHttpResult<interfaces.IAdobeePdfToChuncks>): void {
    super.processDataResponse(id, dataRes);
    if (dataRes.code === 200) {
      if (dataRes.response[0] && dataRes.response[0]['name'] != undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.currentEditName = dataRes.response[0]['name'];
      } else {
        this.currentEditName = 'Nouveau AdobeePdfToChuncks';
      }
    }
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isArrayOf']"><mat-checkbox formControlName="isArrayOf">isArrayOf</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['required']"><mat-checkbox formControlName="required">required</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['unique']"><mat-checkbox formControlName="unique">unique</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['index']"><mat-checkbox formControlName="index">index</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['default']"><mat-checkbox formControlName="default">default</mat-checkbox><br /></div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-service_fso',
  templateUrl: './service_fso.component.html',
  styleUrls: ['./service_fso.component.scss'],
  providers: [UIComponentState]
})
export class service_fsoUiListComponent extends UiList<interfaces.Iservice_fso> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      urlBase: 'string',
      port: 'number',
      debug: 'boolean',
      'licence_well-known': 'string',
      secretKey: 'string',
      publicAccess: 'subdoc',
      bindIp: 'string',
      headers: 'subdoc',
      accessControlAllowOrigin: 'string',
      confServiceUrl: 'string',
      filePath: 'string',
      bddServiceUrl: 'string',
      proxy: 'subdoc'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'urlBase', 'port', 'debug'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

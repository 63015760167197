<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['lang']">
    <mat-form-field appearance="fill">
      <mat-label>lang</mat-label>
      <input matInput placeholder="" formControlName="lang" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['test']">
    <mat-form-field appearance="fill">
      <mat-label>test</mat-label>
      <input matInput placeholder="" formControlName="test" />
    </mat-form-field>
  </div>
</div>

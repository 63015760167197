/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-file',
  templateUrl: './File.component.html',
  styleUrls: ['./File.component.scss'],
  providers: [UIComponentState]
})
export class FileUiListComponent extends UiList<interfaces.IFile> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      originalname: 'string',
      encoding: 'string',
      mimetype: 'string',
      size: 'number',
      path: 'string',
      createdAt: 'date',
      updatedAt: 'date',
      downloadUrl: 'string',
      accessKeys: 'string'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'originalname', 'encoding', 'mimetype'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="name"></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['properties']">
    <app-ui-list-sub-doc-sheetfield id="properties" label="properties" [inputData]="formGroup['_properties']"> </app-ui-list-sub-doc-sheetfield>
  </div>
</div>

<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>firstName</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.firstName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>lastName</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.lastName }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.email }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>password</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.password }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="resetPasswordKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>resetPasswordKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.resetPasswordKey }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="resetPasswordEndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>resetPasswordEndDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.resetPasswordEndDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userConf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userConf</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.userConf | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="superAdmin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>superAdmin</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.superAdmin }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="apiKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>apiKey</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.apiKey }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

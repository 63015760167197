/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentDataAnalystOutputPageUiListComponent } from 'src/app/generated/dataPages/list/DocumentDataAnalystOutput/DocumentDataAnalystOutput.component';
import { DocumentDataAnalystOutputPageFormComponent } from 'src/app/generated/dataPages/form/DocumentDataAnalystOutput/DocumentDataAnalystOutput.component';
import { DocumentDataAnalystOutputPageUiListModule } from 'src/app/generated/dataPages/list/DocumentDataAnalystOutput/DocumentDataAnalystOutput.module';
import { DocumentDataAnalystOutputPageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentDataAnalystOutput/DocumentDataAnalystOutput.module';

export class DocumentDataAnalystOutputRoutes {
  public static routes = [
    {
      path: 'DocumentDataAnalystOutput/list',
      component: DocumentDataAnalystOutputPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentDataAnalystOutput/edit/:id',
      component: DocumentDataAnalystOutputPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentDataAnalystOutput/new/:id',
      component: DocumentDataAnalystOutputPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentDataAnalystOutputPageUiListModule, DocumentDataAnalystOutputPageFormComponentModule];
}

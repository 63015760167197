<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <input matInput placeholder="" formControlName="message" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['stack']">
    <mat-form-field appearance="fill">
      <mat-label>stack</mat-label>
      <input matInput placeholder="" formControlName="stack" />
    </mat-form-field>
  </div>
</div>

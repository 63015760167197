<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['copywriterSettingsId']">
    <mat-form-field appearance="fill">
      <mat-label>copywriterSettingsId</mat-label>
      <input matInput placeholder="" formControlName="copywriterSettingsId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tags']">
    <app-string-list label="tags" [formArr]="formGroup['_tags']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['answerFormat']">
    <mat-form-field appearance="fill">
      <mat-label>answerFormat</mat-label>
      <input matInput placeholder="" formControlName="answerFormat" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['docQuery']">
    <mat-form-field appearance="fill">
      <mat-label>docQuery</mat-label>
      <input matInput placeholder="" formControlName="docQuery" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['documents']">
    <app-string-list label="documents" [formArr]="formGroup['_documents']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['copywriterStyleSettingsId']">
    <mat-form-field appearance="fill">
      <mat-label>copywriterStyleSettingsId</mat-label>
      <input matInput placeholder="" formControlName="copywriterStyleSettingsId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['framework']">
    <mat-form-field appearance="fill">
      <mat-label>framework</mat-label>
      <input matInput placeholder="" formControlName="framework" />
    </mat-form-field>
  </div>
</div>

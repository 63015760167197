/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { application_configuration_adaptivai_adminPageUiListComponent } from 'src/app/generated/dataPages/list/application_configuration_adaptivai_admin/application_configuration_adaptivai_admin.component';
import { application_configuration_adaptivai_adminPageFormComponent } from 'src/app/generated/dataPages/form/application_configuration_adaptivai_admin/application_configuration_adaptivai_admin.component';
import { application_configuration_adaptivai_adminPageUiListModule } from 'src/app/generated/dataPages/list/application_configuration_adaptivai_admin/application_configuration_adaptivai_admin.module';
import { application_configuration_adaptivai_adminPageFormComponentModule } from 'src/app/generated/dataPages/form/application_configuration_adaptivai_admin/application_configuration_adaptivai_admin.module';

export class application_configuration_adaptivai_adminRoutes {
  public static routes = [
    {
      path: 'application_configuration_adaptivai_admin/list',
      component: application_configuration_adaptivai_adminPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'application_configuration_adaptivai_admin/edit/:id',
      component: application_configuration_adaptivai_adminPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'application_configuration_adaptivai_admin/new/:id',
      component: application_configuration_adaptivai_adminPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [application_configuration_adaptivai_adminPageUiListModule, application_configuration_adaptivai_adminPageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { adaptivai_admin_apiPageUiListComponent } from 'src/app/generated/dataPages/list/adaptivai_admin_api/adaptivai_admin_api.component';
import { adaptivai_admin_apiPageFormComponent } from 'src/app/generated/dataPages/form/adaptivai_admin_api/adaptivai_admin_api.component';
import { adaptivai_admin_apiPageUiListModule } from 'src/app/generated/dataPages/list/adaptivai_admin_api/adaptivai_admin_api.module';
import { adaptivai_admin_apiPageFormComponentModule } from 'src/app/generated/dataPages/form/adaptivai_admin_api/adaptivai_admin_api.module';

export class adaptivai_admin_apiRoutes {
  public static routes = [
    {
      path: 'adaptivai_admin_api/list',
      component: adaptivai_admin_apiPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'adaptivai_admin_api/edit/:id',
      component: adaptivai_admin_apiPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'adaptivai_admin_api/new/:id',
      component: adaptivai_admin_apiPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [adaptivai_admin_apiPageUiListModule, adaptivai_admin_apiPageFormComponentModule];
}

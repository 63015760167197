import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatExpansionModule } from '@angular/material/expansion';

import { FlowStepListSubDocModule } from '../../listSubdoc/FlowStep/FlowStep.module';

import { FlowParamsListSubDocModule } from '../../listSubdoc/FlowParams/FlowParams.module';

import { UserClientFormAutoCompleteModule } from '../../chipList/UserClient/UserClient.module';

import { TagFormAutoCompleteModule } from '../../chipList/Tag/Tag.module';

import { FlowFormComponent } from './Flow.component';
@NgModule({
  declarations: [FlowFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatExpansionModule,

    FlowStepListSubDocModule,

    FlowParamsListSubDocModule,

    UserClientFormAutoCompleteModule,

    TagFormAutoCompleteModule
  ],
  exports: [
    FlowFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatExpansionModule,

    FlowStepListSubDocModule,

    FlowParamsListSubDocModule,

    UserClientFormAutoCompleteModule,

    TagFormAutoCompleteModule
  ]
})
export class FlowFormModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { VisionSlideTypeOutputFormComponent } from './VisionSlideTypeOutput.component';
@NgModule({
  declarations: [VisionSlideTypeOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule],
  exports: [VisionSlideTypeOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule]
})
export class VisionSlideTypeOutputFormModule {}

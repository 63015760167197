/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { FilePageUiListComponent } from 'src/app/generated/dataPages/list/File/File.component';
import { FilePageFormComponent } from 'src/app/generated/dataPages/form/File/File.component';
import { FilePageUiListModule } from 'src/app/generated/dataPages/list/File/File.module';
import { FilePageFormComponentModule } from 'src/app/generated/dataPages/form/File/File.module';

export class FileRoutes {
  public static routes = [
    {
      path: 'File/list',
      component: FilePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'File/edit/:id',
      component: FilePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'File/new/:id',
      component: FilePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [FilePageUiListModule, FilePageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isNullable']"><mat-checkbox formControlName="isNullable">isNullable</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['types']">
    <app-string-list label="types" [formArr]="formGroup['_types']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['sample']">
    <mat-form-field appearance="fill">
      <mat-label>sample</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>
</div>

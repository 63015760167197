import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedModule } from '@leandredev/common-ng16/components';

import { DocumentChunkSearchOutputFormComponent } from './DocumentChunkSearchOutput.component';
@NgModule({
  declarations: [DocumentChunkSearchOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule],
  exports: [DocumentChunkSearchOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule]
})
export class DocumentChunkSearchOutputFormModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatExpansionModule } from '@angular/material/expansion';

import { route_roleListSubDocModule } from '../../listSubdoc/route_role/route_role.module';

import { url_roleFormComponent } from './url_role.component';
@NgModule({
  declarations: [url_roleFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatExpansionModule, route_roleListSubDocModule],
  exports: [url_roleFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatExpansionModule, route_roleListSubDocModule]
})
export class url_roleFormModule {}

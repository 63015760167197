import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@leandredev/common-ng16/components';

import { DayjsModule } from '@leandredev/common-ng16';

import { UIComponentUIListSubDocComponent } from './UIComponent.component';

import { UIChatMessageFormModule } from '../../form/UIChatMessage/UIChatMessage.module';

import { UIChatWelcomeFormModule } from '../../form/UIChatWelcome/UIChatWelcome.module';

import { UIDocumentalistWelcomeFormModule } from '../../form/UIDocumentalistWelcome/UIDocumentalistWelcome.module';

import { UIDocumentalistSettingsFormModule } from '../../form/UIDocumentalistSettings/UIDocumentalistSettings.module';

import { UICopywriterWelcomeFormModule } from '../../form/UICopywriterWelcome/UICopywriterWelcome.module';

import { UIDocumentalistUserInputFormModule } from '../../form/UIDocumentalistUserInput/UIDocumentalistUserInput.module';

import { UICopywriterSettingsFormModule } from '../../form/UICopywriterSettings/UICopywriterSettings.module';

import { UIDocumentalistSettingsDrawerFormModule } from '../../form/UIDocumentalistSettingsDrawer/UIDocumentalistSettingsDrawer.module';

import { UICopywriterUserInputFormModule } from '../../form/UICopywriterUserInput/UICopywriterUserInput.module';

import { UICopywriterSettingsDrawerFormModule } from '../../form/UICopywriterSettingsDrawer/UICopywriterSettingsDrawer.module';

import { UIErrorMessageFormModule } from '../../form/UIErrorMessage/UIErrorMessage.module';

import { UICreativeWelcomeFormModule } from '../../form/UICreativeWelcome/UICreativeWelcome.module';

import { UICreativeSettingsDrawerFormModule } from '../../form/UICreativeSettingsDrawer/UICreativeSettingsDrawer.module';

import { UICreativeSettingsFormModule } from '../../form/UICreativeSettings/UICreativeSettings.module';

import { UICreativeUserInputFormModule } from '../../form/UICreativeUserInput/UICreativeUserInput.module';

import { UICopywriterStyleSettingsFormModule } from '../../form/UICopywriterStyleSettings/UICopywriterStyleSettings.module';

import { UICopywriterStyleSettingsDrawerFormModule } from '../../form/UICopywriterStyleSettingsDrawer/UICopywriterStyleSettingsDrawer.module';

import { UIAnalystUserInputFormModule } from '../../form/UIAnalystUserInput/UIAnalystUserInput.module';

import { UIAnalystSettingsDrawerFormModule } from '../../form/UIAnalystSettingsDrawer/UIAnalystSettingsDrawer.module';

import { UIAnalystWelcomeFormModule } from '../../form/UIAnalystWelcome/UIAnalystWelcome.module';

import { UIAnalystDataMessageFormModule } from '../../form/UIAnalystDataMessage/UIAnalystDataMessage.module';

import { UIAnalystSettingsFormModule } from '../../form/UIAnalystSettings/UIAnalystSettings.module';

import { FlowStepFormModule } from '../../form/FlowStep/FlowStep.module';

import { FlowStepDocumentalistFormModule } from '../../form/FlowStepDocumentalist/FlowStepDocumentalist.module';

import { FlowStepAnalystFormModule } from '../../form/FlowStepAnalyst/FlowStepAnalyst.module';

import { FlowStepCopywriterFormModule } from '../../form/FlowStepCopywriter/FlowStepCopywriter.module';

@NgModule({
  declarations: [UIComponentUIListSubDocComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    DayjsModule,
    SharedModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,

    UIChatMessageFormModule,

    UIChatWelcomeFormModule,

    UIDocumentalistWelcomeFormModule,

    UIDocumentalistSettingsFormModule,

    UICopywriterWelcomeFormModule,

    UIDocumentalistUserInputFormModule,

    UICopywriterSettingsFormModule,

    UIDocumentalistSettingsDrawerFormModule,

    UICopywriterUserInputFormModule,

    UICopywriterSettingsDrawerFormModule,

    UIErrorMessageFormModule,

    UICreativeWelcomeFormModule,

    UICreativeSettingsDrawerFormModule,

    UICreativeSettingsFormModule,

    UICreativeUserInputFormModule,

    UICopywriterStyleSettingsFormModule,

    UICopywriterStyleSettingsDrawerFormModule,

    UIAnalystUserInputFormModule,

    UIAnalystSettingsDrawerFormModule,

    UIAnalystWelcomeFormModule,

    UIAnalystDataMessageFormModule,

    UIAnalystSettingsFormModule,

    FlowStepFormModule,

    FlowStepDocumentalistFormModule,

    FlowStepAnalystFormModule,

    FlowStepCopywriterFormModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,

    MatButtonModule,
    MatMenuModule,
    DragDropModule,
    DayjsModule,
    SharedModule,

    UIChatMessageFormModule,

    UIChatWelcomeFormModule,

    UIDocumentalistWelcomeFormModule,

    UIDocumentalistSettingsFormModule,

    UICopywriterWelcomeFormModule,

    UIDocumentalistUserInputFormModule,

    UICopywriterSettingsFormModule,

    UIDocumentalistSettingsDrawerFormModule,

    UICopywriterUserInputFormModule,

    UICopywriterSettingsDrawerFormModule,

    UIErrorMessageFormModule,

    UICreativeWelcomeFormModule,

    UICreativeSettingsDrawerFormModule,

    UICreativeSettingsFormModule,

    UICreativeUserInputFormModule,

    UICopywriterStyleSettingsFormModule,

    UICopywriterStyleSettingsDrawerFormModule,

    UIAnalystUserInputFormModule,

    UIAnalystSettingsDrawerFormModule,

    UIAnalystWelcomeFormModule,

    UIAnalystDataMessageFormModule,

    UIAnalystSettingsFormModule,

    FlowStepFormModule,

    FlowStepDocumentalistFormModule,

    FlowStepAnalystFormModule,

    FlowStepCopywriterFormModule,

    UIComponentUIListSubDocComponent
  ]
})
export class UIComponentListSubDocModule {}

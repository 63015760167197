/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnChanges, ViewChild, OnInit, Input, Self, SimpleChanges } from '@angular/core';
import { interfaces, ObjectId } from '@leandredev/adaptivai-common-lib';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { DataService } from 'src/app/dataService/dataService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MsgActionToExecuteUiListComponent } from '../../../ui/list/MsgActionToExecute/MsgActionToExecute.component';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { ListComponent, PageTitleService } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-msgactiontoexecute-list',
  templateUrl: './MsgActionToExecute.component.html',
  styleUrls: ['./MsgActionToExecute.component.scss'],
  providers: [UIComponentState]
})
export class MsgActionToExecutePageUiListComponent extends ListComponent<interfaces.IMsgActionToExecute> implements OnInit, OnChanges {
  @ViewChild(MsgActionToExecuteUiListComponent, { static: true }) table!: MsgActionToExecuteUiListComponent;

  @Input()
  public filterQuery!: string;
  public columns: ListColumn[] = [
    { colId: 'creationDate', visible: false, humanName: 'creationDate' },
    { colId: 'startDate', visible: false, humanName: 'startDate' },
    { colId: 'serviceId', visible: false, humanName: 'serviceId' },
    { colId: 'endDate', visible: false, humanName: 'endDate' },
    { colId: 'hasFailed', visible: false, humanName: 'hasFailed' },
    { colId: 'errorsList', visible: false, humanName: 'errorsList' },
    { colId: 'serviceCreator', visible: false, humanName: 'serviceCreator' },
    { colId: 'userId', visible: false, humanName: 'userId' }
  ];

  constructor(
    protected dataService: DataService,
    route: Router,
    public activeRoute: ActivatedRoute,
    @Self() uIComponentState: UIComponentState,
    protected titleService: PageTitleService
  ) {
    super(route, activeRoute, uIComponentState);

    this.titleService.setPageTitle(`MsgActionToExecute`);

    this.httpServiceBase = dataService.api.collections.MsgActionToExecute;
  }

  setDefaultStatePopValues(): void {
    // super.setDefaultStatePopValues();
    this.pageSizeOptions = [20, 50, 100, 500];
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = this.columns.slice(0, 3).map((t) => t.colId);
    }
  }

  afterStateChanged(): void {
    // super.afterStateChanged();
    if (this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((col) => {
        const colToActivateIndex = this.columns.findIndex((c) => c.colId === col);

        if (colToActivateIndex > -1) {
          this.columns[colToActivateIndex].visible = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBase = this.filterQuery;
  }

  colsFilterChangeListener($event) {
    this.displayedColumns = $event;
  }

  public add(className = ''): void {
    if (className === '') {
      void this.router.navigate([`MsgActionToExecute/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    } else {
      void this.router.navigate([`${className}/new/${ObjectId.stringObjectId()}`], { relativeTo: this.activeRoute.parent });
    }
  }

  onFilterChange($event): void {
    this.applyFilter($event);
  }

  override rowClicked(row: interfaces.IMsgActionToExecute): void {
    if (!row._class) {
      row._class = 'MsgActionToExecute';
    }
    super.rowClicked(row);
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['index']">
    <mat-form-field appearance="fill">
      <mat-label>index</mat-label>
      <input matInput placeholder="" formControlName="index" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tags']">
    <app-string-list label="tags" [formArr]="formGroup['_tags']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['origine']">
    <mat-form-field appearance="fill">
      <mat-label>origine</mat-label>
      <input matInput placeholder="" formControlName="origine" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['mimetype']">
    <mat-form-field appearance="fill">
      <mat-label>mimetype</mat-label>
      <input matInput placeholder="" formControlName="mimetype" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['text']">
    <mat-form-field appearance="fill">
      <mat-label>text</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="text"></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['embedding']">
    <app-number-list label="embedding" [formArr]="formGroup['_embedding']"></app-number-list>
  </div>
</div>

<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>icon</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.icon }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>description</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <div [innerHTML]="row.description"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="usage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>usage</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <div [innerHTML]="row.usage"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="shortDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>shortDescription</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.shortDescription }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>isActive</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.isActive }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-assistant',
  templateUrl: './Assistant.component.html',
  styleUrls: ['./Assistant.component.scss'],
  providers: [UIComponentState]
})
export class AssistantUiListComponent extends UiList<interfaces.IAssistant> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      icon: 'string',
      description: 'htmltexte',
      usage: 'htmltexte',
      shortDescription: 'texte',
      isActive: 'boolean'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'icon', 'description', 'usage'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['service']">
    <app-service-form-auto-complete [formCtrl]="formGroup['_service']" placeholder="service"> </app-service-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['httAccess']">
    <mat-expansion-panel
      [expanded]="state['httAccess']?._expanded"
      (closed)="propState('httAccess_expanded', null)"
      (opened)="propState('httAccess_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> httAccess {{ fieldClassHumanName[formGroup._httAccess?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_httAccess']?.__class?.value">
          <app-ui-form-url_role id="httAccess" *ngSwitchCase="'url_role'" [formGroup]="formGroup['_httAccess']"></app-ui-form-url_role>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { TagPageUiListComponent } from 'src/app/generated/dataPages/list/Tag/Tag.component';
import { TagPageFormComponent } from 'src/app/generated/dataPages/form/Tag/Tag.component';
import { TagPageUiListModule } from 'src/app/generated/dataPages/list/Tag/Tag.module';
import { TagPageFormComponentModule } from 'src/app/generated/dataPages/form/Tag/Tag.module';

export class TagRoutes {
  public static routes = [
    {
      path: 'Tag/list',
      component: TagPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Tag/edit/:id',
      component: TagPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Tag/new/:id',
      component: TagPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [TagPageUiListModule, TagPageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['docSpaceId']">
    <app-tag-form-auto-complete [formCtrl]="formGroup['_docSpaceId']" placeholder="docSpaceId"> </app-tag-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['categoryEditor']">
    <mat-checkbox formControlName="categoryEditor">categoryEditor</mat-checkbox><br />
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tagEditor']"><mat-checkbox formControlName="tagEditor">tagEditor</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['documentUpload']">
    <mat-checkbox formControlName="documentUpload">documentUpload</mat-checkbox><br />
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['urlBase']">
    <mat-form-field appearance="fill">
      <mat-label>urlBase</mat-label>
      <input matInput placeholder="" formControlName="urlBase" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['port']">
    <mat-form-field appearance="fill">
      <mat-label>port</mat-label>
      <input matInput placeholder="" formControlName="port" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['debug']"><mat-checkbox formControlName="debug">debug</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['licence_well-known']">
    <mat-form-field appearance="fill">
      <mat-label>licence_well-known</mat-label>
      <input matInput placeholder="" formControlName="licence_well-known" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['secretKey']">
    <mat-form-field appearance="fill">
      <mat-label>secretKey</mat-label>
      <input matInput placeholder="" formControlName="secretKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['publicAccess']">
    <mat-expansion-panel
      [expanded]="state['publicAccess']?._expanded"
      (closed)="propState('publicAccess_expanded', null)"
      (opened)="propState('publicAccess_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> publicAccess {{ fieldClassHumanName[formGroup._publicAccess?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_publicAccess']?.__class?.value">
          <app-ui-form-url_role id="publicAccess" *ngSwitchCase="'url_role'" [formGroup]="formGroup['_publicAccess']"></app-ui-form-url_role>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['bindIp']">
    <mat-form-field appearance="fill">
      <mat-label>bindIp</mat-label>
      <input matInput placeholder="" formControlName="bindIp" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['headers']">
    <app-ui-list-sub-doc-name_value id="headers" label="headers" [inputData]="formGroup['_headers']"> </app-ui-list-sub-doc-name_value>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['accessControlAllowOrigin']">
    <app-string-list label="accessControlAllowOrigin" [formArr]="formGroup['_accessControlAllowOrigin']"></app-string-list>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['confServiceUrl']">
    <mat-form-field appearance="fill">
      <mat-label>confServiceUrl</mat-label>
      <input matInput placeholder="" formControlName="confServiceUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['bddUrl']">
    <mat-form-field appearance="fill">
      <mat-label>bddUrl</mat-label>
      <input matInput placeholder="" formControlName="bddUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tockenDuration']">
    <mat-form-field appearance="fill">
      <mat-label>Durée de validité du token de connexion en secondes</mat-label>
      <input matInput placeholder="" formControlName="tockenDuration" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['adminSiteUrl']">
    <mat-form-field appearance="fill">
      <mat-label>url de admin pour les retours de connexion admin</mat-label>
      <input matInput placeholder="" formControlName="adminSiteUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['mongoDbUrl']">
    <mat-form-field appearance="fill">
      <mat-label>mongoDbUrl</mat-label>
      <input matInput placeholder="" formControlName="mongoDbUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['openAiApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>openAiApiKey</mat-label>
      <input matInput placeholder="" formControlName="openAiApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['filesPath']">
    <mat-form-field appearance="fill">
      <mat-label>filesPath</mat-label>
      <input matInput placeholder="" formControlName="filesPath" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['deeplApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>deeplApiKey</mat-label>
      <input matInput placeholder="" formControlName="deeplApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['adobePdfServiceClientId']">
    <mat-form-field appearance="fill">
      <mat-label>adobePdfServiceClientId</mat-label>
      <input matInput placeholder="" formControlName="adobePdfServiceClientId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['adobePdfServiceClientSecret']">
    <mat-form-field appearance="fill">
      <mat-label>adobePdfServiceClientSecret</mat-label>
      <input matInput placeholder="" formControlName="adobePdfServiceClientSecret" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['sendgridConf']">
    <mat-expansion-panel
      [expanded]="state['sendgridConf']?._expanded"
      (closed)="propState('sendgridConf_expanded', null)"
      (opened)="propState('sendgridConf_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> sendgridConf {{ fieldClassHumanName[formGroup._sendgridConf?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_sendgridConf']?.__class?.value">
          <app-ui-form-adaptivaimailer
            id="sendgridConf"
            *ngSwitchCase="'AdaptivaiMailer'"
            [formGroup]="formGroup['_sendgridConf']"
          ></app-ui-form-adaptivaimailer>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['sendgridApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>sendgridApiKey</mat-label>
      <input matInput placeholder="" formControlName="sendgridApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['azureOpenApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>azureOpenApiKey</mat-label>
      <input matInput placeholder="" formControlName="azureOpenApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['azureOpenAiUrl']">
    <mat-form-field appearance="fill">
      <mat-label>azureOpenAiUrl</mat-label>
      <input matInput placeholder="" formControlName="azureOpenAiUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['mistralApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>mistralApiKey</mat-label>
      <input matInput placeholder="" formControlName="mistralApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['geminiApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>geminiApiKey</mat-label>
      <input matInput placeholder="" formControlName="geminiApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['anthropicApiKey']">
    <mat-form-field appearance="fill">
      <mat-label>anthropicApiKey</mat-label>
      <input matInput placeholder="" formControlName="anthropicApiKey" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['vectorStoreDriver']">
    <mat-form-field appearance="fill">
      <mat-label>vectorStoreDriver</mat-label>
      <input matInput placeholder="" formControlName="vectorStoreDriver" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['embeddingDriver']">
    <mat-form-field appearance="fill">
      <mat-label>embeddingDriver</mat-label>
      <input matInput placeholder="" formControlName="embeddingDriver" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['embeddingModel']">
    <mat-form-field appearance="fill">
      <mat-label>embeddingModel</mat-label>
      <input matInput placeholder="" formControlName="embeddingModel" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['embeddingVectorLength']">
    <mat-form-field appearance="fill">
      <mat-label>embeddingVectorLength</mat-label>
      <input matInput placeholder="" formControlName="embeddingVectorLength" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['imgLlm']">
    <mat-form-field appearance="fill">
      <mat-label>imgLlm</mat-label>
      <input matInput placeholder="" formControlName="imgLlm" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['imgLlmModel']">
    <mat-form-field appearance="fill">
      <mat-label>imgLlmModel</mat-label>
      <input matInput placeholder="" formControlName="imgLlmModel" />
    </mat-form-field>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { TagTypeFormAutoCompleteModule } from '../../chipList/TagType/TagType.module';

import { TagFormComponent } from './Tag.component';
@NgModule({
  declarations: [TagFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    TagTypeFormAutoCompleteModule
  ],
  exports: [
    TagFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    TagTypeFormAutoCompleteModule
  ]
})
export class TagFormModule {}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['icon']">
    <mat-form-field appearance="fill">
      <mat-label>icon</mat-label>
      <input matInput placeholder="" formControlName="icon" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-label>description</mat-label>
    <quill-editor placeholder="" formControlName="description">
      <div quill-editor-toolbar>
        <span class="ql-formats">
          <button class="ql-bold" [title]="'Bold'"></button>
          <button class="ql-underline" [title]="'Underline'"></button>
        </span>
        <!-- <span class="ql-script">
                            <button class="ql-script" value="sub"></button>
                            <button class="ql-script" value="super"></button>
                        </span> -->
        <span class="ql-formats">
          <select class="ql-align" [title]="'Alignement'">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-size">
          <select class="ql-size">
            <option value="small"></option>
            <!-- Note a missing, thus falsy value, is used to reset to default -->
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
        </span>
      </div>
    </quill-editor>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['usage']">
    <mat-label>usage</mat-label>
    <quill-editor placeholder="" formControlName="usage">
      <div quill-editor-toolbar>
        <span class="ql-formats">
          <button class="ql-bold" [title]="'Bold'"></button>
          <button class="ql-underline" [title]="'Underline'"></button>
        </span>
        <!-- <span class="ql-script">
                            <button class="ql-script" value="sub"></button>
                            <button class="ql-script" value="super"></button>
                        </span> -->
        <span class="ql-formats">
          <select class="ql-align" [title]="'Alignement'">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-size">
          <select class="ql-size">
            <option value="small"></option>
            <!-- Note a missing, thus falsy value, is used to reset to default -->
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
        </span>
      </div>
    </quill-editor>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['shortDescription']">
    <mat-form-field appearance="fill">
      <mat-label>shortDescription</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="shortDescription"></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isActive']"><mat-checkbox formControlName="isActive">isActive</mat-checkbox><br /></div>
</div>

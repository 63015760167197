import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatExpansionModule } from '@angular/material/expansion';

import { UIComponentListSubDocModule } from '../../listSubdoc/UIComponent/UIComponent.module';

import { UIComponentChatUserInputFormModule } from '../UIComponentChatUserInput/UIComponentChatUserInput.module';

import { UIDocumentalistUserInputFormModule } from '../UIDocumentalistUserInput/UIDocumentalistUserInput.module';

import { UICopywriterUserInputFormModule } from '../UICopywriterUserInput/UICopywriterUserInput.module';

import { UICreativeUserInputFormModule } from '../UICreativeUserInput/UICreativeUserInput.module';

import { UIAnalystUserInputFormModule } from '../UIAnalystUserInput/UIAnalystUserInput.module';

import { UIComponentFormModule } from '../UIComponent/UIComponent.module';

import { UIChatMessageFormModule } from '../UIChatMessage/UIChatMessage.module';

import { UIChatWelcomeFormModule } from '../UIChatWelcome/UIChatWelcome.module';

import { UIDocumentalistWelcomeFormModule } from '../UIDocumentalistWelcome/UIDocumentalistWelcome.module';

import { UIDocumentalistSettingsFormModule } from '../UIDocumentalistSettings/UIDocumentalistSettings.module';

import { UICopywriterWelcomeFormModule } from '../UICopywriterWelcome/UICopywriterWelcome.module';

import { UICopywriterSettingsFormModule } from '../UICopywriterSettings/UICopywriterSettings.module';

import { UIDocumentalistSettingsDrawerFormModule } from '../UIDocumentalistSettingsDrawer/UIDocumentalistSettingsDrawer.module';

import { UICopywriterSettingsDrawerFormModule } from '../UICopywriterSettingsDrawer/UICopywriterSettingsDrawer.module';

import { UIErrorMessageFormModule } from '../UIErrorMessage/UIErrorMessage.module';

import { UICreativeWelcomeFormModule } from '../UICreativeWelcome/UICreativeWelcome.module';

import { UICreativeSettingsDrawerFormModule } from '../UICreativeSettingsDrawer/UICreativeSettingsDrawer.module';

import { UICreativeSettingsFormModule } from '../UICreativeSettings/UICreativeSettings.module';

import { UICopywriterStyleSettingsFormModule } from '../UICopywriterStyleSettings/UICopywriterStyleSettings.module';

import { UICopywriterStyleSettingsDrawerFormModule } from '../UICopywriterStyleSettingsDrawer/UICopywriterStyleSettingsDrawer.module';

import { UIAnalystSettingsDrawerFormModule } from '../UIAnalystSettingsDrawer/UIAnalystSettingsDrawer.module';

import { UIAnalystWelcomeFormModule } from '../UIAnalystWelcome/UIAnalystWelcome.module';

import { UIAnalystDataMessageFormModule } from '../UIAnalystDataMessage/UIAnalystDataMessage.module';

import { UIAnalystSettingsFormModule } from '../UIAnalystSettings/UIAnalystSettings.module';

import { FlowStepFormModule } from '../FlowStep/FlowStep.module';

import { FlowStepDocumentalistFormModule } from '../FlowStepDocumentalist/FlowStepDocumentalist.module';

import { FlowStepAnalystFormModule } from '../FlowStepAnalyst/FlowStepAnalyst.module';

import { FlowStepCopywriterFormModule } from '../FlowStepCopywriter/FlowStepCopywriter.module';

import { LlmActionFormAutoCompleteModule } from '../../chipList/LlmAction/LlmAction.module';

import { UserAdminFormAutoCompleteModule } from '../../chipList/UserAdmin/UserAdmin.module';

import { AssistantSessionFormAutoCompleteModule } from '../../chipList/AssistantSession/AssistantSession.module';

import { SharedModule } from '@leandredev/common-ng16/components';

import { UserMessageAssistantFormComponent } from './UserMessageAssistant.component';
@NgModule({
  declarations: [UserMessageAssistantFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatExpansionModule,

    UIComponentListSubDocModule,

    UIComponentChatUserInputFormModule,

    UIDocumentalistUserInputFormModule,

    UICopywriterUserInputFormModule,

    UICreativeUserInputFormModule,

    UIAnalystUserInputFormModule,

    UIComponentFormModule,

    UIChatMessageFormModule,

    UIChatWelcomeFormModule,

    UIDocumentalistWelcomeFormModule,

    UIDocumentalistSettingsFormModule,

    UICopywriterWelcomeFormModule,

    UICopywriterSettingsFormModule,

    UIDocumentalistSettingsDrawerFormModule,

    UICopywriterSettingsDrawerFormModule,

    UIErrorMessageFormModule,

    UICreativeWelcomeFormModule,

    UICreativeSettingsDrawerFormModule,

    UICreativeSettingsFormModule,

    UICopywriterStyleSettingsFormModule,

    UICopywriterStyleSettingsDrawerFormModule,

    UIAnalystSettingsDrawerFormModule,

    UIAnalystWelcomeFormModule,

    UIAnalystDataMessageFormModule,

    UIAnalystSettingsFormModule,

    FlowStepFormModule,

    FlowStepDocumentalistFormModule,

    FlowStepAnalystFormModule,

    FlowStepCopywriterFormModule,

    LlmActionFormAutoCompleteModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule,

    SharedModule
  ],
  exports: [
    UserMessageAssistantFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatExpansionModule,

    UIComponentListSubDocModule,

    UIComponentChatUserInputFormModule,

    UIDocumentalistUserInputFormModule,

    UICopywriterUserInputFormModule,

    UICreativeUserInputFormModule,

    UIAnalystUserInputFormModule,

    UIComponentFormModule,

    UIChatMessageFormModule,

    UIChatWelcomeFormModule,

    UIDocumentalistWelcomeFormModule,

    UIDocumentalistSettingsFormModule,

    UICopywriterWelcomeFormModule,

    UICopywriterSettingsFormModule,

    UIDocumentalistSettingsDrawerFormModule,

    UICopywriterSettingsDrawerFormModule,

    UIErrorMessageFormModule,

    UICreativeWelcomeFormModule,

    UICreativeSettingsDrawerFormModule,

    UICreativeSettingsFormModule,

    UICopywriterStyleSettingsFormModule,

    UICopywriterStyleSettingsDrawerFormModule,

    UIAnalystSettingsDrawerFormModule,

    UIAnalystWelcomeFormModule,

    UIAnalystDataMessageFormModule,

    UIAnalystSettingsFormModule,

    FlowStepFormModule,

    FlowStepDocumentalistFormModule,

    FlowStepAnalystFormModule,

    FlowStepCopywriterFormModule,

    LlmActionFormAutoCompleteModule,

    UserAdminFormAutoCompleteModule,

    AssistantSessionFormAutoCompleteModule,

    SharedModule
  ]
})
export class UserMessageAssistantFormModule {}

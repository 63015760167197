/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { VisionImageDescriptionPageUiListComponent } from 'src/app/generated/dataPages/list/VisionImageDescription/VisionImageDescription.component';
import { VisionImageDescriptionPageFormComponent } from 'src/app/generated/dataPages/form/VisionImageDescription/VisionImageDescription.component';
import { VisionImageDescriptionPageUiListModule } from 'src/app/generated/dataPages/list/VisionImageDescription/VisionImageDescription.module';
import { VisionImageDescriptionPageFormComponentModule } from 'src/app/generated/dataPages/form/VisionImageDescription/VisionImageDescription.module';

export class VisionImageDescriptionRoutes {
  public static routes = [
    {
      path: 'VisionImageDescription/list',
      component: VisionImageDescriptionPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'VisionImageDescription/edit/:id',
      component: VisionImageDescriptionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'VisionImageDescription/new/:id',
      component: VisionImageDescriptionPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [VisionImageDescriptionPageUiListModule, VisionImageDescriptionPageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['order']">
    <mat-form-field appearance="fill">
      <mat-label>order</mat-label>
      <input matInput placeholder="" formControlName="order" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['docspaces']">
    <app-tag-form-auto-complete [formArr]="formGroup['_docspaces']" placeholder="docspaces"> </app-tag-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['color']">
    <mat-form-field appearance="fill">
      <mat-label>color</mat-label>
      <input matInput placeholder="" formControlName="color" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isMandatory']"><mat-checkbox formControlName="isMandatory">isMandatory</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['isDataQualifier']">
    <mat-checkbox formControlName="isDataQualifier">isDataQualifier</mat-checkbox><br />
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isReadOnly']"><mat-checkbox formControlName="isReadOnly">isReadOnly</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['import']"><mat-checkbox formControlName="import">import</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['isDocSpace']"><mat-checkbox formControlName="isDocSpace">isDocSpace</mat-checkbox><br /></div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { service_fsoPageUiListComponent } from 'src/app/generated/dataPages/list/service_fso/service_fso.component';
import { service_fsoPageFormComponent } from 'src/app/generated/dataPages/form/service_fso/service_fso.component';
import { service_fsoPageUiListModule } from 'src/app/generated/dataPages/list/service_fso/service_fso.module';
import { service_fsoPageFormComponentModule } from 'src/app/generated/dataPages/form/service_fso/service_fso.module';

export class service_fsoRoutes {
  public static routes = [
    {
      path: 'service_fso/list',
      component: service_fsoPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'service_fso/edit/:id',
      component: service_fsoPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'service_fso/new/:id',
      component: service_fsoPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [service_fsoPageUiListModule, service_fsoPageFormComponentModule];
}

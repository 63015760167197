<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['senderEmail']">
    <mat-form-field appearance="fill">
      <mat-label>senderEmail</mat-label>
      <input matInput placeholder="" formControlName="senderEmail" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['senderName']">
    <mat-form-field appearance="fill">
      <mat-label>senderName</mat-label>
      <input matInput placeholder="" formControlName="senderName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['baseUrl']">
    <mat-form-field appearance="fill">
      <mat-label>baseUrl</mat-label>
      <input matInput placeholder="" formControlName="baseUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['sendgridApiUrl']">
    <mat-form-field appearance="fill">
      <mat-label>sendgridApiUrl</mat-label>
      <input matInput placeholder="" formControlName="sendgridApiUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['contactMail']">
    <mat-form-field appearance="fill">
      <mat-label>contactMail</mat-label>
      <input matInput placeholder="" formControlName="contactMail" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPassword']">
    <mat-form-field appearance="fill">
      <mat-label>resetPassword</mat-label>
      <input matInput placeholder="" formControlName="resetPassword" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['adaptivai_welcome']">
    <mat-form-field appearance="fill">
      <mat-label>adaptivai_welcome</mat-label>
      <input matInput placeholder="" formControlName="adaptivai_welcome" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['accountActivationRedirectUrl']">
    <mat-form-field appearance="fill">
      <mat-label>accountActivationRedirectUrl</mat-label>
      <input matInput placeholder="" formControlName="accountActivationRedirectUrl" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPasswordRedirectUrlApp']">
    <mat-form-field appearance="fill">
      <mat-label>resetPasswordRedirectUrlApp</mat-label>
      <input matInput placeholder="" formControlName="resetPasswordRedirectUrlApp" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['resetPasswordRedirectUrlAdmin']">
    <mat-form-field appearance="fill">
      <mat-label>resetPasswordRedirectUrlAdmin</mat-label>
      <input matInput placeholder="" formControlName="resetPasswordRedirectUrlAdmin" />
    </mat-form-field>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['startDate']">
    <mat-form-field appearance="fill">
      <mat-label>startDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mystartDate" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="mystartDate"></mat-datepicker-toggle>
      <mat-datepicker #mystartDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['state']">
    <mat-form-field appearance="fill">
      <mat-label>state</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceId']">
    <mat-form-field appearance="fill">
      <mat-label>serviceId</mat-label>
      <input matInput placeholder="" formControlName="serviceId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endDate']">
    <mat-form-field appearance="fill">
      <mat-label>endDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myendDate" formControlName="endDate" />
      <mat-datepicker-toggle matSuffix [for]="myendDate"></mat-datepicker-toggle>
      <mat-datepicker #myendDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['deleted']"><mat-checkbox formControlName="deleted">deleted</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['hasFailed']"><mat-checkbox formControlName="hasFailed">hasFailed</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['errorsList']">
    <app-ui-list-sub-doc-errorobj id="errorsList" label="errorsList" [inputData]="formGroup['_errorsList']"> </app-ui-list-sub-doc-errorobj>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['serviceCreator']">
    <mat-form-field appearance="fill">
      <mat-label>serviceCreator</mat-label>
      <input matInput placeholder="" formControlName="serviceCreator" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantSessionId']">
    <app-assistantsession-form-auto-complete [formCtrl]="formGroup['_assistantSessionId']" placeholder="assistantSessionId">
    </app-assistantsession-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lang']">
    <mat-form-field appearance="fill">
      <mat-label>lang</mat-label>
      <input matInput placeholder="" formControlName="lang" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llm']">
    <mat-form-field appearance="fill">
      <mat-label>llm</mat-label>
      <input matInput placeholder="" formControlName="llm" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['llmModel']">
    <mat-form-field appearance="fill">
      <mat-label>llmModel</mat-label>
      <input matInput placeholder="" formControlName="llmModel" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['endInteractionServiceId']">
    <mat-form-field appearance="fill">
      <mat-label>endInteractionServiceId</mat-label>
      <input matInput placeholder="" formControlName="endInteractionServiceId" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['prompt']">
    <mat-form-field appearance="fill">
      <mat-label>prompt</mat-label>
      <input matInput placeholder="" formControlName="prompt" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['suggestQuestions']">
    <mat-checkbox formControlName="suggestQuestions">suggestQuestions</mat-checkbox><br />
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['chunkSearch']">
    <mat-expansion-panel
      [expanded]="state['chunkSearch']?._expanded"
      (closed)="propState('chunkSearch_expanded', null)"
      (opened)="propState('chunkSearch_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> chunkSearch {{ fieldClassHumanName[formGroup._chunkSearch?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_chunkSearch']?.__class?.value">
          <app-ui-form-documentchunksearch
            id="chunkSearch"
            *ngSwitchCase="'DocumentChunkSearch'"
            [formGroup]="formGroup['_chunkSearch']"
          ></app-ui-form-documentchunksearch>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['answerFormat']">
    <mat-form-field appearance="fill">
      <mat-label>answerFormat</mat-label>

      <mat-select placeholder="Select" formControlName="answerFormat">
        <mat-option value="short">short</mat-option>

        <mat-option value="bullet_points">bullet_points</mat-option>

        <mat-option value="detailed">detailed</mat-option>

        <mat-option value="table">table</mat-option>

        <mat-option value="specified_in_query">specified_in_query</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['output']">
    <mat-expansion-panel
      [expanded]="state['output']?._expanded"
      (closed)="propState('output_expanded', null)"
      (opened)="propState('output_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> output {{ fieldClassHumanName[formGroup._output?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_output']?.__class?.value">
          <app-ui-form-documentalistesearchoutput
            id="output"
            *ngSwitchCase="'DocumentalisteSearchOutput'"
            [formGroup]="formGroup['_output']"
          ></app-ui-form-documentalistesearchoutput>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['searchType']">
    <mat-form-field appearance="fill">
      <mat-label>searchType</mat-label>

      <mat-select placeholder="Select" formControlName="searchType">
        <mat-option value="full_document">full_document</mat-option>

        <mat-option value="chat_only">chat_only</mat-option>

        <mat-option value="query_only">query_only</mat-option>

        <mat-option value="query_specific">query_specific</mat-option>

        <mat-option value="query_and_question">query_and_question</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['framework']">
    <mat-form-field appearance="fill">
      <mat-label>framework</mat-label>
      <input matInput placeholder="" formControlName="framework" />
    </mat-form-field>
  </div>
</div>

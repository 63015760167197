/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { CopywriterPageUiListComponent } from 'src/app/generated/dataPages/list/Copywriter/Copywriter.component';
import { CopywriterPageFormComponent } from 'src/app/generated/dataPages/form/Copywriter/Copywriter.component';
import { CopywriterPageUiListModule } from 'src/app/generated/dataPages/list/Copywriter/Copywriter.module';
import { CopywriterPageFormComponentModule } from 'src/app/generated/dataPages/form/Copywriter/Copywriter.module';

export class CopywriterRoutes {
  public static routes = [
    {
      path: 'Copywriter/list',
      component: CopywriterPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Copywriter/edit/:id',
      component: CopywriterPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Copywriter/new/:id',
      component: CopywriterPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [CopywriterPageUiListModule, CopywriterPageFormComponentModule];
}

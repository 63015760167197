<mat-form-field class="example-chip-list" appearance="fill">
  <mat-label>Tags</mat-label>
  <mat-chip-grid #chipList aria-label="Tags">
    <mat-chip *ngFor="let tag of selectedTags" (removed)="remove(tag)">
      {{ tag.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="Ajouter des tags"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add()"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let obj of tags" [value]="obj">
      {{ obj.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

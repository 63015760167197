import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormPasswordDirective } from './form-password.directive';

@NgModule({
  declarations: [FormPasswordDirective],
  imports: [CommonModule],
  providers: [DecimalPipe],
  exports: [FormPasswordDirective]
})
export class DirectiveModule {}

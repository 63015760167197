import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DayjsModule } from '@leandredev/common-ng16';
import { MatMenuModule } from '@angular/material/menu';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { ListModule } from 'src/@fury/shared/list/list.module';

import { application_configuration_adaptivai_clientPageUiListComponent } from './application_configuration_adaptivai_client.component';
import { application_configuration_adaptivai_clientListModule } from '../../../ui/list/application_configuration_adaptivai_client/application_configuration_adaptivai_client.module';
@NgModule({
  declarations: [application_configuration_adaptivai_clientPageUiListComponent],
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    DayjsModule,
    MatMenuModule,
    FurySharedModule,
    ListModule,

    application_configuration_adaptivai_clientListModule
  ],

  exports: [
    application_configuration_adaptivai_clientPageUiListComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    DayjsModule,
    MatMenuModule,

    ListModule,

    application_configuration_adaptivai_clientListModule
  ]
})
export class application_configuration_adaptivai_clientPageUiListModule {}

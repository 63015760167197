<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs current="CreatePresentation"></fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content>
    <fury-list
      name="CreatePresentation"
      [columns]="columns"
      (filterChange)="onFilterChange($event)"
      (colsFilterChange)="colsFilterChangeListener($event)"
    >
      <div class="actions">
        <button class="create" (click)="add()" type="button" mat-mini-fab color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <app-ui-list-createpresentation
        class="table"
        id="uiList"
        [sortDirection]="sortDirection"
        [sortColumn]="sortColumn"
        [displayedColumns]="displayedColumns"
        [data]="data"
        (rowClicked)="rowClicked($event)"
        [isLoadingResults]="isLoadingResults"
        (sortChange)="sortChange($event)"
      ></app-ui-list-createpresentation>
      <mat-paginator
        (page)="paginatorChange($event)"
        [pageSizeOptions]="pageSizeOptions"
        [length]="dataLength"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        showFirstLastButtons
      ></mat-paginator>
    </fury-list>
  </fury-page-layout-content>
</fury-page-layout>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { CreatePresentationPageUiListComponent } from 'src/app/generated/dataPages/list/CreatePresentation/CreatePresentation.component';
import { CreatePresentationPageFormComponent } from 'src/app/generated/dataPages/form/CreatePresentation/CreatePresentation.component';
import { CreatePresentationPageUiListModule } from 'src/app/generated/dataPages/list/CreatePresentation/CreatePresentation.module';
import { CreatePresentationPageFormComponentModule } from 'src/app/generated/dataPages/form/CreatePresentation/CreatePresentation.module';

export class CreatePresentationRoutes {
  public static routes = [
    {
      path: 'CreatePresentation/list',
      component: CreatePresentationPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'CreatePresentation/edit/:id',
      component: CreatePresentationPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'CreatePresentation/new/:id',
      component: CreatePresentationPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [CreatePresentationPageUiListModule, CreatePresentationPageFormComponentModule];
}

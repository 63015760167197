/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { AdobeCreatePdfPageUiListComponent } from 'src/app/generated/dataPages/list/AdobeCreatePdf/AdobeCreatePdf.component';
import { AdobeCreatePdfPageFormComponent } from 'src/app/generated/dataPages/form/AdobeCreatePdf/AdobeCreatePdf.component';
import { AdobeCreatePdfPageUiListModule } from 'src/app/generated/dataPages/list/AdobeCreatePdf/AdobeCreatePdf.module';
import { AdobeCreatePdfPageFormComponentModule } from 'src/app/generated/dataPages/form/AdobeCreatePdf/AdobeCreatePdf.module';

export class AdobeCreatePdfRoutes {
  public static routes = [
    {
      path: 'AdobeCreatePdf/list',
      component: AdobeCreatePdfPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AdobeCreatePdf/edit/:id',
      component: AdobeCreatePdfPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'AdobeCreatePdf/new/:id',
      component: AdobeCreatePdfPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [AdobeCreatePdfPageUiListModule, AdobeCreatePdfPageFormComponentModule];
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { application_configuration_adaptivai_clientPageUiListComponent } from 'src/app/generated/dataPages/list/application_configuration_adaptivai_client/application_configuration_adaptivai_client.component';
import { application_configuration_adaptivai_clientPageFormComponent } from 'src/app/generated/dataPages/form/application_configuration_adaptivai_client/application_configuration_adaptivai_client.component';
import { application_configuration_adaptivai_clientPageUiListModule } from 'src/app/generated/dataPages/list/application_configuration_adaptivai_client/application_configuration_adaptivai_client.module';
import { application_configuration_adaptivai_clientPageFormComponentModule } from 'src/app/generated/dataPages/form/application_configuration_adaptivai_client/application_configuration_adaptivai_client.module';

export class application_configuration_adaptivai_clientRoutes {
  public static routes = [
    {
      path: 'application_configuration_adaptivai_client/list',
      component: application_configuration_adaptivai_clientPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'application_configuration_adaptivai_client/edit/:id',
      component: application_configuration_adaptivai_clientPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'application_configuration_adaptivai_client/new/:id',
      component: application_configuration_adaptivai_clientPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [
    application_configuration_adaptivai_clientPageUiListModule,
    application_configuration_adaptivai_clientPageFormComponentModule
  ];
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FurySharedModule } from '../../@fury/fury-shared.module';
import { DataRoutingModule } from './data-routing.module';
import { UserAdminPageFormComponentModule } from '../generated/dataPages/form/UserAdmin/UserAdmin.module';
import { UserAdminPageUiListModule } from '../generated/dataPages/list/UserAdmin/UserAdmin.module';
import { protoschemaPageFormComponentModule } from '../generated/dataPages/form/protoschema/protoschema.module';
import { TagPageUiListModule } from '../generated/dataPages/list/Tag/Tag.module';
import { TagPageFormComponentModule } from '../generated/dataPages/form/Tag/Tag.module';
import { TagTypePageFormComponentModule } from '../generated/dataPages/form/TagType/TagType.module';
import { TagTypePageUiListModule } from '../generated/dataPages/list/TagType/TagType.module';

import { ImportingFilePageUiListModule } from '../generated/dataPages/list/ImportingFile/ImportingFile.module';
import { ImportingFilePageFormComponentModule } from '../generated/dataPages/form/ImportingFile/ImportingFile.module';
import { UploadModule } from '../modules/upload/upload.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserClientModule } from '../modules/user-client/user-client.module';

//form.FormControlBase.classes = form;

@NgModule({
  imports: [
    CommonModule,
    FurySharedModule,
    FormsModule,
    ReactiveFormsModule,
    protoschemaPageFormComponentModule,
    UserAdminPageUiListModule,
    UserAdminPageFormComponentModule,
    TagPageUiListModule,
    TagPageFormComponentModule,
    TagTypePageUiListModule,
    TagTypePageFormComponentModule,
    ImportingFilePageUiListModule,

    DataRoutingModule,
    UploadModule,
    UserClientModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FurySharedModule,
    protoschemaPageFormComponentModule,
    UserAdminPageUiListModule,
    UserAdminPageFormComponentModule,
    TagPageUiListModule,
    TagPageFormComponentModule,
    TagTypePageUiListModule,
    TagTypePageFormComponentModule,
    ImportingFilePageUiListModule,
    ImportingFilePageFormComponentModule,
    DataRoutingModule,
    UploadModule,
    UserClientModule
  ]
})
export class DataModule {}

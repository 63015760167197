import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedModule } from '@leandredev/common-ng16/components';

import { AdobeePdfToChuncksOutputFormComponent } from './AdobeePdfToChuncksOutput.component';
@NgModule({
  declarations: [AdobeePdfToChuncksOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule],
  exports: [AdobeePdfToChuncksOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule]
})
export class AdobeePdfToChuncksOutputFormModule {}

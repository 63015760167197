/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-documentdataanalystoutput',
  templateUrl: './DocumentDataAnalystOutput.component.html',
  styleUrls: ['./DocumentDataAnalystOutput.component.scss'],
  providers: [UIComponentState]
})
export class DocumentDataAnalystOutputUiListComponent extends UiList<interfaces.IDocumentDataAnalystOutput> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      output: 'subdoc',
      creationDate: 'date',
      startDate: 'date',
      state: 'object',
      serviceId: 'string',
      endDate: 'date',
      deleted: 'boolean',
      hasFailed: 'boolean',
      errorsList: 'subdoc',
      userId: 'objectid',
      serviceCreator: 'string',
      assistantSessionId: 'objectid',
      lang: 'string',
      llm: 'string',
      llmModel: 'string',
      endInteractionServiceId: 'string',
      url: 'string',
      blob: 'object',
      mimeType: 'string',
      collectionName: 'string',
      fieldsDescriptions: 'object',
      mongoQuery: 'object',
      datas: 'object',
      error: 'string',
      explanation: 'texte',
      chartSettings: 'subdoc'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['output', 'creationDate', 'startDate', 'state'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-useractionstats',
  templateUrl: './UserActionStats.component.html',
  styleUrls: ['./UserActionStats.component.scss'],
  providers: [UIComponentState]
})
export class UserActionStatsUiListComponent extends UiList<interfaces.IUserActionStats> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      documentaliste: 'number',
      copywriter: 'number',
      creative: 'number',
      analyst: 'number',
      chat: 'number',
      nb: 'number',
      userId: 'objectid',
      isWeek: 'number',
      year: 'number',
      date: 'date',
      name: 'string',
      email: 'string'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['documentaliste', 'copywriter', 'creative', 'analyst'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

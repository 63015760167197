<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>

      <mat-select placeholder="Select" formControlName="name">
        <mat-option value="GTP 4 Turbo">GTP 4 Turbo</mat-option>

        <mat-option value="GPT 4o">GPT 4o</mat-option>

        <mat-option value="Gemini Pro">Gemini Pro</mat-option>

        <mat-option value="Mistral AI">Mistral AI</mat-option>

        <mat-option value="Claude 3">Claude 3</mat-option>

        <mat-option value="Llama 3">Llama 3</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['model']">
    <mat-form-field appearance="fill">
      <mat-label>model</mat-label>

      <mat-select placeholder="Select" formControlName="model">
        <mat-option value="gpt-4-turbo">gpt-4-turbo</mat-option>

        <mat-option value="gpt-4o">gpt-4o</mat-option>

        <mat-option value="gemini-pro">gemini-pro</mat-option>

        <mat-option value="mistral-ai">mistral-ai</mat-option>

        <mat-option value="claude-3">claude-3</mat-option>

        <mat-option value="llama-3">llama-3</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput placeholder="" formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['driver']">
    <mat-form-field appearance="fill">
      <mat-label>driver</mat-label>
      <input matInput placeholder="" formControlName="driver" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isActive']"><mat-checkbox formControlName="isActive">isActive</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['category']">
    <mat-form-field appearance="fill">
      <mat-label>category</mat-label>
      <input matInput placeholder="" formControlName="category" />
    </mat-form-field>
  </div>
</div>

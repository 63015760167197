<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>externalId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.externalId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="enabledTags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>enabledTags</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <ng-container *ngFor="let val of row.enabledTags">
              <app-ui-ref #obj colName="Tag" [idRef]="val">
                {{ obj.data?.name || obj.data?.title || val }}
              </app-ui-ref>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="disabledTags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>disabledTags</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <ng-container *ngFor="let val of row.disabledTags">
              <app-ui-ref #obj colName="Tag" [idRef]="val">
                {{ obj.data?.name || obj.data?.title || val }}
              </app-ui-ref>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="docSpaceRights">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>docSpaceRights</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.docSpaceRights | json }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>creator</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="UserAdmin" [idRef]="row.creator">
              {{ obj.data?.name || obj.data?.title || row.creator }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>

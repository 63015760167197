/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { DocumentStatusMessagePageUiListComponent } from 'src/app/generated/dataPages/list/DocumentStatusMessage/DocumentStatusMessage.component';
import { DocumentStatusMessagePageFormComponent } from 'src/app/generated/dataPages/form/DocumentStatusMessage/DocumentStatusMessage.component';
import { DocumentStatusMessagePageUiListModule } from 'src/app/generated/dataPages/list/DocumentStatusMessage/DocumentStatusMessage.module';
import { DocumentStatusMessagePageFormComponentModule } from 'src/app/generated/dataPages/form/DocumentStatusMessage/DocumentStatusMessage.module';

export class DocumentStatusMessageRoutes {
  public static routes = [
    {
      path: 'DocumentStatusMessage/list',
      component: DocumentStatusMessagePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentStatusMessage/edit/:id',
      component: DocumentStatusMessagePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'DocumentStatusMessage/new/:id',
      component: DocumentStatusMessagePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [DocumentStatusMessagePageUiListModule, DocumentStatusMessagePageFormComponentModule];
}

import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { AssistantPageFormComponent } from 'src/app/generated/dataPages/form/Assistant/Assistant.component';
import { CopywriterStyleRecordPageFormComponent } from 'src/app/generated/dataPages/form/CopywriterStyleRecord/CopywriterStyleRecord.component';
import { AssistantPageUiListComponent } from 'src/app/generated/dataPages/list/Assistant/Assistant.component';
import { CopywriterStyleRecordPageUiListComponent } from 'src/app/generated/dataPages/list/CopywriterStyleRecord/CopywriterStyleRecord.component';

export class ApplicationDataRoutes {
  public static routes = [
    {
      path: 'Assistant/list',
      component: AssistantPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Assistant/edit/:id',
      component: AssistantPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'Assistant/new/:id',
      component: AssistantPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'CopywriterStyleRecord/list',
      component: CopywriterStyleRecordPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },

    {
      path: 'CopywriterStyleRecord/edit/:id',
      component: CopywriterStyleRecordPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'CopywriterStyleRecord/new/:id',
      component: CopywriterStyleRecordPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
}

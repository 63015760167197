/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-usermessage',
  templateUrl: './UserMessage.component.html',
  styleUrls: ['./UserMessage.component.scss'],
  providers: [UIComponentState]
})
export class UserMessageUiListComponent extends UiList<interfaces.IUserMessage> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      message: 'string',
      llmActionId: 'objectid',
      creationDate: 'date',
      serviceIds: 'string',
      userId: 'objectid',
      assistantSessionId: 'objectid'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['message', 'llmActionId', 'creationDate', 'serviceIds'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserAdminPageUiListComponent } from 'src/app/generated/dataPages/list/UserAdmin/UserAdmin.component';
import { UserAdminPageFormComponent } from 'src/app/generated/dataPages/form/UserAdmin/UserAdmin.component';
import { UserAdminPageUiListModule } from 'src/app/generated/dataPages/list/UserAdmin/UserAdmin.module';
import { UserAdminPageFormComponentModule } from 'src/app/generated/dataPages/form/UserAdmin/UserAdmin.module';

export class UserAdminRoutes {
  public static routes = [
    {
      path: 'UserAdmin/list',
      component: UserAdminPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserAdmin/edit/:id',
      component: UserAdminPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserAdmin/new/:id',
      component: UserAdminPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserAdminPageUiListModule, UserAdminPageFormComponentModule];
}

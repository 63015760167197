<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['assistantName']">
    <mat-form-field appearance="fill">
      <mat-label>assistantName</mat-label>
      <input matInput placeholder="" formControlName="assistantName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['creationDate']">
    <mat-form-field appearance="fill">
      <mat-label>creationDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreationDate" formControlName="creationDate" />
      <mat-datepicker-toggle matSuffix [for]="mycreationDate"></mat-datepicker-toggle>
      <mat-datepicker #mycreationDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['flowView']">
    <app-ui-list-sub-doc-uicomponent id="flowView" label="flowView" [inputData]="formGroup['_flowView']"> </app-ui-list-sub-doc-uicomponent>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['state']">
    <mat-form-field appearance="fill">
      <mat-label>state</mat-label>
      <textarea matInput rows="5" placeholder=""></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lmActionHistory']">
    <app-llmaction-form-auto-complete [formArr]="formGroup['_lmActionHistory']" placeholder="lmActionHistory"> </app-llmaction-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-useradmin-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-useradmin-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['updatedDate']">
    <mat-form-field appearance="fill">
      <mat-label>updatedDate</mat-label>
      <input matInput placeholder="" [matDatepicker]="myupdatedDate" formControlName="updatedDate" />
      <mat-datepicker-toggle matSuffix [for]="myupdatedDate"></mat-datepicker-toggle>
      <mat-datepicker #myupdatedDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userInput']">
    <mat-expansion-panel
      [expanded]="state['userInput']?._expanded"
      (closed)="propState('userInput_expanded', null)"
      (opened)="propState('userInput_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> userInput {{ fieldClassHumanName[formGroup._userInput?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-form-field>
          <mat-label>Selectionner le type</mat-label>
          <mat-select (selectionChange)="classChange($event.value, formGroup['_userInput'])" [value]="formGroup['_userInput']?.__class?.value">
            <mat-option value="UIDocumentalistUserInput">UIDocumentalistUserInput</mat-option>

            <mat-option value="UICopywriterUserInput">UICopywriterUserInput</mat-option>

            <mat-option value="UICreativeUserInput">UICreativeUserInput</mat-option>

            <mat-option value="UIAnalystUserInput">UIAnalystUserInput</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container [ngSwitch]="formGroup['_userInput']?.__class?.value">
          <app-ui-form-uidocumentalistuserinput
            id="userInput"
            *ngSwitchCase="'UIDocumentalistUserInput'"
            [formGroup]="$any(formGroup['_userInput'])"
          ></app-ui-form-uidocumentalistuserinput>

          <app-ui-form-uicopywriteruserinput
            id="userInput"
            *ngSwitchCase="'UICopywriterUserInput'"
            [formGroup]="$any(formGroup['_userInput'])"
          ></app-ui-form-uicopywriteruserinput>

          <app-ui-form-uicreativeuserinput
            id="userInput"
            *ngSwitchCase="'UICreativeUserInput'"
            [formGroup]="$any(formGroup['_userInput'])"
          ></app-ui-form-uicreativeuserinput>

          <app-ui-form-uianalystuserinput
            id="userInput"
            *ngSwitchCase="'UIAnalystUserInput'"
            [formGroup]="$any(formGroup['_userInput'])"
          ></app-ui-form-uianalystuserinput>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['drawerComponent']">
    <mat-expansion-panel
      [expanded]="state['drawerComponent']?._expanded"
      (closed)="propState('drawerComponent_expanded', null)"
      (opened)="propState('drawerComponent_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> drawerComponent {{ fieldClassHumanName[formGroup._drawerComponent?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-form-field>
          <mat-label>Selectionner le type</mat-label>
          <mat-select
            (selectionChange)="classChange($event.value, formGroup['_drawerComponent'])"
            [value]="formGroup['_drawerComponent']?.__class?.value"
          >
            <mat-option value="UIChatMessage">UIChatMessage</mat-option>

            <mat-option value="UIChatWelcome">UIChatWelcome</mat-option>

            <mat-option value="UIDocumentalistWelcome">UIDocumentalistWelcome</mat-option>

            <mat-option value="UIDocumentalistSettings">UIDocumentalistSettings</mat-option>

            <mat-option value="UICopywriterWelcome">UICopywriterWelcome</mat-option>

            <mat-option value="UIDocumentalistUserInput">UIDocumentalistUserInput</mat-option>

            <mat-option value="UICopywriterSettings">UICopywriterSettings</mat-option>

            <mat-option value="UIDocumentalistSettingsDrawer">UIDocumentalistSettingsDrawer</mat-option>

            <mat-option value="UICopywriterUserInput">UICopywriterUserInput</mat-option>

            <mat-option value="UICopywriterSettingsDrawer">UICopywriterSettingsDrawer</mat-option>

            <mat-option value="UIErrorMessage">UIErrorMessage</mat-option>

            <mat-option value="UICreativeWelcome">UICreativeWelcome</mat-option>

            <mat-option value="UICreativeSettingsDrawer">UICreativeSettingsDrawer</mat-option>

            <mat-option value="UICreativeSettings">UICreativeSettings</mat-option>

            <mat-option value="UICreativeUserInput">UICreativeUserInput</mat-option>

            <mat-option value="UICopywriterStyleSettings">UICopywriterStyleSettings</mat-option>

            <mat-option value="UICopywriterStyleSettingsDrawer">UICopywriterStyleSettingsDrawer</mat-option>

            <mat-option value="UIAnalystUserInput">UIAnalystUserInput</mat-option>

            <mat-option value="UIAnalystSettingsDrawer">UIAnalystSettingsDrawer</mat-option>

            <mat-option value="UIAnalystWelcome">UIAnalystWelcome</mat-option>

            <mat-option value="UIAnalystDataMessage">UIAnalystDataMessage</mat-option>

            <mat-option value="UIAnalystSettings">UIAnalystSettings</mat-option>

            <mat-option value="FlowStep">FlowStep</mat-option>

            <mat-option value="FlowStepDocumentalist">FlowStepDocumentalist</mat-option>

            <mat-option value="FlowStepAnalyst">FlowStepAnalyst</mat-option>

            <mat-option value="FlowStepCopywriter">FlowStepCopywriter</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container [ngSwitch]="formGroup['_drawerComponent']?.__class?.value">
          <app-ui-form-uichatmessage
            id="drawerComponent"
            *ngSwitchCase="'UIChatMessage'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uichatmessage>

          <app-ui-form-uichatwelcome
            id="drawerComponent"
            *ngSwitchCase="'UIChatWelcome'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uichatwelcome>

          <app-ui-form-uidocumentalistwelcome
            id="drawerComponent"
            *ngSwitchCase="'UIDocumentalistWelcome'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uidocumentalistwelcome>

          <app-ui-form-uidocumentalistsettings
            id="drawerComponent"
            *ngSwitchCase="'UIDocumentalistSettings'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uidocumentalistsettings>

          <app-ui-form-uicopywriterwelcome
            id="drawerComponent"
            *ngSwitchCase="'UICopywriterWelcome'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicopywriterwelcome>

          <app-ui-form-uidocumentalistuserinput
            id="drawerComponent"
            *ngSwitchCase="'UIDocumentalistUserInput'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uidocumentalistuserinput>

          <app-ui-form-uicopywritersettings
            id="drawerComponent"
            *ngSwitchCase="'UICopywriterSettings'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicopywritersettings>

          <app-ui-form-uidocumentalistsettingsdrawer
            id="drawerComponent"
            *ngSwitchCase="'UIDocumentalistSettingsDrawer'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uidocumentalistsettingsdrawer>

          <app-ui-form-uicopywriteruserinput
            id="drawerComponent"
            *ngSwitchCase="'UICopywriterUserInput'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicopywriteruserinput>

          <app-ui-form-uicopywritersettingsdrawer
            id="drawerComponent"
            *ngSwitchCase="'UICopywriterSettingsDrawer'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicopywritersettingsdrawer>

          <app-ui-form-uierrormessage
            id="drawerComponent"
            *ngSwitchCase="'UIErrorMessage'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uierrormessage>

          <app-ui-form-uicreativewelcome
            id="drawerComponent"
            *ngSwitchCase="'UICreativeWelcome'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicreativewelcome>

          <app-ui-form-uicreativesettingsdrawer
            id="drawerComponent"
            *ngSwitchCase="'UICreativeSettingsDrawer'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicreativesettingsdrawer>

          <app-ui-form-uicreativesettings
            id="drawerComponent"
            *ngSwitchCase="'UICreativeSettings'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicreativesettings>

          <app-ui-form-uicreativeuserinput
            id="drawerComponent"
            *ngSwitchCase="'UICreativeUserInput'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicreativeuserinput>

          <app-ui-form-uicopywriterstylesettings
            id="drawerComponent"
            *ngSwitchCase="'UICopywriterStyleSettings'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicopywriterstylesettings>

          <app-ui-form-uicopywriterstylesettingsdrawer
            id="drawerComponent"
            *ngSwitchCase="'UICopywriterStyleSettingsDrawer'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uicopywriterstylesettingsdrawer>

          <app-ui-form-uianalystuserinput
            id="drawerComponent"
            *ngSwitchCase="'UIAnalystUserInput'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uianalystuserinput>

          <app-ui-form-uianalystsettingsdrawer
            id="drawerComponent"
            *ngSwitchCase="'UIAnalystSettingsDrawer'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uianalystsettingsdrawer>

          <app-ui-form-uianalystwelcome
            id="drawerComponent"
            *ngSwitchCase="'UIAnalystWelcome'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uianalystwelcome>

          <app-ui-form-uianalystdatamessage
            id="drawerComponent"
            *ngSwitchCase="'UIAnalystDataMessage'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uianalystdatamessage>

          <app-ui-form-uianalystsettings
            id="drawerComponent"
            *ngSwitchCase="'UIAnalystSettings'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-uianalystsettings>

          <app-ui-form-flowstep
            id="drawerComponent"
            *ngSwitchCase="'FlowStep'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-flowstep>

          <app-ui-form-flowstepdocumentalist
            id="drawerComponent"
            *ngSwitchCase="'FlowStepDocumentalist'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-flowstepdocumentalist>

          <app-ui-form-flowstepanalyst
            id="drawerComponent"
            *ngSwitchCase="'FlowStepAnalyst'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-flowstepanalyst>

          <app-ui-form-flowstepcopywriter
            id="drawerComponent"
            *ngSwitchCase="'FlowStepCopywriter'"
            [formGroup]="$any(formGroup['_drawerComponent'])"
          ></app-ui-form-flowstepcopywriter>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userLang']">
    <mat-form-field appearance="fill">
      <mat-label>userLang</mat-label>
      <input matInput placeholder="" formControlName="userLang" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['extraDrawerComponent']">
    <app-ui-list-sub-doc-uicomponent id="extraDrawerComponent" label="extraDrawerComponent" [inputData]="formGroup['_extraDrawerComponent']">
    </app-ui-list-sub-doc-uicomponent>
  </div>
</div>

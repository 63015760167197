import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DirectiveModule } from '../../../../directive/directive.module';

import { MatExpansionModule } from '@angular/material/expansion';

import { url_roleFormModule } from '../url_role/url_role.module';

import { serviceFormAutoCompleteModule } from '../../chipList/service/service.module';

import { service_accessFormComponent } from './service_access.component';
@NgModule({
  declarations: [service_accessFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,
    DirectiveModule,

    MatExpansionModule,

    url_roleFormModule,

    serviceFormAutoCompleteModule
  ],
  exports: [
    service_accessFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatExpansionModule,

    url_roleFormModule,

    serviceFormAutoCompleteModule
  ]
})
export class service_accessFormModule {}

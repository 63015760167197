export const environment = {
  production: false,

  application_instance: '653b8ddc2e1023eadf0e00f7',

  data: {
    authServiceUrl: 'http://localhost:6563/authentication/',
    api:'http://localhost:6563/'
  }
};

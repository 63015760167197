<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs current="LlmAction"></fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content>
    <fury-list name="LlmAction" [columns]="columns" (filterChange)="onFilterChange($event)" (colsFilterChange)="colsFilterChangeListener($event)">
      <div class="actions">
        <button mat-fab color="primary" [matMenuTriggerFor]="addObj">
          <mat-icon aria-label="add icon">add</mat-icon>
        </button>
        <mat-menu #addObj="matMenu">
          <button mat-menu-item (click)="add('OpenAiPredict')">OpenAiPredict</button>

          <button mat-menu-item (click)="add('OpenAiChat')">OpenAiChat</button>

          <button mat-menu-item (click)="add('DocumentChunkSearch')">DocumentChunkSearch</button>

          <button mat-menu-item (click)="add('Deepl')">Deepl</button>

          <button mat-menu-item (click)="add('AdobeePdfToChuncks')">AdobeePdfToChuncks</button>

          <button mat-menu-item (click)="add('AdobeWordPdfToChunks')">AdobeWordPdfToChunks</button>

          <button mat-menu-item (click)="add('ExcelToChunks')">ExcelToChunks</button>

          <button mat-menu-item (click)="add('VisionToChuncks')">VisionToChuncks</button>

          <button mat-menu-item (click)="add('VisionImageDescription')">VisionImageDescription</button>

          <button mat-menu-item (click)="add('TxtToChunks')">TxtToChunks</button>

          <button mat-menu-item (click)="add('AdobeCreatePdf')">AdobeCreatePdf</button>

          <button mat-menu-item (click)="add('PdfToImages')">PdfToImages</button>

          <button mat-menu-item (click)="add('FileDataToChunks')">FileDataToChunks</button>

          <button mat-menu-item (click)="add('FileImageDataToChunks')">FileImageDataToChunks</button>

          <button mat-menu-item (click)="add('CreatePresentation')">CreatePresentation</button>

          <button mat-menu-item (click)="add('LangChainFileToChunck')">LangChainFileToChunck</button>

          <button mat-menu-item (click)="add('Creative')">Creative</button>

          <button mat-menu-item (click)="add('DocumentDataAnalystOutput')">DocumentDataAnalystOutput</button>

          <button mat-menu-item (click)="add('DocumentDataDiscovery')">DocumentDataDiscovery</button>

          <button mat-menu-item (click)="add('DocumentDataAnalyst')">DocumentDataAnalyst</button>

          <button mat-menu-item (click)="add('DocumentAnalystDiscovery')">DocumentAnalystDiscovery</button>

          <button mat-menu-item (click)="add('VisionSlideType')">VisionSlideType</button>

          <button mat-menu-item (click)="add('Copywriter')">Copywriter</button>

          <button mat-menu-item (click)="add('DocumentalisteSearch')">DocumentalisteSearch</button>
        </mat-menu>
      </div>
      <app-ui-list-llmaction
        class="table"
        id="uiList"
        [sortDirection]="sortDirection"
        [sortColumn]="sortColumn"
        [displayedColumns]="displayedColumns"
        [data]="data"
        (rowClicked)="rowClicked($event)"
        [isLoadingResults]="isLoadingResults"
        (sortChange)="sortChange($event)"
      ></app-ui-list-llmaction>
      <mat-paginator
        (page)="paginatorChange($event)"
        [pageSizeOptions]="pageSizeOptions"
        [length]="dataLength"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        showFirstLastButtons
      ></mat-paginator>
    </fury-list>
  </fury-page-layout-content>
</fury-page-layout>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessageEndPageUiListComponent } from 'src/app/generated/dataPages/list/UserMessageEnd/UserMessageEnd.component';
import { UserMessageEndPageFormComponent } from 'src/app/generated/dataPages/form/UserMessageEnd/UserMessageEnd.component';
import { UserMessageEndPageUiListModule } from 'src/app/generated/dataPages/list/UserMessageEnd/UserMessageEnd.module';
import { UserMessageEndPageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessageEnd/UserMessageEnd.module';

export class UserMessageEndRoutes {
  public static routes = [
    {
      path: 'UserMessageEnd/list',
      component: UserMessageEndPageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageEnd/edit/:id',
      component: UserMessageEndPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessageEnd/new/:id',
      component: UserMessageEndPageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessageEndPageUiListModule, UserMessageEndPageFormComponentModule];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedModule } from '@leandredev/common-ng16/components';

import { VisionToChuncksOutputFormComponent } from './VisionToChuncksOutput.component';
@NgModule({
  declarations: [VisionToChuncksOutputFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule],
  exports: [VisionToChuncksOutputFormComponent, CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, SharedModule]
})
export class VisionToChuncksOutputFormModule {}

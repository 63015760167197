<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['email']">
    <mat-form-field appearance="fill">
      <mat-label>email</mat-label>
      <input matInput placeholder="" formControlName="email" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['nb']">
    <mat-form-field appearance="fill">
      <mat-label>nb</mat-label>
      <input matInput placeholder="" formControlName="nb" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userId']">
    <app-userclient-form-auto-complete [formCtrl]="formGroup['_userId']" placeholder="userId"> </app-userclient-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['date']">
    <mat-form-field appearance="fill">
      <mat-label>date</mat-label>
      <input matInput placeholder="" [matDatepicker]="mydate" formControlName="date" />
      <mat-datepicker-toggle matSuffix [for]="mydate"></mat-datepicker-toggle>
      <mat-datepicker #mydate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['year']">
    <mat-form-field appearance="fill">
      <mat-label>year</mat-label>
      <input matInput placeholder="" formControlName="year" type="number" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['isoWeek']">
    <mat-form-field appearance="fill">
      <mat-label>isoWeek</mat-label>
      <input matInput placeholder="" formControlName="isoWeek" type="number" />
    </mat-form-field>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['paramName']">
    <mat-form-field appearance="fill">
      <mat-label>paramName</mat-label>
      <input matInput placeholder="" formControlName="paramName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['type']">
    <mat-form-field appearance="fill">
      <mat-label>type</mat-label>

      <mat-select placeholder="Select" formControlName="type">
        <mat-option value="string">string</mat-option>

        <mat-option value="boolean">boolean</mat-option>

        <mat-option value="number">number</mat-option>

        <mat-option value="date">date</mat-option>

        <mat-option value="objectId">objectId</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { UserMessagePageUiListComponent } from 'src/app/generated/dataPages/list/UserMessage/UserMessage.component';
import { UserMessagePageFormComponent } from 'src/app/generated/dataPages/form/UserMessage/UserMessage.component';
import { UserMessagePageUiListModule } from 'src/app/generated/dataPages/list/UserMessage/UserMessage.module';
import { UserMessagePageFormComponentModule } from 'src/app/generated/dataPages/form/UserMessage/UserMessage.module';

export class UserMessageRoutes {
  public static routes = [
    {
      path: 'UserMessage/list',
      component: UserMessagePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessage/edit/:id',
      component: UserMessagePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'UserMessage/new/:id',
      component: UserMessagePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [UserMessagePageUiListModule, UserMessagePageFormComponentModule];
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['name']">
    <mat-form-field appearance="fill">
      <mat-label>name</mat-label>
      <input matInput placeholder="" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['description']">
    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['path']">
    <mat-form-field appearance="fill">
      <mat-label>path</mat-label>
      <input matInput placeholder="" formControlName="path" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['verbe']">
    <mat-form-field appearance="fill">
      <mat-label>verbe</mat-label>

      <mat-select placeholder="Select" formControlName="verbe">
        <mat-option value="get">get</mat-option>

        <mat-option value="post">post</mat-option>

        <mat-option value="put">put</mat-option>

        <mat-option value="patch">patch</mat-option>

        <mat-option value="delete">delete</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['bodyFormat']">
    <mat-form-field appearance="fill">
      <mat-label>bodyFormat</mat-label>

      <mat-select placeholder="Select" formControlName="bodyFormat">
        <mat-option value="json">json</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['returnFormat']">
    <mat-form-field appearance="fill">
      <mat-label>returnFormat</mat-label>

      <mat-select placeholder="Select" formControlName="returnFormat">
        <mat-option value="json">json</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['pathParams']">
    <app-ui-list-sub-doc-swaggerparams id="pathParams" label="pathParams" [inputData]="formGroup['_pathParams']"> </app-ui-list-sub-doc-swaggerparams>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['queryParams']">
    <app-ui-list-sub-doc-swaggerparams id="queryParams" label="queryParams" [inputData]="formGroup['_queryParams']">
    </app-ui-list-sub-doc-swaggerparams>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['bodyParam']">
    <app-protoschema-form-auto-complete [formCtrl]="formGroup['_bodyParam']" placeholder="bodyParam"> </app-protoschema-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['returnType']">
    <app-protoschema-form-auto-complete [formCtrl]="formGroup['_returnType']" placeholder="returnType"> </app-protoschema-form-auto-complete>
  </div>
</div>

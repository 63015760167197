/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Self, AfterContentInit } from '@angular/core';
import { UiList } from '@leandredev/common-ng16/components';
import { UIComponentState } from '@leandredev/common-ng16/infraService';

import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'app-ui-list-userimportstats',
  templateUrl: './UserImportStats.component.html',
  styleUrls: ['./UserImportStats.component.scss'],
  providers: [UIComponentState]
})
export class UserImportStatsUiListComponent extends UiList<interfaces.IUserImportStats> implements AfterContentInit, OnInit {
  @Input() id = '';
  constructor(@Self() protected uIComponentState: UIComponentState) {
    super();

    this.columnsType = {
      _id: 'string',
      name: 'string',
      email: 'string',
      nb: 'number',
      userId: 'objectid',
      date: 'date',
      year: 'number',
      isoWeek: 'number'
    };
  }

  ngAfterContentInit(): void {
    this.displayedColumns = ['name', 'email', 'nb', 'userId'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
  }
}

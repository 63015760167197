/* eslint-disable max-len */
import { RouteComponentState } from '@leandredev/common-ng16/infraService';
import { MsgActionToExecutePageUiListComponent } from 'src/app/generated/dataPages/list/MsgActionToExecute/MsgActionToExecute.component';
import { MsgActionToExecutePageFormComponent } from 'src/app/generated/dataPages/form/MsgActionToExecute/MsgActionToExecute.component';
import { MsgActionToExecutePageUiListModule } from 'src/app/generated/dataPages/list/MsgActionToExecute/MsgActionToExecute.module';
import { MsgActionToExecutePageFormComponentModule } from 'src/app/generated/dataPages/form/MsgActionToExecute/MsgActionToExecute.module';

export class MsgActionToExecuteRoutes {
  public static routes = [
    {
      path: 'MsgActionToExecute/list',
      component: MsgActionToExecutePageUiListComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'MsgActionToExecute/edit/:id',
      component: MsgActionToExecutePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    },
    {
      path: 'MsgActionToExecute/new/:id',
      component: MsgActionToExecutePageFormComponent,
      resolve: {
        state: RouteComponentState
      }
    }
  ];
  public static deps = [MsgActionToExecutePageUiListModule, MsgActionToExecutePageFormComponentModule];
}

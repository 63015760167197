/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, Self, Inject, Output } from '@angular/core';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { FormControlBase } from '@leandredev/common-ng16/form';

import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiForm } from '@leandredev/common-ng16/components';
import { filter, pipe, map } from 'rxjs';

@Component({
  selector: 'app-ui-form-userclientgroup',
  templateUrl: './UserClientGroup.component.html',
  styleUrls: ['./UserClientGroup.component.scss'],
  providers: [UIComponentState]
})
export class UserClientGroupFormComponent extends UiForm<form.UserClientGroup> implements OnInit {
  @Input() override id = '';
  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);
  }
  @Output()
  public selectedEnabledTags = '';

  @Output()
  public selectedDisabledTags = '';

  override ngOnInit(): void {
    super.ngOnInit();
    this.uIComponentState.id = this.id;
    Object.assign(this.state, this.uIComponentState.state);
    Object.assign(this.uIComponentState.state, this.state);
    this.state = this.uIComponentState.state;
    this.formGroup.valueChanges.subscribe((value) => {
      // filtre pour les tags enabled
      if (value['disabledTags']) {
        this.selectedDisabledTags =
          '_id/' +
          value['disabledTags']
            .reduce((a, b) => {
              return `${b}/${a}`;
            }, '')
            .concat(value['disabledTags'].length)
            .concat('/]/&');
      }

      // filtre pour les tags enabled
      if (value['enabledTags']) {
        this.selectedEnabledTags =
          '_id/' +
          value['enabledTags']
            .reduce((a, b) => {
              return `${b}/${a}`;
            }, '')
            .concat(value['enabledTags'].length)
            .concat('/]/&');
      }
    });
  }

  public fieldClassHumanName: { [key: string]: string } = {};

  public classChange(value: string, control: FormControlBase): void {
    let oldVal: any;
    oldVal = control.value;
    if (!oldVal) {
      oldVal = {};
    }
    oldVal._class = value;
    control.patchValue(oldVal);
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Component, OnInit, Input, Self } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiListSubDoc } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-list-sub-doc-uicomponent',
  templateUrl: './UIComponent.component.html',
  styleUrls: ['./UIComponent.component.scss'],
  providers: [UIComponentState],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class UIComponentUIListSubDocComponent extends UiListSubDoc<form.UIComponent> implements OnInit {
  @Input() override id = '';

  public selectedClass = 'UIComponentChatUserInput';

  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);
    this.columnsToDisplay = ['drag', '_class', 'userName', 'actions'];
    this.classChange(this.selectedClass);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.uIComponentState.id = this.id;
  }

  addItemByMenu(value: string): void {
    this.classChange(value);
    this.addItem();
  }
  override addItem(): void {
    this.inputData.push(this.newElement);
    this.slctTable.renderRows();
    this.expandedElement = this.newElement;
    this.newElement = new this.FormClass();
  }
  override drop(event: CdkDragDrop<any>): void {
    moveItemInArray(this.inputData.controls, event.previousIndex, event.currentIndex);
    this.slctTable.renderRows();
  }

  public classToHumanName: { [key: string]: string } = {
    // eslint-disable-next-line @typescript-eslint/quotes
    UIComponent: 'UIComponent',

    UIComponentChatUserInput: 'UIComponentChatUserInput',

    UIChatMessage: 'UIChatMessage',

    UIChatWelcome: 'UIChatWelcome',

    UIDocumentalistWelcome: 'UIDocumentalistWelcome',

    UIDocumentalistSettings: 'UIDocumentalistSettings',

    UICopywriterWelcome: 'UICopywriterWelcome',

    UIDocumentalistUserInput: 'UIDocumentalistUserInput',

    UICopywriterSettings: 'UICopywriterSettings',

    UIDocumentalistSettingsDrawer: 'UIDocumentalistSettingsDrawer',

    UICopywriterUserInput: 'UICopywriterUserInput',

    UICopywriterSettingsDrawer: 'UICopywriterSettingsDrawer',

    UIErrorMessage: 'UIErrorMessage',

    UICreativeWelcome: 'UICreativeWelcome',

    UICreativeSettingsDrawer: 'UICreativeSettingsDrawer',

    UICreativeSettings: 'UICreativeSettings',

    UICreativeUserInput: 'UICreativeUserInput',

    UICopywriterStyleSettings: 'UICopywriterStyleSettings',

    UICopywriterStyleSettingsDrawer: 'UICopywriterStyleSettingsDrawer',

    UIAnalystUserInput: 'UIAnalystUserInput',

    UIAnalystSettingsDrawer: 'UIAnalystSettingsDrawer',

    UIAnalystWelcome: 'UIAnalystWelcome',

    UIAnalystDataMessage: 'UIAnalystDataMessage',

    UIAnalystSettings: 'UIAnalystSettings',

    FlowStep: 'FlowStep',

    FlowStepDocumentalist: 'FlowStepDocumentalist',

    FlowStepAnalyst: 'FlowStepAnalyst',

    FlowStepCopywriter: 'FlowStepCopywriter'
  };
  public classChange(value: string): void {
    let oldVal: any;
    if (this.newElement) {
      oldVal = this.newElement.value;
    }
    switch (value) {
      case 'UIComponent':
        this.FormClass = form.UIComponent;
        break;

      case 'UIComponentChatUserInput':
        this.FormClass = form.UIComponentChatUserInput;
        break;

      case 'UIChatMessage':
        this.FormClass = form.UIChatMessage;
        break;

      case 'UIChatWelcome':
        this.FormClass = form.UIChatWelcome;
        break;

      case 'UIDocumentalistWelcome':
        this.FormClass = form.UIDocumentalistWelcome;
        break;

      case 'UIDocumentalistSettings':
        this.FormClass = form.UIDocumentalistSettings;
        break;

      case 'UICopywriterWelcome':
        this.FormClass = form.UICopywriterWelcome;
        break;

      case 'UIDocumentalistUserInput':
        this.FormClass = form.UIDocumentalistUserInput;
        break;

      case 'UICopywriterSettings':
        this.FormClass = form.UICopywriterSettings;
        break;

      case 'UIDocumentalistSettingsDrawer':
        this.FormClass = form.UIDocumentalistSettingsDrawer;
        break;

      case 'UICopywriterUserInput':
        this.FormClass = form.UICopywriterUserInput;
        break;

      case 'UICopywriterSettingsDrawer':
        this.FormClass = form.UICopywriterSettingsDrawer;
        break;

      case 'UIErrorMessage':
        this.FormClass = form.UIErrorMessage;
        break;

      case 'UICreativeWelcome':
        this.FormClass = form.UICreativeWelcome;
        break;

      case 'UICreativeSettingsDrawer':
        this.FormClass = form.UICreativeSettingsDrawer;
        break;

      case 'UICreativeSettings':
        this.FormClass = form.UICreativeSettings;
        break;

      case 'UICreativeUserInput':
        this.FormClass = form.UICreativeUserInput;
        break;

      case 'UICopywriterStyleSettings':
        this.FormClass = form.UICopywriterStyleSettings;
        break;

      case 'UICopywriterStyleSettingsDrawer':
        this.FormClass = form.UICopywriterStyleSettingsDrawer;
        break;

      case 'UIAnalystUserInput':
        this.FormClass = form.UIAnalystUserInput;
        break;

      case 'UIAnalystSettingsDrawer':
        this.FormClass = form.UIAnalystSettingsDrawer;
        break;

      case 'UIAnalystWelcome':
        this.FormClass = form.UIAnalystWelcome;
        break;

      case 'UIAnalystDataMessage':
        this.FormClass = form.UIAnalystDataMessage;
        break;

      case 'UIAnalystSettings':
        this.FormClass = form.UIAnalystSettings;
        break;

      case 'FlowStep':
        this.FormClass = form.FlowStep;
        break;

      case 'FlowStepDocumentalist':
        this.FormClass = form.FlowStepDocumentalist;
        break;

      case 'FlowStepAnalyst':
        this.FormClass = form.FlowStepAnalyst;
        break;

      case 'FlowStepCopywriter':
        this.FormClass = form.FlowStepCopywriter;
        break;

      default:
        this.FormClass = form.UIComponent;
        break;
    }
    this.newElement = new this.FormClass();
    if (oldVal) {
      delete oldVal._class;
      this.newElement.patchValue(oldVal);
    }
    this.selectedClass = value;
  }
}

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['setting']">
    <mat-expansion-panel
      [expanded]="state['setting']?._expanded"
      (closed)="propState('setting_expanded', null)"
      (opened)="propState('setting_expanded', 'expanded')"
    >
      <mat-expansion-panel-header> setting {{ fieldClassHumanName[formGroup._setting?.__class?.value] }} </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container [ngSwitch]="formGroup['_setting']?.__class?.value">
          <app-ui-form-creativesettingsrequest
            id="setting"
            *ngSwitchCase="'CreativeSettingsRequest'"
            [formGroup]="formGroup['_setting']"
          ></app-ui-form-creativesettingsrequest>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <textarea matInput rows="5" placeholder="" formControlName="message"></textarea>
    </mat-form-field>
  </div>
</div>

<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['userName']">
    <mat-form-field appearance="fill">
      <mat-label>userName</mat-label>
      <input matInput placeholder="" formControlName="userName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['message']">
    <mat-form-field appearance="fill">
      <mat-label>message</mat-label>
      <input matInput placeholder="" formControlName="message" />
    </mat-form-field>
  </div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Component, OnInit, Input, Self } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { form } from '@leandredev/adaptivai-ng16-lib';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UIComponentState } from '@leandredev/common-ng16/infraService';
import { UiListSubDoc } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-ui-list-sub-doc-flowstep',
  templateUrl: './FlowStep.component.html',
  styleUrls: ['./FlowStep.component.scss'],
  providers: [UIComponentState],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class FlowStepUIListSubDocComponent extends UiListSubDoc<form.FlowStep> implements OnInit {
  @Input() override id = '';

  public selectedClass = 'FlowStepDocumentalist';

  constructor(@Self() protected override uIComponentState: UIComponentState) {
    super(uIComponentState);
    this.columnsToDisplay = ['drag', '_class', 'userName', 'name', 'usePrevious', 'actions'];
    this.classChange(this.selectedClass);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.uIComponentState.id = this.id;
  }

  addItemByMenu(value: string): void {
    this.classChange(value);
    this.addItem();
  }
  override addItem(): void {
    this.inputData.push(this.newElement);
    this.slctTable.renderRows();
    this.expandedElement = this.newElement;
    this.newElement = new this.FormClass();
  }
  override drop(event: CdkDragDrop<any>): void {
    moveItemInArray(this.inputData.controls, event.previousIndex, event.currentIndex);
    this.slctTable.renderRows();
  }

  public classToHumanName: { [key: string]: string } = {
    // eslint-disable-next-line @typescript-eslint/quotes
    FlowStep: 'FlowStep',

    FlowStepDocumentalist: 'FlowStepDocumentalist',

    FlowStepAnalyst: 'FlowStepAnalyst',

    FlowStepCopywriter: 'FlowStepCopywriter'
  };
  public classChange(value: string): void {
    let oldVal: any;
    if (this.newElement) {
      oldVal = this.newElement.value;
    }
    switch (value) {
      case 'FlowStep':
        this.FormClass = form.FlowStep;
        break;

      case 'FlowStepDocumentalist':
        this.FormClass = form.FlowStepDocumentalist;
        break;

      case 'FlowStepAnalyst':
        this.FormClass = form.FlowStepAnalyst;
        break;

      case 'FlowStepCopywriter':
        this.FormClass = form.FlowStepCopywriter;
        break;

      default:
        this.FormClass = form.FlowStep;
        break;
    }
    this.newElement = new this.FormClass();
    if (oldVal) {
      delete oldVal._class;
      this.newElement.patchValue(oldVal);
    }
    this.selectedClass = value;
  }
}

<div>
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner class="loading-shade__loader"></mat-spinner>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="data"
      class="table"
      matSort
      [matSortActive]="sortColumn"
      matSortDisableClear
      [matSortDirection]="sortDirection"
      (matSortChange)="sortEvt($event)"
    >
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._id }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="_class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>class</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row._class }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>url</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.url }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>serviceId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.serviceId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>creationDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.creationDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>startDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.startDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>endDate</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.endDate | amDateFormat: 'Do MMMM YYYY' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="hasFailed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>hasFailed</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.hasFailed }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorsList">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>errorsList</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.errorsList }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceCreator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>serviceCreator</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.serviceCreator }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>userId</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <app-ui-ref #obj colName="UserAdmin" [idRef]="row.userId">
              {{ obj.data?.name || obj.data?.title || row.userId }}
            </app-ui-ref>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>tags</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <ng-container *ngFor="let val of row.tags">
              <app-ui-ref #obj colName="Tag" [idRef]="val">
                {{ obj.data?.name || obj.data?.title || val }}
              </app-ui-ref>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="descriptions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>descriptions</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            {{ row.descriptions | json }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClick($event, row)"></tr>
    </table>
  </div>
</div>
